import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Breadcrumb from 'antd/lib/breadcrumb';
import Menu from 'antd/lib/menu';
import { HomeOutlined } from '@ant-design/icons';
import Tires from './types/Tires';
import Radio from './types/Radio';
import Tracks from './types/Tracks';
import Phones from './types/Phones';

import './Misc.scss';

const titleMapper = {
    tires: { title: 'צמיגים', comp: Tires },
    radio: { title: 'תקשורת רדיו', comp: Radio },
    tracks: { title: 'מסלולי מרוץ', comp: Tracks },
    phones: { title: 'טלפונים חשובים', comp: Phones }
};

function Misc(props) {
    const [colmo, setColmo] = useState({});
    const [chemp, setChemp] = useState({});
    const ref = useRef();
    const colmosku = useRef();
    const chempionsku = useRef();
    const { type } = props.match.params;
    const CurrentPage = type ? titleMapper[type] : null;

    const findColmobilSku = () => {
        const sku = colmosku.current.value;
        axios.get(`/makat/colmobil/${sku}`)
            .then(({ data }) => {setColmo(data)});
    }

    const findChempionskuSku = () => {
        const sku = chempionsku.current.value;
        axios.get(`/makat/chempion/${sku}`)
            .then(({ data }) => {setChemp(data)});
    }

    const menu = (<Menu>
        {Object.keys(titleMapper).map((key) => (<Menu.Item key={key}>
            <Link to={`/misc/${key}`}>{titleMapper[key].title}</Link>
        </Menu.Item>))}
    </Menu>);

    return (
        <div style={{ padding: 30 }}>
            <Breadcrumb>
                <Breadcrumb.Item key={`/misc`}>
                    <HomeOutlined />{' '}
                    <Link to={'/misc'}>מידע שימושי</Link>
                </Breadcrumb.Item>
                {CurrentPage && <Breadcrumb.Item key={`/misc/${type}`} overlay={menu}>
                    <Link to={`/misc/${type}`}>{CurrentPage.title}</Link>
                </Breadcrumb.Item>}
            </Breadcrumb>

            {CurrentPage ? <CurrentPage.comp /> : <>
                <h1>מידע שימושי</h1>
                {menu}

                <ul>
                    <h3>לינקים שימושיים</h3>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.iroads.co.il/%D7%AA%D7%99%D7%A7%D7%99%D7%99%D7%AA-%D7%9E%D7%A6%D7%9C%D7%9E%D7%95%D7%AA/">מצלמות כבישים</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://ims.gov.il/he/Forecasts">תחזית מזג אוויר</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.auto-data.net/">auto data</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.powerbulbs.com/vehicles/cars/rest-of-world">חיפוש נורות לפי יצרן</a></li>

                    <li><a target="_blank" rel="noopener noreferrer" href="https://7zap.com/en/catalog/cars/" title="catalog for: Abarth, Alfa Romeo, Audi, BMW, Buick, Cadillac, Chevrolet, Citroen, Dacia, Datsun, Fiat, Ford, GMC, Honda, Hummer, Hyundai, Infiniti, Kia, Lancia, Lexus, Maybach, Mazda, Mercedes, Mini, Nissan, Oldsmobile, Opel, Peugeot, Pontiac, Renault, Rolls-Royce, Saab, Saturn, Seat, Skoda, Smart, SsangYong, Subaru, Suzuki, Toyota, Vauxhall, Volkswagen, Volvo, Zinoro">קטלוג חלפים - 7zap</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.megazip.net/" title="catalog for: Suzuki, Toyota, Nissan, Honda, Mitsubishi, Subaru">קטלוג חלפים - megazip</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.amayama.com/en" title="catalog for: Infiniti, Lexus, Toyota, Nissan, Honda, Mitsubishi, Subaru">קטלוג חלפים - amayama</a></li>

                    <hr/>
                    <h3>חלפים</h3>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.colmobil.co.il/spare-parts/file.php?f=HYU">מחירון חלפים Hyundai</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.colmobil.co.il/spare-parts/file.php?f=MERC">מחירון חלפים Mercedes</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.colmobil.co.il/spare-parts/file.php?f=MIT">מחירון חלפים Mitsubishi</a></li>
                    <hr/>

                    <h3>חלפים - <a rel="noopener noreferrer" href="https://www.colmobil.co.il/spare_parts/%D7%9E%D7%97%D7%99%D7%A8%D7%95%D7%9F-%D7%97%D7%9C%D7%A4%D7%99%D7%9D/" target="_blank">כלמוביל</a></h3>
                    <input type="text" ref={colmosku} style={{border: '1px solid grey', height: '28px'}}/>
                    <button onClick={findColmobilSku}>מצא</button>
                    {colmo.name && <div>{colmo.name}</div>}
                    {colmo.price && <div>מחיר: {colmo.price}</div>}
                    <hr/>

                    <h3>חלפים - <a rel="noopener noreferrer" href="https://www.championmotors.co.il/catalog/" target="_blank">צ'מפיון</a></h3>
                    <input type="text" ref={chempionsku} style={{border: '1px solid grey', height: '28px'}}/>
                    <button onClick={findChempionskuSku}>מצא</button>
                    {chemp.name && <div>{chemp.name}</div>}
                    {chemp.price && <div>מחיר: {chemp.price}</div>}
                    <hr/>

                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.megazip.net/zapchasti-dlya-avtomobilej/mitsubishi/pajero-montero-4375/v26w-10650/gnhgl6-164715">קטלוג מקטים פג'רו</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://7zap.com/en/catalog/cars/Seat/brand/16/0/Ibiza%2FST/Ibiza%20(2018%20-%202021)/Seat/a1Y1VUs1TzdyTC84eFpnUDkyK3NoQT09--/6bbffde8b5b8077d2d0a5796da6eea3c:c52c0119b83d4865c507191d6654a4f4/universal">קטלוג מקטים איביזה</a></li>
                    <hr/>
                    <h3>שליחת הודעת וואטסאפ</h3>
                    <input type="text" ref={ref} style={{border: '1px solid grey', height: '28px'}}/>
                    <img style={{ verticalAlign: 'top', width: 28, height: 28 }}
                        onClick={() => {
                            window.open(`https://wa.me/972${Number(ref.current.value.replace('-', ''))}`);
                        }}
                        alt="whatsapp"
                        src="https://www.iconheaven.com/download/5/png/whatsapp_png_transparent256.png"/>
                </ul>

            </>}
        </div>
    );
};

export { Misc };

export default Misc;
import Roads from './Roads';
import Road from './Road';
import RoadItem from './RoadItem';
import RoadsMap from './RoadsMap';
import Weather from './Weather';

import HERMON from './gpx/hermon.gpx';
import MISHMAR_KELA_ALON from './gpx/mishmar-hayarden-kela-alon.gpx';
import KELA_ALON_SHAMIR from './gpx/kela-alon-shamir.gpx';
import NORTH_ROAD from './gpx/north-road.gpx';
import KOACH_MISGAV from './gpx/koach-misgav.gpx';
import SHEVA_MIRON from './gpx/sheva-miron.gpx';
import BIRANIT_ALKOSH from './gpx/biranit-alkosh.gpx';
import AVDON_MENAHEM from './gpx/avdon-admit-even-menahem.gpx';
import KFAR_NAHUM_ALMAGOR from './gpx/kfar-nahum-almagor.gpx';
import PORIA_SWISS from './gpx/poria-swiss.gpx';
import SUSITA from './gpx/susita.gpx';
import MEVO_HAMA from './gpx/mevo-hama.gpx';
import ASHDOT_YAAKOV from './gpx/ashdot-yaakov-shoshana.gpx';
import TAVOR from './gpx/tavor.gpx';
import GILBOA from './gpx/gilboa.gpx';
import MENASHE from './gpx/menashe.gpx';
import GILAD_MISHMAR from './gpx/gilad-mishmar-haemek.gpx';
import AVIEL_KFAR_KARA from './gpx/aviel-kfar-kara.gpx';
import BETOREN from './gpx/bet-oren.gpx';
import OFER from './gpx/ofer.gpx';
import ALON_ROAD from './gpx/alon-road.gpx';

import BET_ARIE from './gpx/bet-arie.gpx';
import ESHTAOL_EN_KAREM from './gpx/eshtaol-en-karem.gpx';
import ENKAREM_BETSHEMESH from './gpx/enkerem-betshemesh.gpx';
import HEMED_NATAF from './gpx/hemed-nataf.gpx';
import AGUR_LUZIT from './gpx/agur-luzit.gpx';
import ADUMIM_MICHMESH from './gpx/kfar-adumim-maale-michmesh.gpx';
import BET_GOVRIN_LACHISH from './gpx/bet-govrin-lachish.gpx';
import YAAR_MALACHIM from './gpx/malachim.gpx';
import ORA_KENEDI from './gpx/ora-kenedi.gpx';
import ADERET_ADOLAM from './gpx/aderet-adolam.gpx';
import OZEM_NEGBA from './gpx/ozem-helez-negba.gpx';

import MEZOKI_DRAGOT from './gpx/mezoki-dragot.gpx';
import LAHAV from './gpx/lahav.gpx';
import SDOMֹֹֹֹֹֹֹֹ_ARAD from './gpx/sdom-arad.gpx';
import ARAD_MEZADA from './gpx/arad-mezada.gpx';
import ROTEM_ARAD from './gpx/rotem-arad.gpx';
import IDAN_ENYAHAV from './gpx/idan-enyahav.gpx';
import YAAR_LEHAVIM from './gpx/yaar-lehavim.gpx';
import YATIR_FOREST from './gpx/yaar-yatir.gpx';
import KTORA_SHOROT from './gpx/ktora-shorot.gpx';
import RAMON_HARIF from './gpx/ramon-harif.gpx';
import RAMON_EILAT from './gpx/ramon-eilat.gpx';
import TIMNA from './gpx/timna.gpx';
import ROAD_10 from './gpx/road10-south.gpx';
import AKRABIM from './gpx/akrabim.gpx';
import MACHTESH_GADOL from './gpx/machtesh-gadol.gpx';

import SEAT_DAROM_11 from './gpx/seat-darom-11.gpx';
import TIUL_5 from './gpx/tiul-5.gpx';
import TO_ARIE from './gpx/to-arie.gpx';
import NESS from './gpx/ness-harim.gpx';
import SDOMֹֹֹֹֹֹֹֹ_ARAD_MEZADA from './gpx/sdom-arad-mezada.gpx';
import ZRIHA from './gpx/zriha.gpx';
import RENO from './gpx/reno.gpx';

// {
//   key: '', // must be exactly as gpx filename.
//   roads: [],
//   group: 'north/center/south',
//   gpx: null
// },

const north = [
  {
    key: 'hermon',
    name: 'החרמון',
    roads: [98, 989],
    group: 'north',
    gpx: HERMON,
    round: true
  },
  {
    key: 'mishmar-hayarden-kela-alon',
    name: 'משמר הירדן - קלע אלון',
    roads: [918, 959],
    group: 'north',
    gpx: MISHMAR_KELA_ALON
  },
  {
    key: 'kela-alon-shamir',
    name: 'קלע אלון - שמיר',
    roads: [959, 918],
    group: 'north',
    gpx: KELA_ALON_SHAMIR
  },
  {
    key: 'north-road',
    name: 'כביש הצפון',
    roads: [886, 899],
    group: 'north',
    gpx: NORTH_ROAD
  },
  {
    key: 'koach-misgav',
    name: 'צומת כ"ח - משגב עם',
    roads: [899, 886],
    group: 'north',
    gpx: KOACH_MISGAV,
    cams: [{ title: 'צומת כ"ח', url: 'https://5e0d15ab12687.streamlock.net/live/KOAKH.stream/playlist.m3u8' }]
  },
  {
    key: 'sheva-miron',
    name: 'צומת שבע - פסגת הר מירון',
    roads: [866, 89],
    group: 'north',
    gpx: SHEVA_MIRON
  },
  {
    key: 'biranit-alkosh',
    name: 'בירנית - אלקוש',
    roads: [8944],
    group: 'north',
    gpx: BIRANIT_ALKOSH
  },
  {
    key: 'avdon-admit-even-menahem',
    name: 'עבדון – אדמית – אבן מנחם',
    roads: [899, 8993],
    group: 'north',
    gpx: AVDON_MENAHEM
  },
  {
    key: 'kfar-nahum-almagor',
    name: 'צומת כפר נחום – אלמגור',
    roads: [90, 807, 65, 85, 8277],
    group: 'north',
    gpx: KFAR_NAHUM_ALMAGOR
  },
  {
    key: 'poria-swiss',
    name: 'פוריה - יער שוויץ',
    roads: [7677],
    group: 'north',
    gpx: PORIA_SWISS
  },
  {
    key: 'mevo-hama',
    name: 'מבוא חמה',
    roads: [98],
    group: 'north',
    gpx: MEVO_HAMA
  },
  {
    key: 'ashdot-yaakov-shoshana',
    name: 'אשדות יעקב - אחוזת שושנה',
    roads: [7188],
    group: 'north',
    gpx: ASHDOT_YAAKOV
  },
  {
    key: 'tavor',
    name: 'מעלה תבור',
    roads: [7266],
    group: 'north',
    gpx: TAVOR,
    round: true,
    cams: [
      { title: 'כפר תבור', url: 'https://5e0d15ab12687.streamlock.net/live/KFARTAVOR.stream/playlist.m3u8' }
    ]
  },
  {
    key: 'gilboa',
    name: 'דרך נוף גלבוע',
    roads: [667],
    group: 'north',
    gpx: GILBOA
  },
  {
    key: 'menashe',
    name: 'פארק מנשה',
    roads: [],
    group: 'north',
    gpx: MENASHE,
    round: true
  },
  {
    key: 'gilad-mishmar-haemek',
    name: 'גלעד - משמר העמק',
    roads: [672],
    group: 'north',
    gpx: GILAD_MISHMAR
  },
  {
    key: 'aviel-kfar-kara',
    name: 'אביאל - כפר קרע',
    roads: [6533],
    group: 'north',
    gpx: AVIEL_KFAR_KARA,
    cams: [{ title: 'כפר קרע', url: 'https://5e0d15ab12687.streamlock.net/live/KFRAKARA.stream/chunklist_w708583221.m3u8' }]
  },
  {
    key: 'bet-oren',
    name: 'בית אורן',
    roads: [721],
    group: 'north',
    gpx: BETOREN,
    cams: [{ title: 'עתלית - כביש 4', url: 'https://5e0d15ab12687.streamlock.net/live/ATLITKVISH4.stream/playlist.m3u8' }]
  },
  {
    key: 'ofer',
    name: 'כביש גישה לעופר',
    roads: [],
    group: 'north',
    gpx: OFER,
    round: true
  },
  {
    key: 'susita',
    name: 'מעלה סוסיתא',
    roads: [],
    group: 'north',
    gpx: SUSITA,
    closed: true,
    info: ['הכביש סגור']
  }
];

const center = [
  {
    key: 'bet-arie',
    name: 'בית אריה',
    roads: [465],
    group: 'center',
    gpx: BET_ARIE,
  },
  {
    key: 'eshtaol-en-karem',
    name: 'אשתאול – רמת רזיאל – הר הרצל',
    roads: [395],
    group: 'center',
    gpx: ESHTAOL_EN_KAREM,
    cams: [
      { title: 'כיכר הסטף', url: 'https://5d8c50e7b358f.streamlock.net/live/SATAF.stream/playlist.m3u8' },
      { title: 'כיכר כרם', url: 'https://5d8c50e7b358f.streamlock.net/live/KAREM.stream/playlist.m3u8' }
    ]
  },
  {
    key: 'enkerem-betshemesh',
    name: 'עין כרם – נס הרים – בית שמש',
    roads: [386, 3866],
    group: 'center',
    gpx: ENKAREM_BETSHEMESH,
    cams: [
      { title: 'כיכר כרם', url: 'https://5d8c50e7b358f.streamlock.net/live/KAREM.stream/playlist.m3u8' }
    ]
  },
  {
    key: 'hemed-nataf',
    name: 'מחלף חמד - נטף',
    roads: [425],
    group: 'center',
    gpx: HEMED_NATAF,
    round: true
  },
  {
    key: 'agur-luzit',
    name: 'עגור - לוזית',
    roads: [353],
    group: 'center',
    gpx: AGUR_LUZIT
  },
  {
    key: 'kfar-adumim-maale-michmesh',
    name: 'כפר אדומים - מעלה מכמש',
    roads: [458],
    group: 'center',
    gpx: ADUMIM_MICHMESH
  },
  {
    key: 'alon-road',
    name: 'דרך אלון',
    roads: [458, 505, 508],
    group: 'center',
    gpx: ALON_ROAD
  },
  {
    key: 'bet-govrin-lachish',
    name: 'בית גוברין - לכיש - יער אמציה',
    roads: [3415, 35],
    group: 'center',
    gpx: BET_GOVRIN_LACHISH,
  },
  {
    key: 'malachim',
    name: 'יער המלאכים',
    roads: [],
    group: 'center',
    gpx: YAAR_MALACHIM,
    round: true
  },
  {
    key: 'ora-kenedi',
    name: 'צומת אורה - יד קנדי',
    roads: [3877],
    group: 'center',
    gpx: ORA_KENEDI,
    round: true
  },
  {
    key: 'aderet-adolam',
    name: 'אדרת - פארק עדולם - מצפה משואה',
    roads: [375],
    group: 'center',
    gpx: ADERET_ADOLAM
  },
  {
    key: 'ozem-helez-negba',
    name: 'עוצם – חלץ – צומת גבעתי – נגבה',
    roads: [352, 232],
    group: 'center',
    gpx: OZEM_NEGBA,
    cams: [
      { title: 'צומת גבעתי', url: 'ttps://5d8c50e7b358f.streamlock.net/live/GIVATI.stream/chunklist_w1502580724.m3u8' }
    ]
  }
];

const south = [
  {
    key: 'yaar-lehavim',
    name: 'יער להבים',
    roads: [],
    group: 'south',
    gpx: YAAR_LEHAVIM
  },
  {
    key: 'lahav',
    name: 'להב - מיתר',
    roads: [3253],
    group: 'south',
    gpx: LAHAV,
    cams: [
      { title: 'להב כביש 6', url: 'https://5d8c50e7b358f.streamlock.net/live/LAHAVKVISH6.stream/chunklist_w931890757.m3u8' }
    ]
  },
  {
    key: 'yaar-yatir',
    name: 'יער יתיר',
    roads: [316, 80],
    round: true,
    info: ['בכביש בתחילת הדרך קיימים בורות', 'הכביש סלול ברמה בינונית', 'מומלץ להמנע מנסיעה בלילה'],
    group: 'south',
    gpx: YATIR_FOREST
  },
  {
    key: 'arad-mezada',
    name: 'ערד - מצדה',
    roads: [3199],
    group: 'south',
    gpx: ARAD_MEZADA,
    round: true,
    cams: [
      { title: 'תל ערד', url: 'https://5e0d15ab12687.streamlock.net/live/TELARAD.stream/chunklist_w1333790872.m3u8' }
    ]
  },
  {
    key: 'sdom-arad',
    name: 'סדום - ערד',
    roads: [31],
    group: 'south',
    gpx: SDOMֹֹֹֹֹֹֹֹ_ARAD,
    cams: [
      { title: 'תל ערד', url: 'https://5e0d15ab12687.streamlock.net/live/TELARAD.stream/chunklist_w1333790872.m3u8' }
    ]
  },
  {
    key: 'rotem-arad',
    name: 'צומת הערבה - מישור רותם - ערד',
    roads: [25, 258, 31],
    group: 'south',
    gpx: ROTEM_ARAD
  },
  {
    key: 'idan-enyahav',
    name: 'עידן - עין יהב',
    roads: [],
    group: 'south',
    gpx: IDAN_ENYAHAV
  },
  {
    key: 'ramon-harif',
    name: 'מצפה רמון – הר חריף',
    roads: [171],
    round: false,
    group: 'south',
    gpx: RAMON_HARIF
  },
  {
    key: 'ramon-eilat',
    name: 'מצפה רמון – אילת',
    roads: [40, 12],
    round: false,
    group: 'south',
    gpx: RAMON_EILAT
  },
  {
    key: 'ktora-shorot',
    name: 'צומת קטורה – שחרות',
    roads: [],
    round: false,
    group: 'south',
    gpx: KTORA_SHOROT
  },
  {
    key: 'timna',
    name: 'בקעת תמנע',
    roads: [],
    round: false,
    group: 'south',
    gpx: TIMNA
  },


  // 29. צומת קליה – מלונות ים המלח (כביש 90)
  // 37. גן לאומי שיבטה
  // 38. כביש החממות, קדש ברנע



  {
    key: 'mezoki-dragot',
    name: 'מצוקי דרגות',
    roads: [],
    group: 'south',
    gpx: MEZOKI_DRAGOT,
    round: true
  },
  {
    key: 'machtesh-gadol',
    name: 'המכתש הגדול',
    roads: [225, 227],
    group: 'south',
    gpx: MACHTESH_GADOL,
    round: false
  },









  // {
  //   key: 'road10-north',
  //   name: 'כביש 10 - חלק צפוני',
  //   roads: [10],
  //   round: false,
  //   group: 'south',
  //   gpx: ROAD10_NORTH
  // },
  {
    key: 'road10-south',
    name: 'כביש 10 - חלק דרומי',
    roads: [10],
    closed: true,
    group: 'south',
    gpx: ROAD_10,
    info: ['הכביש סגור']
  },
  {
    key: 'akrabim',
    name: 'מעלה עקרבים',
    roads: [227],
    closed: true,
    group: 'south',
    gpx: AKRABIM,
    info: ['הכביש סגור']
  }
];

const trips = [
  {
    key: 'seat-darom-11',
    name: 'טיול סיאט דרום',
    roads: [31, 3199, 90, 25, 258],
    group: 'private',
    gpx: SEAT_DAROM_11
  },
  {
    key: 'tiul-5',
    name: '22/05/2020',
    roads: [],
    group: 'private',
    gpx: TIUL_5
  },
  {
    key: 'to-arie',
    name: 'נסיעה לאריה',
    roads: [],
    group: 'private',
    gpx: TO_ARIE
  },
  {
    key: 'reno',
    name: 'טיול רנו',
    roads: [],
    group: 'private',
    gpx: RENO
  },
  {
    key: 'sdom-arad-mezada',
    name: 'סדום - ערד - מצדה',
    roads: [31, 3199],
    group: 'trip',
    gpx: SDOMֹֹֹֹֹֹֹֹ_ARAD_MEZADA,
    cams: [
      { title: 'תל ערד', url: 'https://5e0d15ab12687.streamlock.net/live/TELARAD.stream/chunklist_w1333790872.m3u8' }
    ]
  },
  {
    key: 'zriha',
    name: 'טיול זריחה לדרום',
    roads: [505, 458, 90, 31, 358, 3415],
    group: 'trip',
    gpx: ZRIHA,
    cams: [
      { title: 'תל ערד', url: 'https://5e0d15ab12687.streamlock.net/live/TELARAD.stream/chunklist_w1333790872.m3u8' }
    ]
  },
  {
    key: 'ness-harim',
    name: 'נס הרים - אשתאול',
    roads: [395, 386, 3866],
    group: 'trip',
    gpx: NESS,
    round: true,
    cams: [
      { title: 'כיכר הסטף', url: 'https://5d8c50e7b358f.streamlock.net/live/SATAF.stream/chunklist_w917146249.m3u8' },
      { title: 'כיכר כרם', url: 'https://5d8c50e7b358f.streamlock.net/live/KAREM.stream/chunklist_w1952566860.m3u8' },
      { title: 'שמשון', url: 'https://5e0d15ab12687.streamlock.net/live/SHIMSHON.stream/chunklist_w2115764201.m3u8' }
    ]
  },
];

const ROADS = [...north, ...center, ...south, ...trips];
export { Roads, Road, RoadItem, RoadsMap, Weather, ROADS };

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Switch from 'antd/lib/switch';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import { UnorderedListOutlined, EnvironmentOutlined } from '@ant-design/icons';

import { ROADS, RoadItem, RoadsMap } from './';
import './Roads.scss';

const { Panel } = Collapse;

const Roads = function () {
    const [mapView, setMapView] = useState(JSON.parse(localStorage.getItem('mapView')));
    const [showCameras, setShowCameras] = useState(false);
    const [showGasStations, setShowGasStations] = useState(false);
    const [roadInfo, setRoadInfo] = useState([]);

    useEffect(() => {
        fetch('/api/iroads')
            .then(res => res.json())
            .then(({ roads }) => setRoadInfo(roads));
    }, []);

    const grouped = _.groupBy(ROADS, 'group');

    const iter = (road, idx) => <RoadItem key={idx} idx={idx} road={road} warnings={_.filter(roadInfo, (i) => road.roads.includes(parseInt(i.number)))}/>;

    return (<div>
        <h1>כבישי נהיגה בישראל</h1>
        {mapView
            ? (<React.Fragment>
                <Button className="btn" onClick={() => { localStorage.setItem('mapView', 'false'); setMapView(false) }}><UnorderedListOutlined />&nbsp; רשימה</Button>
                &nbsp; <Switch checked={showCameras} onChange={(val) => setShowCameras(!showCameras)} />
                &nbsp;מצלמות

                &nbsp; <Switch checked={showGasStations} onChange={(val) => setShowGasStations(!showGasStations)} />
                &nbsp;דלק
            </React.Fragment>)
            : <Button className="btn" onClick={() => { localStorage.setItem('mapView', 'true'); setMapView(true) }}><EnvironmentOutlined />&nbsp; תצוגת מפה</Button>}

        {mapView
            ? <RoadsMap showCameras={showCameras} showGasStations={showGasStations} />
            : <Collapse defaultActiveKey={['north', 'center', 'south', 'trip']}>
                <Panel header="צפון" key="north">{grouped.north.map(iter)}</Panel>
                <Panel header="מרכז" key="center">{grouped.center.map(iter)}</Panel>
                <Panel header="דרום" key="south">{grouped.south.map(iter)}</Panel>
                <Panel header="טיולים" key="trip">{grouped.trip.map(iter)}</Panel>
            </Collapse>}
    </div>);
};

export default Roads;

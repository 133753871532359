import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Popover from 'antd/lib/popover';
import { InfoCircleFilled } from '@ant-design/icons';
import { weatherTranslate } from './weather-helper';

const Weather = function ({ lat, lon, icnCallback, className }) {
    const [weather, setWeather] = useState({});

    useEffect(() => {
        fetch(`/api/weather/${lat}/${lon}`)
            .then(res => res.json())
            .then(data => {
                setWeather(data.weather);
                icnCallback && icnCallback(_.get(data, 'weather.list[0].weather[0].icon', null), _.get(data, 'weather.city.coord', {}));
            });
    }, [icnCallback, lat, lon]);

    const [
        last3h,
        temp,
        icon,
        weatherId,
        sunrise,
        sunset,
        cityId,
        cityName
    ] = _.at(weather, [
        'list[0].rain.3h',
        'list[0].main.temp',
        'list[0].weather[0].icon',
        'list[0].weather[0].id',
        'city.sunrise',
        'city.sunset',
        'city.id',
        'city.name'
    ]);

    const sunsetTime = new Date(sunset*1000).toLocaleTimeString();
    const sunriseTime = new Date(sunrise*1000).toLocaleTimeString();

    const weatherStr = weatherId && ` - ${weatherTranslate[weatherId][0]}, ${weatherTranslate[weatherId][1]} `;

    const weatherContent = (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <div>זריחה: {sunriseTime}</div>
        <div>שקיעה: {sunsetTime}</div>

        <h2>תחזית עתידית לפי שעות:</h2>
        {(weather.list || []).map(w => (<div key={w.dt_txt} style={{textAlign: 'right', borderBottom: '1px solid lightgrey'}}>
            <div><b>{w.dt_txt.replace(' ', ' - ')}</b></div>
            <div style={{textAlign: 'right'}}>
                <img alt={w.dt_txt} src={`https://openweathermap.org/img/wn/${w.weather[0].icon || '01d'}@2x.png`} width={40}/>
                {Math.round(w.main.temp)}°&nbsp;-&nbsp;
                {weatherTranslate[w.weather[0].id][0]}, {weatherTranslate[w.weather[0].id][1]}

                {(w.rain && w.rain['3h']) && ` (~גשם: ${w.rain['3h']}מ"מ)`}
                {(w.snow && w.snow['3h']) && ` (~שלג: ${w.snow['3h']}מ"מ)`}
            </div>
        </div>))}

        <a href={`https://openweathermap.org/city/${cityId}`} target="_blank" rel="noopener noreferrer">
            מזג האוויר נמדד ב-{cityName}
        </a>

    </div>);

    return (<div className={classnames("section-header", className)} style={{ paddingRight: 14 }}>
        <div>
            <Popover trigger="click" placement="leftBottom" content={weatherContent}>
                <img src={`https://openweathermap.org/img/wn/${icon || '01d'}@2x.png`} width={40} alt={weatherStr}/>
            </Popover>
            <strong>{Math.round(temp)}° </strong>
            {weatherStr}
            {last3h && ` - ממוצע גשם: ${last3h}mm`}
        </div>
        <Popover trigger="click"
            placement="leftBottom"
            content={weatherContent}>
        <InfoCircleFilled style={{ fontSize: 21}}/>
        </Popover>
    </div>);
};

export default Weather;

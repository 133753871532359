import React, { useState } from 'react';
import Tooltip from 'antd/lib/tooltip';
import  { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { load, speed } from '../Misc/types/Tires/helpers';
import './TavNeche.scss';
import { tireSizeParser } from '../parsers';
import ListItem from './ListItem';

const tires = (tireWidth, tireRatio, tireDiameter) => (<>
    <a href={`https://www.tirerack.com/tires/TireSearchResults.jsp?width=${tireWidth}/&ratio=${tireRatio}&diameter=${tireDiameter}`} target="_blank" rel="noopener noreferrer"><img src="https://www.tirerack.com/favicon.ico" alt="tirerack" width="16" height="16" /></a>
    {' | '}
    <a href={`https://www.tyrereviews.co.uk/Search/Size/${tireWidth}-${tireRatio}-${tireDiameter}`} target="_blank" rel="noopener noreferrer"><img src="https://www.tyrereviews.co.uk/images/favicon.ico" alt="tyrereviews" width="16" height="16" /></a>
    {' | '}
    <a href={`https://tirepressure.com/${tireWidth}-${tireRatio}r${tireDiameter}-tire-pressure`} target="_blank" rel="noopener noreferrer"><img src="https://tirepressure.com/assets/images/favicon.png" alt="tirepsi" width="16" height="16" /></a>
</>);

function Tires({ zmig_kidmi, zmig_ahori, extra }) {
    const [copied, setCopied] = useState(false);

    const {
        front: { width: fTireWidth, ratio: fTireRatio, diameter: fTireDiameter },
        back: { width: rTireWidth, ratio: rTireRatio, diameter: rTireDiameter }
    } = tireSizeParser(zmig_kidmi, zmig_ahori);

    return (<>
        <li>
            <div className="row">
                <span className="cell" onClick={() => {
                    if (navigator.clipboard) {
                        setCopied(true)  
                        navigator.clipboard.writeText(`${zmig_kidmi} ${extra.kod_omes_tzmig_kidmi}${extra.kod_mehirut_tzmig_kidmi}`)
                        }
                    }
                }>
                    {navigator.clipboard && <>{copied ? <CheckOutlined/> : <CopyOutlined/>}</>}
                    {' '}                
                    <strong>מידת צמיג קדמי:</strong>
                    <span style={{ display: 'inline-block', direction: 'ltr' }}>
                        {zmig_kidmi}{' '}
                        {Number(extra.kod_omes_tzmig_kidmi) !== 0 && <Tooltip placement="topRight" title={load.data.find(l =>  Number(l.in) === Number(extra.kod_omes_tzmig_kidmi)).kg} style={{ marginRight: 10 }}>
                            <u style={{color: 'blue'}}>{extra.kod_omes_tzmig_kidmi}</u>
                        </Tooltip>}
                        {' '}
                        {extra.kod_mehirut_tzmig_kidmi && <Tooltip placement="topRight" title={speed.data.find(l => l.in === (extra.kod_mehirut_tzmig_kidmi || '').toUpperCase()).kmh} style={{ marginRight: 10 }}>
                            <u style={{color: 'blue'}}>{extra.kod_mehirut_tzmig_kidmi}</u>
                        </Tooltip>}
                    </span>
                </span>
                <span className="cell">
                    {tires(fTireWidth, fTireRatio, fTireDiameter)}
                </span>
            </div> 
        </li>

        {zmig_kidmi === zmig_ahori ? <ListItem title="" value="* צמיג אחורי זהה לקדמי" /> : (<>
            <li>
                <div className="row">
                    <span className="cell" onClick={() => {
                        if (navigator.clipboard) {
                            setCopied(true)  
                            navigator.clipboard.writeText(`${zmig_kidmi} ${extra.kod_omes_tzmig_kidmi}${extra.kod_mehirut_tzmig_kidmi}`)
                            }
                        }
                    }>
                        {navigator.clipboard && <>{copied ? <CheckOutlined/> : <CopyOutlined/>}</>}
                        {' '}                
                        <strong>מידת צמיג אחורי:</strong>
                        <span style={{ display: 'inline-block', direction: 'ltr' }}>
                            {zmig_ahori}{' '}
                            {Number(extra.kod_omes_tzmig_ahori) !== 0 && <Tooltip placement="topRight" title={load.data.find(l =>  Number(l.in) === Number(extra.kod_omes_tzmig_ahori)).kg} style={{ marginRight: 10 }}>
                                <u>{extra.kod_omes_tzmig_ahori}</u>
                            </Tooltip>}
                            {' '}
                            {extra.kod_mehirut_tzmig_ahori && <Tooltip placement="topRight" title={speed.data.find(l => l.in === (extra.kod_mehirut_tzmig_ahori || '').toUpperCase()).kmh} style={{ marginRight: 10 }}>
                                <u>{extra.kod_mehirut_tzmig_ahori}</u>
                            </Tooltip>}
                            
                        </span>
                    </span>
                    <span className="cell">
                        {tires(rTireWidth, rTireRatio, rTireDiameter)}
                    </span>
                </div> 
            </li>
        </>)}

        {Number(extra.shinui_zmig_ind) === 1 && <ListItem title="" value="* צמיג שונה מהמקור" />}
    </>);
}

export default Tires;
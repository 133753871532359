import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import * as proj from 'ol/proj';
import { Style, Icon as Ico } from 'ol/style';

import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as LayerTile, Vector as VectorLayer } from 'ol/layer';
import { OSM as SourceOSM, Vector as VectorSource } from 'ol/source';

import RACE_FLAG from './assets/flag.svg';

const openNavigation = (lat, lon) => {
    if /* if we're on iOS, open in Apple Maps */
        ((navigator.platform.indexOf("iPhone") !== -1) ||
        (navigator.platform.indexOf("iPad") !== -1) ||
        (navigator.platform.indexOf("iPod") !== -1))
        window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);

    else /* else use Google */
        window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
}

export const mapHook = (lat, lon, element) => {
    const map = new Map({
        target: null,
        layers: [new LayerTile({
            source: new SourceOSM()
        })],
        view: new View({
            center: proj.fromLonLat([lat, lon], 'EPSG:3857'),
            zoom: 15
        })
    });

    map.setTarget(element);

    const features = [];

    const feature = new Feature({
        geometry: new Point(proj.fromLonLat([lat, lon])),
        location: { lat, lon }
    });

    var style = new Style({
        image: new Ico({
            src: RACE_FLAG,
            size: [478, 478],
            scale: 0.05
        })
    });

    feature.setStyle(style);

    features.push(feature);

    const featuresLayer = new VectorLayer({
        source: new VectorSource({ features })
    });

    map.addLayer(featuresLayer);

    map.on('click', (evt) => {
        const feature = map.getFeaturesAtPixel(evt.pixel);
        if (feature && feature[0].values_.location) {
            const { lat, lon } = feature[0].values_.location;
            openNavigation(lat, lon);
        }
    });
}
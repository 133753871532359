import React, { useState, useRef  } from 'react';
import {  Redirect  } from "react-router-dom";
import Button from 'antd/lib/button';

import './TypeSearchInput.scss';

const TypeSearchInput = React.forwardRef((props, ref) => {
    const [toType, setType] = useState(null);
    const inputEl = ref ? ref : useRef(null);



    return (<React.Fragment>
        {toType && <Redirect to={`/types/${toType}`} />}
        <h1>הכנס דגם רכב למידע</h1>
        <div className="searchInput">
            <input type="text"
                size="large"
                ref={inputEl}
                defaultValue={props.kinuy}
                onKeyUp={(e) => e.key === 'Enter' && setType(inputEl.current.value)} />
            <Button size="large" type="primary" onClick={() => setType(inputEl.current.value)}>
                אתר דגמים
            </Button>
        </div>





    </React.Fragment>);
});

export default TypeSearchInput;

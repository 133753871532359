import React, { Component } from 'react';
import _ from 'lodash';
import Button from 'antd/lib/button';

// import './style.scss';

export default class CarStolen extends Component {
    static getDerivedStateFromProps(props, state) {
        if (props.carNumber !== state.carNumber) {
            return {
                carNumber: props.carNumber,
                stolen: {}
            };
        }

        return null;
    }

    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');

        this.state = {
            debug,
            carNumber: props.carNumber,
            stolen: {},
            loadingStolen: false
        };
    }

    getStolen = () => {
        const { carNumber } = this.state;

        this.setState({
            loadingStolen: true
        }, () => fetch(`/api/stolen/${carNumber}`)
            .then(resCar => resCar.json())
            .then(stolen => this.setState({
                loadingStolen: false,
                stolen
            }))
            .catch(err => console.log(err))
        );
    };

    render() {
        const { stolen, loadingStolen, debug } = this.state;

        return (
            <div className="section price" style={{ padding: '0px 10px' }}>
                <h2>בדיקת רכב גנוב</h2>

                {_.isEmpty(stolen)
                    ? (<Button type="primary" loading={loadingStolen} onClick={this.getStolen}>
                        בדוק רכב
                    </Button>)
                    : (<div className={stolen.isStolen ? 'stolen' : 'not-stolen'}>{stolen.stolen_text}</div>)}

                {/* <Button href="https://www.gov.il/apps/police/stolencar/" target="_blank" rel="noopener noreferrer">בדוק באתר המשטרה</Button> */}

                {debug === 'true' && <pre>recalls: {JSON.stringify(stolen, null, 2)}</pre>}
            </div>
        );
    }
};

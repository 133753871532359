import React, { createRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Progress } from 'antd';
import { HeartTwoTone } from '@ant-design/icons';


import { allItems } from './items';
import Timer from '../Theory/Timer';

import './style.scss';

const TOYS_COUNT = 6;
const TIME_SEC = 25;
const ITEM_SIZE = 60;
const BOARD_SIZE = 600;
const TRIES = 3;

const ShakedGame = () => {
    const [toys, setToys] = useState([]);
    const [items, setItems] = useState([]);
    const [started, setStarted] = useState(false);
    const [tries, setTries] = useState(TRIES);
    const [score, setScore] = useState(0);
    const [clicked, setClicked] = useState([]);
    // const [level, setLevel] = useState(1);

    const timer = createRef();

    useEffect(() => {
        const _score = (_.uniq(clicked).length * 100) / Math.max(TOYS_COUNT, 1);

        setScore(parseInt(_score));

        if (_score === 100) {
            setStarted(false);
            timer.current.end();
        }

        const success = _.uniq(clicked).length;

        if (success === TOYS_COUNT) {
            setStarted(false);
            timer.current.end();
        }
    }, [clicked]);

    useEffect(() => {
        clicked.map((item) => {
            document.querySelector(`.${item}`).style.opacity =  '1';
        });
        setClicked([]);
    }, [items]);

    useEffect(() => {
        if (tries === 0) {
            setStarted(false);
            timer.current.end();
        }
    }, [tries]);

    useEffect(() => {
        if (started) {
            setClicked([]);
            setScore(0);
            setTries(TRIES);

            timer.current.start();
        }
    }, [started]);

    const handleItemClick = (toys, item) => {

        if (toys.includes(item)) {
            setClicked((oldClicked) => [...oldClicked, item.title]);

            document.querySelector(`.${item.title}`).style.opacity =  '0';
        }
        else {
            setTries((oldTries) => oldTries - 1);
        }
    }

    const handleTimeEnd = () => {
        setStarted(false);
    }

    const start = () => {
        const _toys = allItems.map(x => ({ x, r: Math.random() }))
            .sort((a, b) => a.r - b.r)
            .map(a => a.x)
            .slice(0, TOYS_COUNT);

        const _items = allItems.map((item) => {
            const left = (Math.random() * (BOARD_SIZE - ITEM_SIZE)).toFixed();
            const top = (Math.random() * (BOARD_SIZE - ITEM_SIZE)).toFixed();

            return (<div onClick={() => handleItemClick(_toys, item)}
                key={item.title}
                className={classnames('game-item', item.title, { toy: _toys.includes(item) })}
                style={{
                    backgroundImage: `url('${item.url}')`,
                    width: ITEM_SIZE,
                    height: ITEM_SIZE,
                    left: `${left}px`,
                    top: `${top}px`
                }}></div>);
        });

        setItems(_items);
        setToys(_toys);
        setStarted(true);
    }

    return (<div>
        <h2>המשחק של שקד</h2>
        <p>יש למצוא את כל הפריטים שמופיעים בצד ימין וללחוץ עליהם בצד שמאל.</p>

        <div className="game-panel">
            <div><Timer circle seconds={TIME_SEC} minutes={0} ref={timer} onEnd={handleTimeEnd}/></div>

            {tries >= 0 && <div className="tries">
                {_.range(TRIES-tries).map(i => <HeartTwoTone key={i} style={{fontSize: 26}} twoToneColor="#cccccc" />)}
                {_.range(tries).map(i => <HeartTwoTone key={i} style={{fontSize: 26}} twoToneColor="#ff0000" />)}
            </div>}

            <div className="game-score">{score}%</div>
            <Progress percent={score} status="active" className="score" />
        </div>

        <main style={{ height: (BOARD_SIZE + 2) }}>
            <aside style={{ height: (BOARD_SIZE + 2) }}>
                <h3>רשימת הפריטים</h3>

                {!started &&
                    <button onClick={() => start()}>התחל משחק</button>}

                {started && toys.map((item) => (<div key={item.title}>
                    <div className={classnames(['game-item', 'toy'])} style={{
                        position: 'static',
                        backgroundImage: `url('${item.url}')`,
                        width: ITEM_SIZE,
                        height: ITEM_SIZE
                    }}/>
                </div>))}
            </aside>

            {(started || score > 0 || tries < TRIES) && (<div className={classnames(['main-game'])}
                    style={{ minWidth: BOARD_SIZE, width: BOARD_SIZE, minHeight: BOARD_SIZE, height: BOARD_SIZE }}>

                    {score === 100 && <div className="hoorai">כל הכבוד הצלחת למצוא את הכל!</div>}
                    {!started && score < 100 && tries > 0 && <div className="hoorai">כמעט! הצלחת למצוא {score}%, יפה מאוד.</div>}
                    {!started && tries === 0 && <div className="hoorai">נגמרו לך הפסילות, מצאת {score}%.</div>}

                    {items}
                </div>)}
        </main>
    </div>);
}

export default ShakedGame;
import React, { useState, createRef } from 'react';
import _ from 'lodash';
import Table from 'antd/lib/table';
import Card from 'antd/lib/card';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import Space from 'antd/lib/space';
import Collapse from 'antd/lib/collapse';
import Icon, { CalendarOutlined } from '@ant-design/icons';

import {
    forwardCross,
    xPattern,
    rearwardCross,
    frontToRear,
    sideToSide,
    tire,
    license,
    tireDate,
    calcTireSize,
    calcTireISize,
    size, load, speed, TireIcon, getDateOfWeek,
    DEFAULT_BAR_VALUE, RATES, DEFAULT_TABLE_HEIGHT
} from './helpers';

import './style.scss';

const { Option } = Select;
const { Title } = Typography;
const { Panel } = Collapse;

function Tires() {
    const [value, setValue] = useState(DEFAULT_BAR_VALUE);
    const [mida, setMida] = useState('BAR');
    const [date, setDate] = useState(null);
    const [tireSize, setTireSize] = useState(null);
    const [tireISize, setTireISize] = useState(null);

    const w = createRef();
    const h = createRef();
    const r = createRef();
    
    const iw = createRef();
    const ih = createRef();
    const ir = createRef();

    return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <h1>מידע שימושי: צמיגים</h1>

            <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://tiresize.com/calculator/">חישוב צמיגים</a></li>
            </ul>


            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Card size="small" title="המרת יחידות לחץ אוויר בצמיגים" style={{ width: 370, margin: '10px auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>
                        <Icon component={TireIcon} />
                        <Input type="text"
                            defaultValue={DEFAULT_BAR_VALUE}
                            onChange={(e) => setValue(e.target.value)}
                            style={{ width: 100, marginLeft: 8 }}/>
                        <Select value={mida}
                            style={{ width: 80, margin: '0 8px' }}
                            onChange={(value) => setMida(value)}>
                            <Option value="PSI">PSI</Option>
                            <Option value="BAR">BAR</Option>
                        </Select>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        direction: 'ltr',
                        padding: 6,
                        marginTop: 10,
                        borderTop: '1px dashed #aaaaaa'
                    }}>{(value * RATES[mida]).toFixed(2)} {Object.keys(_.omit(RATES, [mida])).join()}</div>
                </Card>

                <Card size="small" title="בדיקת תאריך ייצור של צמיג" style={{ width: 370, margin: '10px auto' }}>
                    <img src={tireDate} width="100%" height="auto" alt="תאריך יצור של צמיג" />
                    <p>תאריך הצמיג מוטבע באליפסה בדופן הצמיג ומורכב ממספר שבוע והשנה</p>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>
                        <CalendarOutlined style={{ fontSize: 30, color: '#717171' }} />
                        <Input type="text"
                            onChange={(e) => setDate(getDateOfWeek(e.target.value))}
                            style={{ width: 100, marginLeft: 8, borderRadius: 35 }}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        direction: 'ltr',
                        padding: 6,
                        height: 35,
                        marginTop: 10,
                        borderTop: '1px dashed #aaaaaa'
                    }}>{date}</div>
                </Card>

                <Card size="small" title="המרת מידות לצמיגים" style={{ width: 370, margin: '10px auto' }}>
                    {/* ... */}
                    <p>מילימטרים לאינצ'ים</p>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>
                        {/* <CalendarOutlined style={{ fontSize: 30, color: '#717171' }} /> */}
                        <Input type="text"
                            ref={w}
                            defaultValue={267}
                            style={{ width: 100, marginLeft: 8 }}/>/
                        <Input type="text"
                            ref={h}
                            defaultValue={95}
                            style={{ width: 100, marginLeft: 8 }}/>R
                        <Input type="text"
                            ref={r}
                            defaultValue={15}
                            style={{ width: 100, marginLeft: 8 }}/>
                        <button onClick={(e) => console.log(w.current.state.value) || setTireSize(calcTireSize(w.current.state.value, h.current.state.value, r.current.state.value))}>Calculate</button>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        direction: 'ltr',
                        padding: 6,
                        height: 35,
                        marginTop: 10,
                        borderTop: '1px dashed #aaaaaa'
                    }}>{tireSize}</div>
                
                    <p>אינצ'ים למילימטרים</p>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'center' }}>
                        {/* <CalendarOutlined style={{ fontSize: 30, color: '#717171' }} /> */}
                        <Input type="text"
                            ref={ih}
                            defaultValue={35}
                            style={{ width: 100, marginLeft: 1 }}/>.
                        <Input type="text"
                            ref={iw}
                            defaultValue={10.5}
                            style={{ width: 100, marginLeft: 1 }}/>.
                        <Input type="text"
                            ref={ir}
                            defaultValue={15}
                            style={{ width: 100, marginLeft: 1 }}/>
                        <button onClick={(e) => setTireISize(calcTireISize(ih.current.state.value, iw.current.state.value, ir.current.state.value))}>Calculate</button>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        direction: 'ltr',
                        padding: 6,
                        height: 35,
                        marginTop: 10,
                        borderTop: '1px dashed #aaaaaa'
                    }}>{tireISize}</div>
                </Card>
            </div>
            <Title level={3}>שינוי מיקום הצמיגים ברכב</Title>

            <Collapse defaultActiveKey={[]}>
                <Panel header={<Title level={4}>עבור צמיגים שהם ללא כיוון</Title>} key="a">
                    <div className="row">
                        <Card size="small" title="חילוף צלב קדמי" className="card">
                            <img src={forwardCross} alt="חילוף צלב קדמי" />
                            <p>זהו הדפוס הנפוץ ביותר לרכבי הנעה קדמית. צמיגי הסרן הקדמי מועברים ישירות לאחור ואילו הצמיגים האחוריים מועברים באלכסון לצד הנגדי של הציר הקדמי.</p>
                        </Card>

                        <Card size="small" title="חילוף תבנית X" className="card">
                            <img src={xPattern} alt="חילוף תבנית X" />
                            <p>מומלץ לרכבים בהנעה קדמית כמו משאיות קלות וסדאן, כל הצמיגים מועברים באלכסון, כלומר צמיגים עוברים מציר אחד להיפך וממקמים אותם מחדש מצד לצד.</p>
                        </Card>

                        <Card size="small" title="חילוף צלב אחורי" className="card">
                            <img src={rearwardCross} alt="חילוף צלב אחורי" />
                            <p>לרכבים בעלי הנעה לכל הגלגלים או הנעה אחורית, מומלץ להשתמש בתבנית צלב אחורית. צמיגים אחוריים מועברים לסרן הקדמי ונשארים באותו צד של הרכב בזמן שהצמיגים הקדמיים מועברים לצדדים מנוגדים של הציר האחורי.</p>
                        </Card>
                    </div>
                </Panel>

                <Panel header={<Title level={4}>עבור צמיגים שהם עם כיוון או שונים בין קדמי לאחורי</Title>} key="b">
                    <div className="row">
                        <Card size="small" title="חילוף חזית אחור" className="card">
                            <img src={frontToRear} alt="חילוף חזית אחור" />
                            <p>כל הצמיגים מועברים מציר אחד למשנהו אך נשארים באותו הצד של הרכב. לדוגמה, הצמיג השמאלי הקדמי מועבר לצד השמאלי של הציר האחורי ואילו הצמיג השמאלי האחורי ממוקם על הצד השמאלי של הציר הקדמי.</p>
                        </Card>

                        <Card size="small" title="חילוף צד לצד" className="card">
                            <img src={sideToSide} alt="חילוף צד לצד" />
                            <p>צמיגי ביצוע שונים בגודל שונה בצדדים הקדמיים והאחוריים,<br />
                    כל הצמיגים מתחלפים עם בן זוגם בגודל זהה ונשארים על אותו ציר. שני הצמיגים האחוריים עוברים זה עם זה לצד השני לעומת שני הצמיגים הקדמיים עושים את אותו הדבר.</p>
                        </Card>
                    </div>
                </Panel>
            </Collapse>




            <Title level={3}>טבלאות מידע על  צמיגים</Title>


            <img src={tire} alt="צמיג" className="tire-explain" />
            <img src={license} alt="רשיון רכב" className="tire-explain" />

            <Title level={4}>טבלת רוחב צמיג אפשרי לפי רוחב ג'נט</Title>
            <Table bordered
                scroll={{ y: DEFAULT_TABLE_HEIGHT }}
                size="small"
                pagination={false}
                dataSource={size.data}
                columns={size.columns} />

            <Title level={4}>טבלת קודי עומס</Title>
            <Table bordered
                scroll={{ y: DEFAULT_TABLE_HEIGHT }}
                size="small"
                pagination={false}
                dataSource={load.data}
                columns={load.columns} />

            <Title level={4}>טבלת קודי מהירות</Title>
            <Table bordered
                scroll={{ y: DEFAULT_TABLE_HEIGHT }}
                size="small"
                pagination={false}
                dataSource={speed.data}
                columns={speed.columns} />


        </Space>
    );
};

export default Tires;
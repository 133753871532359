import React, { Component } from 'react';
import TypeSearchInput from './TypeSearchInput'
import { Link } from 'react-router-dom';
import axios from 'axios';

export default class CarType extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');
        const kinuy = props.match.params.kinuy;

        this.state = {
            debug,
            kinuy,
            cars: [],
            loadingCars: true
        };
    }

    componentDidMount() {
        this.fetchData(this.state.kinuy);
    };

    // componentDidUpdate(prevProps) {
    //     if (this.props.kinuy !== prevProps.kinuy) {
    //         this.setState({ loadingCars: true, cars: [] });
    //         this.fetchData(this.state.kinuy);
    //     }
    // }

    fetchData = (kinuy) => axios.get(`/kinuy/${kinuy}`)
        .then(({ data: {data} }) => this.setState({
            cars: data,
            loadingCars: false
        })).catch(() => {
            this.setState({ cars: [] })
        }).finally(() => {
            this.setState({ loadingCars: false })
        });

    render() {
        const { kinuy, cars, loadingCars, debug } = this.state;

        // "kinuy_mishari": "IBIZA",
        // "tozeret_nm": "סיאט ספרד",
        // "degem_nm": "6L12G4",
        // "degem_cd": 870,
        // "shnat_yitzur": 2004

        return (<>
            <TypeSearchInput ref={this.input} kinuy={kinuy} />

            <div style={{ margin: '10px 0' }}>
                {loadingCars
                    ? (<div>מחפש רכבים....</div>)
                    : (<div>

                        {cars.map((car, idx) => (
                            <div><Link key={idx} to={`/type/${car.kinuy_mishari}/${car.degem_nm}/${car.shnat_yitzur}/`} title={car.degem_nm} style={{ marginBottom: 30 }}>
                                <b>{car.tozeret_nm} דגם {car.kinuy_mishari} שנת {car.shnat_yitzur}</b>
                                <br />
                                שם דגם: {car.degem_nm}
                                <br />
                                קוד דגם: {car.degem_cd}
                            </Link><hr /></div>
                        ))}
                    </div>)}

                {debug === 'true' && <pre>cars: {JSON.stringify(cars, null, 2)}</pre>}
            </div>
        </>
        );
    }
};

import React, { Component } from 'react';

import './TotalOfDegem.scss';
import ListItem from './ListItem';
import axios from 'axios';

export default class TotalOfDegem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            total: null,
            loading: true
        };
    }

    componentDidMount() {
        const { degem } = this.props;

        axios.get(`/carcount/${degem}`)
            .then(({ data }) => this.setState({
                total: data.total,
                loading: false
            }))
            .catch((e) => this.setState({ loading: false }));
    };

    render() {
        const { degem } = this.props;
        const { total, loading } = this.state;

        return (
            !!total
                ? (<div className="totalCount">
                    {loading
                        ? (<div>סופר מכוניות....</div>)
                        : (<div>
                            <ListItem tooltip="מציג כמות רכבים שעלו לכביש משנת 2018 ועד החודש הקלנדרי הקודם - פחות רכבים שירדו מהכביש החל משנת 2010 ובסטטוס ביטול סופי"
                            feat="כמות רכבים על הכביש"
                            value={total.toLocaleString()}
                            extra={<small>(לדגם: {degem})</small>} />
                        </div>)}
                </div>)
                : null
        );
    }
};

import React, { useEffect } from 'react';

// import _ from 'lodash';
// import Table from 'antd/lib/table';
// import Input from 'antd/lib/input';
// import Select from 'antd/lib/select';
// import Typography from 'antd/lib/typography';
// import Space from 'antd/lib/space';
// import Collapse from 'antd/lib/collapse';

import { CarFilled } from '@ant-design/icons';
import Card from 'antd/lib/card';
import { mapHook } from './helpers';

// import './style.scss';


const Pezael = ({ map, lat, lon, title, children }) => {
    useEffect(() => mapHook(lat, lon, map), [lat, lon, map]);

    return (
        <Card size="small"
            title={<>
                {title}
                <CarFilled style={{float: 'left'}} className="car-btn" onClick={() => {
                    window.open(`https://www.waze.com/ul?ll=${lon}%2C${lat}&navigate=yes&zoom=17`);
                }} />
            </>}
            style={{ width: 370, margin: '10px auto' }}>

            <div id={map} style={{ width: "100%", height: "300px" }} />
            {children}
        </Card>
    );
}

export default Pezael;
import React from 'react';

// import Table from 'antd/lib/table';
// import Card from 'antd/lib/card';
// import Input from 'antd/lib/input';
// import Select from 'antd/lib/select';
// import Typography from 'antd/lib/typography';
// import Collapse from 'antd/lib/collapse';

// import './style.scss';

const Pezael = () => (
    <div>
        כאן יהיה מידע נוסף על המסלול בפצאל
    </div>
);

export default Pezael;
import React, { Component } from 'react';
import Button from 'antd/lib/button';
import { ListItem } from './';
import axios from 'axios';
import { convertCCToLiters, convertHorsepowerToKilowatts, translateTozar } from '../helpers';

// import './style.scss';

export default class CarBattery extends Component {
    constructor() {
        super();
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');

        this.state = {
            debug,
            battery: null,
            loadingBattery: false
        };
    }

    getBattery = () => {
        const {   shnat_yitzur, ...rest  } = this.props;
        const { tozar, kinuy_mishari, koah_sus, degem_nm, ramat_gimur, nefah_manoa } = rest.carData;

        this.setState({
            loadingBattery: true
        }, () => axios.get(`/bat/${shnat_yitzur}/${translateTozar(tozar)}/${kinuy_mishari} ${degem_nm} ${ramat_gimur}/${convertCCToLiters(nefah_manoa)} ${convertHorsepowerToKilowatts(koah_sus)}kw ${koah_sus}hp/`)
            .then(({ data: { batteryReplacementInformations, vehicle, url } }) => this.setState({
                loadingBattery: false,
                battery: vehicle,
                batteryReplacementInformations,
                url
            }))
            .catch(err => console.log(err))
        )
    };

    render() {
        const { batteryReplacementInformations, battery, url, loadingBattery, debug } = this.state;

        return (
            <div className="section battery" style={{ padding: '0 10px' }}>
                <h2>מצברים</h2>

                {battery &&  (<ul>
                        <ListItem feat="סוג הרכב" value={battery.name} title={battery.name} />
                        {battery.placeAndTimeInformation && battery.placeAndTimeInformation.placeInformation && <ListItem feat="start&stop" value={battery.placeAndTimeInformation.placeInformation.startStop} title={battery.name} />}

                    {batteryReplacementInformations.replacementPdf && batteryReplacementInformations.replacementPdf.length>0 && <div>
                        <a href={batteryReplacementInformations.replacementPdf[0].pdfUrl} rel="noopener noreferrer" target="_blank">הוראות החלפה</a>
                    </div>}
                    {batteryReplacementInformations.battery_location && batteryReplacementInformations.battery_location.length>0 && <div>
                        <a href={batteryReplacementInformations.battery_location[0]} rel="noopener noreferrer" target="_blank">מיקום המצבר</a>
                    </div>}
                    {batteryReplacementInformations.eobd_location && <div>
                        <a href={batteryReplacementInformations.eobd_location} rel="noopener noreferrer" target="_blank">מיקום שקע OBD</a>
                    </div>}
                    
                    
                    <div><a href={url} target="_blank" rel="noopener noreferrer"><img src="https://www.varta-automotive.com/packages/website_varta/themes/varta/favicon.ico" width="16" height="16" alt="varta" /> - מצברים</a></div>

                    </ul>)}

                    {!battery && (<Button type="primary" loading={loadingBattery} onClick={this.getBattery}>
                        הצג פרטים
                    </Button>)}

                {debug === 'true' && <pre>recalls: {JSON.stringify(battery, null, 2)}</pre>}
            </div>
        );
    }
};

import React, { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Tooltip from 'antd/lib/tooltip';
import {
    translateKeys
} from '../helpers';
import  { CopyOutlined, CheckOutlined } from '@ant-design/icons';


const ListItem = ({ copy, feat, value, checked, checkedValue, translate, extra, ltr, tooltip, ...rest }) => {
    const [copied, setCopied] = useState(false)
    if (!value && !extra && !checkedValue) return null;

    const key = translate ? translateKeys(feat) : feat;

    if (checked) {
        return (<li className={checkedValue === 1 ? "checked" : "missing"} {...rest}>{key}</li>);
    }

    return (<li {...rest}>
        <div className="row">
            <span className="cell" onClick={() => {
                if (copy && navigator.clipboard) {
                    setCopied(true)  
                    navigator.clipboard.writeText(typeof copy === 'string' && copy.length > 0 ? copy : value)
                    }
                }
            }>
                {copy && navigator.clipboard && <>{copied ? <CheckOutlined/> : <CopyOutlined/>}</>}
                {' '}                
                {key && <strong>{key}:</strong>}
                <span style={{ display: 'inline-flex', direction: ltr ? 'ltr' : 'rtl' }}>
                    {value || extra}
                </span>
            </span>
            {value && extra && <span className="cell">
                {/* {copy && navigator.clipboard && typeof copy === 'string' && <CopyOutlined onClick={() => {navigator.clipboard.writeText(copy)}}/>} */}
                {extra}
            </span>}

 
        </div>
        {tooltip && <Tooltip placement="topRight" title={tooltip} style={{ marginRight: 10 }}>
            <InfoCircleOutlined />
        </Tooltip>} 
    </li>);
};

export default ListItem;
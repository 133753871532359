const _ = require('lodash');

const tireSizeParser = (kidmi, ahori) => {
    let front = {};
    let back = {};

    const zmig_kidmi = kidmi && kidmi.replace(/\\/g, '/');
    const zmig_ahori = ahori && ahori.replace(/\\/g, '/');

    const splitkeys = ["/", "X", "*", ".", "=", "-"];

    if (zmig_kidmi && _.some(splitkeys, (key) => zmig_kidmi.indexOf(key) >= 0)) {
        let fTireWidth, fTireRatio, fTireDiameter;
        let spliter = '/';

        for (let i=0; i < splitkeys.length; i++) {
            if (zmig_kidmi.indexOf(splitkeys[i]) > 0) {
                spliter = splitkeys[i];
                break;
            }
        }

        if (zmig_kidmi.indexOf('-') > 0 && zmig_kidmi.indexOf('/') < 0 && zmig_kidmi.indexOf('X') < 0 && zmig_kidmi.indexOf('*') < 0 && zmig_kidmi.indexOf('.') < 0 && zmig_kidmi.indexOf('=') < 0) spliter = '-';
        const ftire = zmig_kidmi.split(spliter);

        if (ftire.length === 3) {
            fTireWidth = ftire[0].replace(/[a-zA-Z-]/g, '').trim();
            fTireRatio = ftire[1].replace(/[a-zA-Z-]/g, '').trim();
            fTireDiameter = ftire[2].replace(/[a-zA-Z-]/g, '').trim();

            if (ftire[1].indexOf('R') > 0) {
                fTireRatio = ftire[1].split('R')[0];
                fTireDiameter = ftire[1].split('R')[1] || ftire[2].replace(/[a-zA-Z-]/g, '').trim();
            }
        }
        else {
            let findDiameter = zmig_kidmi.indexOf('R');
            if (findDiameter < 0) {
                findDiameter = zmig_kidmi.indexOf('-');
            }
            const newDiameter = zmig_kidmi.substring(findDiameter, findDiameter+3);
            const newSize = zmig_kidmi.replace(newDiameter, '');

            for (let i=0; i < splitkeys.length; i++) {
                if (newSize.indexOf(splitkeys[i]) > 0) {
                    spliter = splitkeys[i];
                    break;
                }
            }
            const newSizeAfterSplit = newSize.split(spliter);
            fTireWidth = newSizeAfterSplit[0].replace(/[a-zA-Z-]/g, '').trim();
            fTireRatio = newSizeAfterSplit[1].replace(/[a-zA-Z-]/g, '').trim();
            fTireDiameter = newDiameter.toLowerCase().replace(/[a-zA-Z-]/g, '').trim();
        }

        front = {
            width: fTireWidth,
            ratio: fTireRatio,
            diameter: fTireDiameter
        };
    }

    if (zmig_ahori && _.some(splitkeys, (key) => zmig_ahori.indexOf(key) >= 0)) {
        let rTireWidth, rTireRatio, rTireDiameter;
        let spliter = '/';

        for (let i=0; i < splitkeys.length; i++) {
            if (zmig_ahori.indexOf(splitkeys[i]) > 0) spliter = splitkeys[i];
            break;
        }

        if (zmig_ahori.indexOf('-') > 0 && zmig_ahori.indexOf('/') < 0 && zmig_ahori.indexOf('X') < 0 && zmig_ahori.indexOf('*') < 0 && zmig_ahori.indexOf('.') < 0 && zmig_ahori.indexOf('=') < 0) spliter = '-';

        const rtire = zmig_ahori.split(spliter);
        if (rtire.length === 3) {
            rTireWidth = rtire[0].replace(/[a-zA-Z-]/g, '').trim();
            rTireRatio = rtire[1].replace(/[a-zA-Z-]/g, '').trim();
            rTireDiameter = rtire[2].replace(/[a-zA-Z-]/g, '').trim();

            if (rtire[1].indexOf('R') > 0) {
                rTireRatio = rtire[1].split('R')[0];
                rTireDiameter = rtire[1].split('R')[1] || rtire[2].replace(/[a-zA-Z-]/g, '').trim();
            }
        }
        else {
            let findDiameter = zmig_ahori.indexOf('R');
            if (findDiameter < 0) {
                findDiameter = zmig_ahori.indexOf('-');
            }
            const newDiameter = zmig_ahori.substring(findDiameter, findDiameter+3);
            const newSize = zmig_ahori.replace(newDiameter, '');

            for (let i=0; i < splitkeys.length; i++) {
                if (newSize.indexOf(splitkeys[i]) > 0) {
                    spliter = splitkeys[i];
                    break;
                }
            }
            const newSizeAfterSplit = newSize.split(spliter);
            rTireWidth = newSizeAfterSplit[0].replace(/[a-zA-Z-]/g, '').trim();
            rTireRatio = newSizeAfterSplit[1].replace(/[a-zA-Z-]/g, '').trim();
            rTireDiameter = newDiameter.toLowerCase().replace(/[a-zA-Z-]/g, '').trim();
        }

        back = {
            width: rTireWidth,
            ratio: rTireRatio,
            diameter: rTireDiameter
        };
    }

    return ({ front, back });
}

module.exports = {
    tireSizeParser
}
import React, { Component } from 'react';
import classnames from 'classnames';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import 'antd/dist/antd.css';
import { CarDetails, CarSearchInput } from './CarDetails';
import { CarCompareDetails, CarCompareSearchInput } from './CarCompare'
import CarTypes from './CarTypes';
import TypeSearchInput from './CarTypes/TypeSearchInput';
import CarType from './CarTypes/CarType';
import { Delek } from './Delek';
import ShakedGame from './ShakedGame';
import Questions from './Theory/Questions';
import Misc from './Misc/Misc';
import { Roads, Road } from './Roads';

import './App.scss';

const PAGE_META = {
  roads: { title: 'כבישי נהיגה בישראל', desc: 'כבישים יפים, מפותלים ומהנים לנהיגה' },
  delek: { title: 'תחנות דלק אוקטן 98', desc: 'רשימת תחנות דלק 98' },
  car: { title: 'איתור מידע על רכבים', desc: 'איתור מידע על רכבים לפי מספר רישוי' },
  misc: { title: 'מידע שימושי', desc: 'מידע שימושי בנושא רכבים וטיולים' },
  tires: { title: 'מידע שימושי - צמיגים ברכב', desc: 'טבלאות מידע ומחשבון המרת לחץ אוויר בצמיגים ברכב' },
  radio: { title: 'מידע שימושי - תדרי רדיו', desc: 'טבלת תדרי FRS אמריקאי ואירופאי' },
  theory: { title: 'לימוד לתאוריה', desc: 'מאגר שאלות של משרד הרישוי' },
  game: { title: 'המשחק של שקד', desc: 'מאגר שאלות של משרד הרישוי' }
};

class App extends Component {
  render() {
    return (
      <Router>
        <Route render={(props) => {
          const x = props.location.pathname.split('/').filter(Boolean);
          const lastParam = x[x.length - 1];
          let pageName = lastParam;

          document.title = (PAGE_META[pageName] && PAGE_META[pageName].title) || PAGE_META.car.title;
          document.querySelector('meta[name="description"]')
            .setAttribute('content', (PAGE_META[pageName] && PAGE_META[pageName].desc) || PAGE_META.car.desc);

          return (<section className={classnames(['app', ...x])}>
            <header>
              <nav>
                <ul>
                  <li><Link to="/">מידע על רכבים</Link></li>
                  <li><Link to="/type">מידע על סוגי רכבים</Link></li>
                  <li><Link to="/misc">מידע שימושי</Link></li>
                  {/* <li><Link to="/delek">תחנות דלק</Link></li>
                  <li><Link to="/roads">כבישים</Link></li>
                  <li><Link to="/theory">תאוריה</Link></li> */}
                  <li style={{ float: 'left', color: 'white' }}>v3</li>
                </ul>
              </nav>
            </header>
            <div className='content-wrapper'>
              <Route exact path='/' component={() => <><CarSearchInput /><div>Question</div></>} titl="sdffds" />
              <Route exact path='/compare' component={() => <><CarCompareSearchInput /></>} titl="sdffds" />
              <Route exact path='/compare/:cara/:carb' component={CarCompareDetails} titl="sdffds" />
              <Route path="/car" exact component={CarSearchInput} />
              <Route path="/car/:id" component={CarDetails} />
              <Route path="/type/:kinuy/:degem/:year/" component={CarType} /> 
              <Route path="/type" exact component={() => <><TypeSearchInput /><div>Question</div></>} />
              <Route path="/types/:kinuy" component={CarTypes} /> 
              <Route path="/delek/" component={Delek} />
              <Route path={['/theory/:start', '/theory']} component={Questions} />
              <Route exact path="/roads/:road" component={Road} />
              <Route exact path="/roads" component={Roads} />
              <Route exact path="/misc" component={Misc} />
              <Route exact path="/misc/:type" component={Misc} />
              <Route exact path="/shaked/game" component={ShakedGame} />
            </div>
          </section>
          )
        }} />
      </Router>
    );
  }
};

export default App;

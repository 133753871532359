import React, { Component } from 'react';
import Card from 'antd/lib/card';
import axios from 'axios';

export default class CarRecall extends Component {
    constructor() {
        super();
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');

        this.state = {
            debug,
            recalls: [],
            extraData: [],
            loadingRecall: true
        };
    }

    componentDidMount() {
        this.fetchData(this.props.carNumber);
    };

    componentDidUpdate(prevProps) {
        if (this.props.carNumber !== prevProps.carNumber) {
            this.setState({ loadingRecall: true, recalls: [] });
            this.fetchData(this.props.carNumber);
        }
    }

    fetchData = (carNumber) => axios.get(`/recalls/${carNumber}`)
    .then(({ data: { recalls, extraData } }) => this.setState({
        recalls,
        extraData,
        loadingRecall: false
    })).catch(() => {
        this.setState({ recalls: [] })
    }).finally(() => {
        this.setState({ loadingRecall: false })
    });
  
    render() {
        const { recalls, extraData, loadingRecall, debug } = this.state;


            //   "RECALL_ID": "14032",
            //   "TOZAR_CD": "011",
            //   "TOZAR_TEUR": "CHEVROLET",
            //   "DEGEM": "SONIC",
            //   "SHNAT_RECALL": "2021",
            //   "BUILD_BEGIN_A": "2012-01-01",
            //   "BUILD_END_A": "2017-12-31",
              //"SUG_RECALL": "תקלה סידרתית בטיחותית",
              //"SUG_TAKALA": "כריות אוויר",
              //"TEUR_TAKALA": "ייתכן וקיים פגם ביחידת ניפוח כרית האוויר הקדמית בצד הנהג.",
            //   "OFEN_TIKUN": "החלפה",
            //   "TKINA_EU": "M1",
            //   "YEVUAN_TEUR": "יוניברסל מוטורס ישראל בעמ",
            //   "TELEPHONE": "03-9534455",
            //   "WEBSITE": "WWW.CHEVROLET.CO.IL"


        return (
            <div style={{ margin: '10px 0' }}>
                {loadingRecall
                    ? (<div>בודק קריאות שירות....</div>)
                    : (<div>
                        {!!recalls.length && <h2>קריאות שירות</h2>}
                        {recalls.map((recall, idx) => (
                            <Card key={idx} title={recall.SUG_RECALL} extra={<a href={`//${extraData[0].WEBSITE}`} target="_blank" rel="noopener noreferrer">{extraData[0].YEVUAN_TEUR}</a>} style={{ marginBottom: 30 }}>
                                סוג תקלה: <b>{recall.SUG_TAKALA}</b><br />
                                {recall.TEUR_TAKALA}<br />
                                <div>אופן התיקון: {extraData[0].OFEN_TIKUN}</div>
                                <div>ת.פתיחה: {recall.TAARICH_PTICHA}</div>
                            </Card>
                        ))}
                    </div>)}

                {debug === 'true' && <pre>recalls: {JSON.stringify(recalls, null, 2)}</pre>}
            </div>
        );
    }
};

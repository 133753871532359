import React from 'react';
import Table from 'antd/lib/table';
// import Card from 'antd/lib/card';
// import Input from 'antd/lib/input';
// import Select from 'antd/lib/select';
// import Typography from 'antd/lib/typography';
import Space from 'antd/lib/space';
// import Collapse from 'antd/lib/collapse';
// import Icon, { CalendarOutlined } from '@ant-design/icons';

import {
    PHONES,
    defaultPhoneColumsEmpty
} from './helpers';

import './style.scss';

// const { Option } = Select;
// const { Title } = Typography;
// const { Panel } = Collapse;

function Phones() {
    return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <h1>מידע שימושי: טלפונים חשובים</h1>

            <Table bordered
                title={() => 'טלפונים חשובים'}
                size="small"
                pagination={false}
                expandable={{ defaultExpandAllRows: true }}
                dataSource={PHONES.all}
                columns={defaultPhoneColumsEmpty} />


            {/* <Title level={3}>מפקדות אזוריות מג"ב</Title>

            <Table bordered
                size="small"
                pagination={false}
                dataSource={PHONES.magav}
                columns={defaultPhoneColums} />


            <Title level={4}>טלפונים</Title>
            <Table bordered
                size="small"
                pagination={false}
                dataSource={PHONES.bla}
                columns={defaultPhoneColums} /> */}

        </Space>
    );
};

export default Phones;

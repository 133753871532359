import React, { Component } from 'react';
import _ from 'lodash';

import Overlay from "ol/Overlay.js";
import * as proj from 'ol/proj';
import GPX from 'ol/format/GPX';

import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as LayerTile, Vector as VectorLayer } from 'ol/layer';
import { OSM as SourceOSM, Vector as VectorSource } from 'ol/source';
import { Stroke, Style, Circle, Fill, Text, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

import { STATIONS, CAMERAS_KEY, directionTranslation, typeTranslation } from '../common/consts';
import cameras from '../cameras-a3.json';
import { ROADS } from './';

import './Roads.scss'

class RoadsMap extends Component {
    constructor(props) {
        super(props);

        // create feature layer and vector source
        const featuresLayer = new VectorLayer({
            source: new VectorSource()
        });

        this.state = {
            loading: true,
            center: proj.fromLonLat([34.6962941, 32.4269213], 'EPSG:3857'),
            zoom: 8,
            featuresLayer,
            showCameras: props.showCameras,
            showGasStations: props.showGasStations,
            loadingGas: false,
            openHours: '247',
            stations: {
                paz: [], sonol: [], doralon: [], delek: [], tapuz: []
            },
        };


        this.map = new Map({
            target: null,
            layers: [new LayerTile({
                source: new SourceOSM()
            })],
            view: new View({
                center: this.state.center,
                zoom: this.state.zoom
            })
        });
    }

    componentDidMount() {
        this.buildMap();
    }

    componentWillUnmount() {
        this.map.setTarget(null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showCameras !== this.props.showCameras) {
            //Perform some operation here
            this.setState({ showCameras: this.props.showCameras });
            this.buildMap();
        }

        if (prevState.showGasStations !== this.props.showGasStations) {
            //Perform some operation here
            this.setState({ showGasStations: this.props.showGasStations});

            if (this.props.showGasStations) {
                this.setState({ loadingGas: true });
                this.getAllFuelStations();
            } else {
                this.buildMap();
            }
        }
    }

    getAllFuelStations = () => {
        const { openHours } = this.state;
        Promise.all([
            fetch(`/api/delek/paz?openhours=${openHours}`),
            fetch(`/api/delek/doralon?openhours=${openHours}`),
            fetch(`/api/delek/delek?openhours=${openHours}`),
            fetch(`/api/delek/sonol?openhours=${openHours}`),
            fetch(`/api/delek/tapuz?openhours=${openHours}`)
        ])
            .then(async ([
                pazStations,
                doralonStations,
                delekStations,
                sonolStations,
                tapuzStations
            ]) => {
                const paz = await pazStations.json();
                const doralon = await doralonStations.json();
                const delek = await delekStations.json();
                const sonol = await sonolStations.json();
                const tapuz = await tapuzStations.json();
                return [
                    paz,
                    doralon,
                    delek,
                    sonol,
                    tapuz
                ]
            })
            .then((allStationsResult) => {
                this.setState({
                    loadingGas: false,
                    stations: {
                        paz: allStationsResult[0].stations,
                        doralon: allStationsResult[1].stations,
                        delek: allStationsResult[2].stations,
                        sonol: allStationsResult[3].stations,
                        tapuz: allStationsResult[4].stations,
                    }
                }, this.buildMap);
            })
            .catch((err) => console.log(err));
    }


    buildMap = () => {
        const {
            showCameras,
            showGasStations,
            stations: {
                doralon,
                delek,
                sonol,
                paz,
                tapuz
            }
        } = this.state;

        this.map.setTarget('map');
        let features = [];

        features = [
            ...doralon,
            ...delek,
            ...sonol,
            ...paz,
            ...tapuz
        ].map(station => {
            const feature = new Feature({
                geometry: new Point(proj.fromLonLat([station.location.longitude, station.location.latitude])),
                sug: 'gas',
                ...station
            });

            var style = new Style({
                image: new Icon({
                    src: STATIONS[station.type].img
                })
            });

            feature.setStyle(style);
            return feature;
        });

        // create feature layer and vector source
        const gasStationsLayer = new VectorLayer({
            name: 'gas',
            source: new VectorSource({ features })
        });

        if (!showGasStations) {
            this.map.getLayers().forEach((layer) => layer && layer.get('name') === 'gas' && this.map.removeLayer(layer))
        }
        else {
            this.map.addLayer(gasStationsLayer);
        }


        _.filter(ROADS, r => ['north', 'center', 'south'].includes(r.group) && r).map(i => {
            const source = new VectorSource({
                url: i.gpx,
                format: new GPX({ extractWaypoints: true })
            });
            const color = i.closed ? '#ff0000' : '#1b8cf6';
            const vectorLayer = new VectorLayer({
                source,
                style: new Style({
                    stroke: new Stroke({
                        color,
                        width: 4
                    })
                })
            });
            this.map.addLayer(vectorLayer);

            return i;
        });

        const overlay = new Overlay({
            element: document.getElementById('popup'),
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });
        this.map.addOverlay(overlay);

        features = cameras[CAMERAS_KEY].map(A3 => {
            const feature = new Feature({
                geometry: new Point(proj.fromLonLat([Number(A3.long), Number(A3.lat)])),
                sug: 'camera',
                ...A3
            });

            var style = new Style({
                image: new Circle({
                    radius: 7,
                    stroke: new Stroke({
                        color: '#fff'
                    }),
                    fill: new Fill({
                        color: (typeTranslation[A3.type] || '#000')
                    })
                }),
                text: new Text({
                    text: directionTranslation[A3.diraction],
                    fill: new Fill({
                        color: '#fff'
                    })
                })
            });
            feature.setStyle(style);
            return feature;
        });

        // create feature layer and vector source
        const camerasLayer = new VectorLayer({
            name: 'cameras',
            source: new VectorSource({ features })
        });

        if (!showCameras) {
            this.map.getLayers().forEach((layer) => layer && layer.get('name') === 'cameras' && this.map.removeLayer(layer))
        }
        else {
            this.map.addLayer(camerasLayer);
        }

        // bind click on vector
        this.map.on('click', (evt) => {
            const feature = this.map.getFeaturesAtPixel(evt.pixel);

            if (feature && feature[0].values_.sug === 'camera') {
                const cameraData = feature[0].values_;
                document.getElementById('popup-content').innerHTML = `
                    <b>מצלמת ${cameraData.type}</b>
                    <div>תחום: ${cameraData.area}</div>
                    <div>כיוון: ${cameraData.diraction}</div>
                `;
                overlay.setPosition(evt.coordinate);
            }
            else if (feature && feature[0].values_.sug === 'gas') {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(async (position) => {
                        const { location: { latitude, longitude }, street, shortName, type, isFuelingStation24_7Open } = feature[0].values_;
                        fetch(`/api/distance/${latitude}/${longitude}/${position.coords.latitude}/${position.coords.longitude}`)
                        .then(r => r.json())
                        .then(re => {
                            document.getElementById('popup-content').innerHTML = `
                            <div><b style='color: ${STATIONS[type].color};'>${STATIONS[type].title} - ${shortName}</b></div>
                            <p>${street}</p>
                            <p>מרחק: ${re.distance} ק"מ</p>
                            <p>${isFuelingStation24_7Open ? 'תחנה פתוחה 24/7' : ''}</p>
                            <a href='https://maps.google.com/maps?daddr=${latitude},${longitude}&amp;ll=' class='ant-btn ant-btn-primary ant-btn-sm btn'>נווט לתחנה</a>
                            `;
                            overlay.setPosition(evt.coordinate);
                        });
                    });
                }
            }
            else if (feature) {
                const roadData = _.filter(ROADS, { name: feature[0].values_.name })[0];
                document.getElementById('popup-content').innerHTML = `<p>טוען נתונים</p>`;

                fetch(`/api/gpx?road=${roadData.key}&lat=${this.state.latitude}&lon=${this.state.longitude}`)
                    .then(res => res.json())
                    .then(gpxData => {
                        document.getElementById('popup-content').innerHTML = `
                        <b class='${roadData.closed ? 'is-closed' : null}'>${gpxData.name || roadData.name} (${roadData.roads.join(', ')})</b>
                        <div>מרחק: ${gpxData.distance} ק"מ</div>
                        <div>אורך מסלול: ${+(Math.round(gpxData.routeLength + "e+2") + "e-2")} ק"מ</div>
                        <a href='/roads/${roadData.key}' class='ant-btn ant-btn-primary ant-btn-sm btn'>מידע נוסף</a>
                        <a href='https://maps.google.com/maps?daddr=${gpxData.start.lat},${gpxData.start.lon}&amp;ll=' class='ant-btn ant-btn-primary ant-btn-sm btn'>נווט למסלול</a>
                        `;
                        overlay.setPosition(evt.coordinate);
                    });
            }

        });

        document.getElementById("popup-closer").onclick = function () {
            overlay.setPosition(undefined);
            document.getElementById("popup-closer").blur();
            return false;
        };

        navigator.geolocation.getCurrentPosition((position) => {
            const center = proj.fromLonLat([position.coords.longitude, position.coords.latitude], 'EPSG:3857');
            // this.map.getView().setCenter(center);
            this.setState({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude
            });
            const marker = new Overlay({
                position: center,
                element: document.getElementById("location"),
                positioning: 'center-center',
                stopEvent: false
            });

            this.map.addOverlay(marker);
        });

    }

    render() {
        return (<div>
            {this.state.loadingGas && <React.Fragment>טוען תחנות דלק</React.Fragment>}
            <div id="map" style={{ width: "100%", height: "500px" }} />
            <div id="location" className="marker"><div className="pulse" /></div>
            <div id="popup" className="ol-popup">
                <span id="popup-closer" className="ol-popup-closer"></span>
                <div id="popup-content"></div>
            </div>
        </div>);
    }
};

export default RoadsMap;

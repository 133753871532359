import React, { Component } from 'react';
import Card from 'antd/lib/card';
import {
    convertToDate,
    checkSugTav
} from '../helpers';

import './TavNeche.scss';
import axios from 'axios';

const { Meta } = Card;

export default class TavNeche extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tav: {},
            loading: true,
            width: 40
        };
    }

    componentDidMount() {
        this.fetchData(this.props.carNumber);
    };

    componentDidUpdate(prevProps) {
        if (this.props.carNumber !== prevProps.carNumber) {
            this.fetchData(this.props.carNumber);
        }
    }

    fetchData = (carNumber) => axios.get(`/tavneche/${carNumber}`)
        .then(({ data: result }) => {            
            this.setState({
                tav: result.tav,
                loading: false
            })
        })
        .catch((e) => this.setState({ loading: false }));

    render() {
        const { tav, loading, width } = this.state;
        
        return (
            <React.Fragment>
                {loading
                    ? (<div />)
                    : (tav.date && (
                        <Card hoverable
                            style={{ width, overflow: 'hidden', position: 'absolute', top: 0, left: 0 }}
                            onMouseEnter={() => this.setState({ width: 205 })}
                            onMouseLeave={() => this.setState({ width: 40 })}
                            cover={<div className={`tavBox tavType${tav.sug}`} />}>
                            <Meta title={`זכאי ל${checkSugTav(tav.sug)}`} description={`מ${convertToDate(tav.date)}`} />
                        </Card>
                    ))}
            </React.Fragment>
        );
    }
};

import React from 'react';
import _ from 'lodash';

export const defaultPhoneColumsEmpty = [
    {
        title: '',
        dataIndex: 'name',
        key: 'name',
        align: 'right'
    },
    {
        title: '',
        dataIndex: 'phone',
        key: 'phone',
        align: 'right',
        render: (phone) => {
            if (_.isArray(phone)) {
                return phone.map((p) => <a className="phone" href={`tel:${p}`} key={p}>{p}</a>);
            }
            return <a href={`tel:${phone}`}>{phone}</a>;
        }
    }
];

export const PHONES = {
    all: [
        {
            name: 'asd',
            key: 'asd',
            children: [{
                name: 'asd1',
                key: 'asd1',
                children: [
                    { name: 'מוקד חדר מצב רשות הטבע והגנים	', phone: '02-6222211', key: '2' },
                    { name: 'מוקד טבע', phone: '02-6233221', key: '3' },
                    { name: 'מזג אוויר, התראות ביטחוניות וכו', phone: 'SMS: 4282', key: '4' },
                    { name: 'מוקד שריפות ארצי של קק"ל', phone: '1-800-250-250', key: '5' },
                    { name: 'חזאי תורן', phone: ['03-9682109', '03-3682110'], key: '6' },
                ]
            }]
        },
        {
            name: 'טלפונים 2',
            key: 'aa2',
            children: [{
                name: 'תחנות מידע',
                key: 'bb2',
                children: [
                    { name: 'מודיעין החברה להגנת הטבע', phone: '03-6388696', key: '1' },
                    { name: 'צומת גולני', phone: '04-6733846', key: '2' },
                    { name: 'צומת מחניים', phone: '04-6936945', key: '3' },
                    { name: 'צומת צמח', phone: '04-6752056', key: '4' },
                    { name: 'אורחן דוברת', phone: '04-6425060', key: '5' },
                    { name: 'אורחן כרמל', phone: '04-9591304', key: '6' },
                    { name: 'נחל מערות', phone: '04-9841750', key: '7' },
                    { name: 'גוש שגב', phone: '04-9800627', key: '8' },
                    { name: 'שמורת יהודיה', phone: '04-6962817', key: '9' },
                    { name: 'גליל מערבי', phone: '04-9852379', key: '10' },
                    { name: 'מצפה רמון - מרכז מבקרים', phone: '08-6588691', key: '11' },
                    { name: 'ים המלח - צומת אלמוג', phone: '02-5871818', key: '12' },
                    { name: 'קו ליער - מרכז מידע ל קק"ל', phone: '1-800-350-550', key: '13' },
                ]
            },{
                name: 'רט"ג - תחנות ופקחים',
                key: 'bb3',
                children: [
                    { name: 'מחוז הצפון', phone: '04-6522167', key: '46522167' },
                    { name: 'אזור חרמון - בן ענבר', phone: '057-7762287', key: '577762287' },
                    { name: 'אזור מרכז הגולן - שרון לוי', phone: '057-7762136', key: '577762136' },
                    { name: 'אזור יהודיה - גיא אלון', phone: '057-7762194', key: '577762194' },
                    { name: 'אזור דרום הגולן - דודו פילס', phone: '057-7762360', key: '577762360' },
                    { name: 'אזור גליל עליון מזרחי - אביעד בלסקי', phone: '057-7762030', key: '577762030' },
                    { name: 'אזור הר מירון - גיא כהן', phone: '057-7762036', key: '577762036' },
                    { name: '', phone: '057-7762144', key: '577762144' },
                    { name: '', phone: '057-7762034', key: '577762034' },
                    { name: '', phone: '057-7762027', key: '577762027' },
                    { name: '', phone: '057-7762088', key: '577762088' },
                    { name: '', phone: '057-7762127', key: '577762127' },
                    { name: '', phone: '057-7762300', key: '577762300' },
                    { name: '', phone: '057-7762080', key: '577762080' },
                    { name: '', phone: '057-7762174', key: '577762174' },
                    { name: '', phone: '03-9150400', key: '39150400' },
                    { name: '', phone: '057-5837994', key: '575837994' },
                    { name: '', phone: '057-7760067', key: '577760067' },
                    { name: '', phone: '057-7762055', key: '577762055' },
                    { name: '', phone: '057-7762289', key: '577762289' },
                    { name: '', phone: '057-7762057', key: '577762057' },
                    { name: '', phone: '02-6232936', key: '26232936' },
                    { name: '', phone: '057-7762238', key: '577762238' },
                    { name: '', phone: '057-7762052', key: '577762052' },
                    { name: '', phone: '057-7762053', key: '577762053' },
                    { name: '', phone: '057-7762237', key: '577762237' },
                    { name: '', phone: '057-7762056', key: '577762056' },
                    { name: '', phone: '08-6280404', key: '86280404' },
                    { name: '', phone: '057-7762072', key: '577762072' },
                    { name: '', phone: '057-7762071', key: '577762071' },
                    { name: '', phone: '057-7762169', key: '577762169' },
                    { name: '', phone: '057-7763242', key: '577763242' },
                    { name: '', phone: '057-7762078', key: '577762078' },
                    { name: '', phone: '057-7762177', key: '577762177' },
                    { name: '', phone: '057-7762064', key: '577762064' },
                    { name: '', phone: '057-7762068', key: '577762068' },
                    { name: '', phone: '057-7762074', key: '577762074' },
                    { name: '', phone: '057-7762069', key: '577762069' },
                    { name: '', phone: '057-7762076', key: '577762076' },
                    { name: '', phone: '057-7762067', key: '577762067' },
                    { name: '', phone: '057-7762341', key: '577762341' },
                    { name: '', phone: '08-6373988', key: '86373988' },
                    { name: '', phone: '057-7762333', key: '577762333' },
                    { name: '', phone: '057-7762301', key: '577762301' },
                ]
            }]
        },
        {
            name: 'צהל',
            key: 'zahal',
            children: [{
                name: 'צפון (04)',
                key: 'north',
                children: [
                    { name: 'מתא"מ (תיאום טיולים)', phone: '04-6979007', key: '1' },
                    { name: 'מבצעים פיקוד צפון', phone: '04-6979253 | 04-6979169', key: '2' },
                    { name: 'פיקוד עורף צפון חמ"ל', phone: '04-6512203 | 04-6512353/4', key: '3' },
                    { name: 'חמ"ל אוגדת הגולן', phone: '04-6977221 | 04-6477221', key: '4' },
                    { name: 'חמ"ל אג"ם בירנית', phone: '04-6925239 | 04-6928495', key: '5' },
                    { name: 'מבצעים חטיבת חרמון', phone: '04-6966336 | 04-6966203', key: '6' },
                    { name: 'מבצעים חטיבת גיבור', phone: '04-6906201 | 04-6906311', key: '7' },
                    { name: 'חמ"ל חטיבת גיבור', phone: '04-6906212', key: '8' },
                    { name: 'מבצעים אליקים', phone: '04-8692580', key: '9' },
                    { name: 'מחוז חיפה', phone: '04-8692550', key: '10' }
                ]
            },
            {
                name: 'מרכז',
                key: 'center',
                children: [
                    { name: 'מתא"מ מרכז (תיאום טיולים)', phone: '02-5305511 | 02-5305042', key: '1' },
                    { name: 'מבצעים פיקוד מרכז קמב"ץ', phone: '02-5305528 | 02-5305415 | 02-5305882', key: '2' },
                    { name: 'מבצעים מפקדת איו"ש', phone: '02-9970248', key: '3' },
                    { name: 'מבצעים חטיבת הבקעה צמח', phone: '04-9940204', key: '4' },
                    { name: 'מבצעים בנימין', phone: '02-9970258 | 02-9970461', key: '5' },
                    { name: 'מבצעים קדומים', phone: '09-7922399 | 02-7922011', key: '6' },
                    { name: 'בא"פ לכיש', phone: '08-6813303', key: '7' },
                    { name: 'יס"פ ח"א', phone: '03-9432566', key: '8' }
                ]
            },
            {
                name: 'דרום',
                key: 'south',
                children: [
                    { name: 'פיקוד דרום מבצעים', phone: '08-9902925', key: '2' },
                    { name: 'מפקדת עין יהב', phone: '08-6581171 | 08-6593211', key: '3' },
                    { name: 'ד"בה 1', phone: '08-6502226', key: '4' },
                    { name: 'מפקדת קישור אילת', phone: '08-6302328', key: '5' },
                    { name: 'מפקדת קציעות', phone: '08-6506960 | 08-6506216', key: '6' },
                ]
            }]
        },
        {
            name: 'מג"ב',
            key: 'magav',
            children: [
                {
                    name: 'גליל גולן ועמקים',
                    key: 'magav-galilgolan',
                    children: [
                        { name: 'אזור גליל עליון וגולן - מפקדה: מצודת כח', phone: '04-6997363', key: '1' },
                        { name: 'אזור גליל תחתון ועמקים - מפקדה: בית נטופה', phone: '04-6478444', key: '2' },
                        { name: 'אזור תענ"ך וגלבוע - מפקדה: גן-נר', phone: '04-6428222', key: '3' },
                        { name: 'אזור ואדי ערה ורמות מנשה - מפקדה: ברקאי', phone: '04-6124444', key: '4' },
                    ]
                },
                {
                    name: 'שפלה',
                    key: 'magav-shfela',
                    children: [
                        { name: 'מפקדה בית שמש', phone: '02-9902210', key: '1' },
                    ]
                }
            ]
        }
    ],
};

export const DEFAULT_TABLE_HEIGHT = 240;

/***

משטרה, רפואה ויחידות חילוץ אזוריות
משטרה: 100 | מגן דוד אדום: 101 | יחידות חילוץ אזוריות: 100

מכבי אש: 102 | חברת החשמל: 103



אזור קרית שמונה: משטרה: 04-6943445 | מד"א: 04-6944334

אזור צפת: משטרה: 04-6978444 | מד"א: 04-6792628 | בי"ח: 04-6828811

אזור טבריה: משטרה: 04-6728444 | מד"א: 04-6790111 | בי"ח: 04-6738265

אזור נהריה: משטרה: 04-9518444 | מד"א: 04-6790111 | בי"ח: 04-9850505

אזור עכו: משטרה: 04-9876808 | מד"א: 04-6790111

אזור כרמיאל: משטרה: 04-9901444 | מד"א: 04-6790111

אזור חיפה: משטרה: 04-8648444 | מד"א: 04-8512233 | בי"ח: 04-8543111

אזור יוקנעם: משטרה: 04-9893044 | מד"א: 04-9593793

אזור טבעון: משטרה: 04-9831444 | מד"א: 04-9834211

אזור מעלות: משטרה: 04-9578444 | מד"א: 04-9912333

אזור קצרין: משטרה: 04-6968222 | מד"א: 04-6792628

אזור עפולה: משטרה: 04-6524444 | מד"א: 04-6421333 | בי"ח: 04-6494357

אזור חדרה: משטרה: 04-6327444 | בי"ח: 04-6304271

קב"ט מפעלי ים המלח: 08-9977010 | 08-9977577 | 08-9977700

קב"ט מפעלי פוספטים אורון: 08-6565611 | 052-3933712

קק"ל מוקד שריפות ארצי: 1-800-250-250 (24 שעות ביממה)

חזאי תורן: 03-5600600 או 039605555 (בימים שני ורביעי בין השעות 10:00-12:00)

זק"א: 1220 (מכל טלפון)

בתי ספר שדה
צפון:

חרמון (לינה + הדרכה): 04-6941091 | הדרכה: 04-6965030/1

גולן (לינה + הדרכה): 04-6965030/1

הר מירון (לינה + הדרכה): 04-6980022 | הדרכה: 04-6620432

אלון תבור (לינה + הדרכה): 04-6766250 | הדרכה: 04-6620432

אכזיב (לינה + הדרכה): 04-9522159 | הדרכה: 04-6620432

חוף כרמל - מעגן מיכאל (הדרכה): 04-6438319

מרכז:

כפר עציון: 02-9935133

הר גילה: 02-9932386

דרום:

הר הנגב - מצפה רמון (לינה + הדרכה): 08-6588615

שקמים - ניצנים (הדרכה): 08-6728755 או 08-6727895

 עין גדי (לינה + הדרכה): 08-6584288

חצבה (לינה + הדרכה): 08-6581576 | תורן: 052-4843781 | הדרכה: 08-6581546

אילת (לינה + הדרכה): 08-6372021 | מרכז הדרכה: 08-6326468

שדה בוקר: 08-6532016

רשות הטבע והגנים
מוקד הסביבה: 6911* (24 שעות ביממה) – להתראה בנושאי פגיעה בגנים, בשמורות ובערכי טבע (ציד, קטיפה, סחר וכו').

רשות הטבע והגנים: 3639* (בשעות העבודה)

חברות אוטובוסים
דן: 3456* או 03-6394444 ● אגד:  2800* או 03-6948888 ● סופרבוס: 1-700-700181 או 08-9205005 ● קווים: 8787* או 072-2588787 או 052-9998787 ● אפיקים: 6686* ● נתיבי אקספרס: 3553* או 1-599-559-559 ● עילית: 02-5807777 ● מטרופולין: 5900* ● גולן: 3254* או 04-6964025 ● מטרודן: 5100* או 1222-5100 ● רכבת ישראל: 5770* או 08-6831222 ● ויאוליה (לשעבר קונקס): 6686*




טלפונים חשובים ותדרים למכשירי קשר:
טלפונים חשובים לשימוש בטיולים בארץ

פיקוד דרום

מבצ' פיקוד דרום	08-9902283
מתא"ם דרום	08-9902294
מפקדת עין יהב	08-6581171 , 08-6593211
בה"ד 1	08-6502226
מפקדת קישור אילת	08-6302328
מפקדת קציעות	08-6506960 , 08-6506216
חמ"ל הר שגיא	08-6352719

פיקוד מרכז

מב"צ פיקוד מרכז	02-5305528 , 02-5305415
מתא"ם מרכז	02-5305511 , 02-5305042
קמב"ץ	02-5305882
מבצ' מפקדת איו"ש	02-9970248
מבצעים חטיבת הבקעה	02-9940204
מבצעים חטיבת צמח	04-6774204
מבצעים חטיבת בנימין	02-9970258 , 02-9970461
מבצעים חטיבת קדומים	09-7922399 , 09-7922011
בא"פ לכיש	08-6813303
יספ ח"א	03-9432566
פיקוד צפון

מבצ' פיקוד צפון	04-6799253 , 04-6799169
מתא"ם צפון	04- 6979007
חמ"'ל אוגדת הגולן	04-6977221 , 04-6477221
חמ"'ל חטיבה בירנית	04-6925239 , 04-6928495
מבצעים חטיבת חרמון	04-6966336 , 04-6966203
מבצעים חטיבת גיבור	04-6906201 , 04-69.6311
מבצעים אליקים	04-8692580
בתי חולים
אילת	יוספטל	08-6358011
אשקלון	ברזילי	08-6745777
באר שבע	סורוקה	08-6400111
בני ברק	מעייני הישועה	03-5771111
חדרה	הלל יפה	04-6304304
חולון	וולפסון	03-5028211
חיפה	בני ציון	04-8359359
כרמל	04-8250211
רמב"ם	04-8543111
טבריה	פוריה	04-6738211
ירושלים	אלין	02-6494222
ביקור חולים	02-6464111
הדסה הר הצופים	02-5844111
הדסה עין כרם	02-6777111
שערי צדק	02-6555111
כפר סבא	מאיר	09-7472555
נהריה	נהריה	04-9107107
נצרת	איטלקי	04-6508900
אנגלי-סקוטי	04-6028888
צרפתי	04-6509000
נתניה	לניאדו	09-8604666
עפולה	העמק	04-6494000
פתח תקווה	בילינסון	03-9377377
השרון	03-9372372
שניידר	03-9253253
צפת	רבקה זיו	04-6828811
צריפין	אסף הרופא	08-9779999
רחובות	קפלן	08-9441211
תל אביב	איכילוב	03-6974444
אסותא	03-5201515
סוראסקי	03-6974444
תל השומר	שיבא	03-5303030


*/
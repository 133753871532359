import axios from 'axios';
import React, { Component } from 'react';
//import Card from 'antd/lib/card';

export default class Hands extends Component {
    constructor() {
        super();
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');

        this.state = {
            debug,
            hands: [],
            loadingHands: true
        };
    }

    componentDidMount() {
        const { carNumber } = this.props;

        axios.get(`/hands/${carNumber}`)
            .then(({ data }) => this.setState({
                loadingHands: false,
                hands: data.hands
            })).catch(() => this.setState({ loadingHands: false }));
    };

    render() {
        const { hands, loadingHands, debug } = this.state;
        if ((hands || []).length === 0) { return null; }

        const ny = new Date().getFullYear();
        const nm = new Date().getMonth() + 1;

        const reduced = (hands || []).reduce((acc, cur, idx) => {
            const nextM = parseInt(idx === hands.length - 1 ? nm : `${hands[idx + 1].baalut_dt}`.slice(4), 10);
            const nextY = parseInt(idx === hands.length - 1 ? ny : `${hands[idx + 1].baalut_dt}`.slice(0, 4), 10);
            const curM = parseInt(`${cur.baalut_dt}`.slice(4), 10);
            const curY = parseInt(`${cur.baalut_dt}`.slice(0, 4), 10);

            let months;
            months = (nextY - curY) * 12;
            months -= curM;
            months += nextM;

            acc.push({
                baalut: cur.baalut,
                m: curM,
                y: curY,
                mDiff: months % 12,
                yDiff: Math.floor(months / 12),
            });
            return acc;

        }, []);


        return (
            <div className="section hands" style={{ display: 'flex', flexDirection: 'column', padding: '0 10px' }}>
                {this.props.title && <h2>{this.props.title}</h2>}
                {loadingHands
                    ? (<div>בודק בעלויות....</div>)
                    : (<div>
                        {reduced.map(({ baalut, m, y, mDiff, yDiff }, idx) => {
                            return (<React.Fragment key={`${baalut}_${m}_${y}`}>
                                <div>
                                    {m}/{y} - {baalut}
                                    <div>
                                        <nobr>{yDiff > 0 ? `${yDiff} שנים ו-` : null} {mDiff} חודשים</nobr>
                                    </div>
                                </div>
                                <hr />
                            </React.Fragment>)
                        })}
                    </div>)}

                {debug === 'true' && <pre>hands: {JSON.stringify(hands, null, 2)}</pre>}
            </div>
        );
    }
};

import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { translateTozar } from '../helpers';
import Stars from '../CarDetails/Stars';
// import '../CarDetails/CrashTest.scss';
import ListCompareItem from './ListCompareItem';

export default class CrashTest extends Component {
    constructor() {
        super();
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');

        this.state = {
            debug,
            ncapA: {},
            ncapB: {},
            ncapLoading: true
        };
    }

    // resolver keys must be in lower case!!
    nameResolver = (name = '', _tozar, merkav) => {
        const MODEL = name.toUpperCase();
        const tozar = translateTozar(_tozar);

        switch (tozar) {
            case 'MERCEDES-BENZ':
                let MERKAV = '';
                if (merkav === 'קבריולט') { MERKAV = 'CABRIOLET'; }
                if (merkav === 'קופה') { MERKAV = 'COUPE'; }

                // GT CLASS
                const firstLetters = MODEL.replace(/[0-9 ].*/, '');
                const exeptions = ['AMGGLA454MATI', 'S560E'];
                const exeption = MODEL.replace(/[ ]/g, '');
                const translator = {
                    'AMGGLA454MATI': 'AMG GLA45',
                    'S560E': 'S560E',
                    'GLC350E': 'GLC350E',
                    'GLC350E4MATIC': 'GLC350E',
                    'GLC350E4COUPE': 'GLC350E',
                };
                if (exeptions.includes(exeption)) return translator[exeption];

                return `${firstLetters}-CLASS ${MERKAV}`.trim();
            case 'LEXUS':
                return MODEL.replace('LEXUS', '').trim();
            default:
                return ({
                    'NEW BEETLE': 'beetle',
                    'M135I': '135I'
                })[MODEL] || MODEL;
        }
    }

    componentDidMount() {
        const {
            shnat_yitzurA, tozarA, degemA, merkavA,
            shnat_yitzurB, tozarB, degemB, merkavB
        } = this.props;

        axios.all([
            axios.get(`/SafetyRatings/${shnat_yitzurA}/${translateTozar(tozarA)}/${this.nameResolver(degemA, tozarA, merkavA)}`),
            axios.get(`/SafetyRatings/${shnat_yitzurB}/${translateTozar(tozarB)}/${this.nameResolver(degemB, tozarB, merkavB)}`),
        ]).then(([a, b]) => {
            this.setState({
                ncapA: a.data.ncap,
                ncapB: b.data.ncap,
                ncapLoading: false,
            })
        }).catch(error => { })


        // fetch(`/SafetyRatings/${shnat_yitzurA}/${translateTozar(tozarA)}/${this.nameResolver(degemA)}`)
        //     .then(res => res.json())
        //     .then(result => this.setState({ ncap: result.ncap, ncapLoading: false }));
    };

    render() {
        const { ncapA, ncapB, ncapLoading } = this.state;

        return (
            <>
                {ncapLoading && <div>טוען מבחני ריסוק</div>}

                {(!_.isEmpty(ncapA) || !_.isEmpty(ncapB)) && (
                    <>
                        <tr className='compare-title'><td colSpan={3} style={{ paddingRight: 10 }}><h2>מבחני ריסוק</h2></td></tr>

                        <ListCompareItem feat='ציון כללי'
                            winnerA={ncapA.OverallRating > ncapB.OverallRating}
                            winnerB={ncapB.OverallRating > ncapA.OverallRating}
                            valueA={<>{ncapA.OverallRating} <Stars count={ncapA.OverallRating} /></>}
                            valueB={<>{ncapB.OverallRating} <Stars count={ncapB.OverallRating} /></>}
                        />

                        <tr className={`compare-row compare-subtitle`}><td colSpan={3} style={{ paddingRight: 10 }}><h3>התנגשות קדמית</h3></td></tr>
                        <ListCompareItem feat='הגנה על הנהג'
                            winnerA={ncapA.FrontCrashDriversideRating > ncapB.FrontCrashDriversideRating}
                            winnerB={ncapB.FrontCrashDriversideRating > ncapA.FrontCrashDriversideRating}
                            valueA={<>{ncapA.FrontCrashDriversideRating} <Stars count={ncapA.FrontCrashDriversideRating} /></>}
                            valueB={<>{ncapB.FrontCrashDriversideRating} <Stars count={ncapB.FrontCrashDriversideRating} /></>}
                        />
                        <ListCompareItem feat='הגנה על הנוסע'
                            winnerA={ncapA.FrontCrashPassengersideRating > ncapB.FrontCrashPassengersideRating}
                            winnerB={ncapB.FrontCrashPassengersideRating > ncapA.FrontCrashPassengersideRating}
                            valueA={<>{ncapA.FrontCrashPassengersideRating} <Stars count={ncapA.FrontCrashPassengersideRating} /></>}
                            valueB={<>{ncapB.FrontCrashPassengersideRating} <Stars count={ncapB.FrontCrashPassengersideRating} /></>}
                        />
                        <ListCompareItem feat='ניקוד כללי'
                            winnerA={ncapA.OverallFrontCrashRating > ncapB.OverallFrontCrashRating}
                            winnerB={ncapB.OverallFrontCrashRating > ncapA.OverallFrontCrashRating}
                            valueA={<>{ncapA.OverallFrontCrashRating} <Stars count={ncapA.OverallFrontCrashRating} /></>}
                            valueB={<>{ncapB.OverallFrontCrashRating} <Stars count={ncapB.OverallFrontCrashRating} /></>}
                        />

                        <tr className={`compare-row compare-subtitle`}><td colSpan={3} style={{ paddingRight: 10 }}><h3>התנגשות מהצד</h3></td></tr>
                        <ListCompareItem feat='הגנה על צד הנהג'
                            winnerA={ncapA.SideCrashDriversideRating > ncapB.SideCrashDriversideRating}
                            winnerB={ncapB.SideCrashDriversideRating > ncapA.SideCrashDriversideRating}
                            valueA={<>{ncapA.SideCrashDriversideRating} <Stars count={ncapA.SideCrashDriversideRating} /></>}
                            valueB={<>{ncapB.SideCrashDriversideRating} <Stars count={ncapB.SideCrashDriversideRating} /></>}
                        />

                        <ListCompareItem feat='הגנה על צד הנוסע'
                            winnerA={ncapA.SideCrashPassengersideRating > ncapB.SideCrashPassengersideRating}
                            winnerB={ncapB.SideCrashPassengersideRating > ncapA.SideCrashPassengersideRating}
                            valueA={<>{ncapA.SideCrashPassengersideRating} <Stars count={ncapA.SideCrashPassengersideRating} /></>}
                            valueB={<>{ncapB.SideCrashPassengersideRating} <Stars count={ncapB.SideCrashPassengersideRating} /></>}
                        />

                        <ListCompareItem feat='ניקוד כללי'
                            winnerA={ncapA.OverallSideCrashRating > ncapB.OverallSideCrashRating}
                            winnerB={ncapB.OverallSideCrashRating > ncapA.OverallSideCrashRating}
                            valueA={<>{ncapA.OverallSideCrashRating} <Stars count={ncapA.OverallSideCrashRating} /></>}
                            valueB={<>{ncapB.OverallSideCrashRating} <Stars count={ncapB.OverallSideCrashRating} /></>}
                        />

                        <tr className={`compare-row compare-subtitle`}><td colSpan={3} style={{ paddingRight: 10 }}><h3>התנגשות בעמוד</h3></td></tr>
                        <ListCompareItem feat='ניקוד כללי'
                            winnerA={ncapA.SidePoleCrashRating > ncapB.SidePoleCrashRating}
                            winnerB={ncapB.SidePoleCrashRating > ncapA.SidePoleCrashRating}
                            valueA={<>{ncapA.SidePoleCrashRating} <Stars count={ncapA.SidePoleCrashRating} /></>}
                            valueB={<>{ncapB.SidePoleCrashRating} <Stars count={ncapB.SidePoleCrashRating} /></>}
                        />
                        <tr className={`compare-row compare-subtitle`}><td colSpan={3} style={{ paddingRight: 10 }}><h3>התהפכות</h3></td></tr>
                        <ListCompareItem feat='ניקוד כללי'
                            winnerA={ncapA.RolloverRating > ncapB.RolloverRating}
                            winnerB={ncapB.RolloverRating > ncapA.RolloverRating}
                            valueA={<>{ncapA.RolloverRating} <Stars count={ncapA.RolloverRating} /></>}
                            valueB={<>{ncapB.RolloverRating} <Stars count={ncapB.RolloverRating} /></>}
                        />
                        <ListCompareItem feat='הסתברות'
                            winnerA={ncapA.RolloverPossibility > ncapB.RolloverPossibility}
                            winnerB={ncapB.RolloverPossibility > ncapA.RolloverPossibility}
                            valueA={ncapA.RolloverPossibility ? `${Math.round(ncapA.RolloverPossibility * 100)}%` : ''}
                            valueB={ncapB.RolloverPossibility ? `${Math.round(ncapB.RolloverPossibility * 100)}%` : ''}
                        />
                    </>
                )}
            </>
        );
    }
};

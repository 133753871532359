import React, { Component } from 'react';
import TypeSearchInput from './TypeSearchInput'
import { Betihut,  CarPrice, CrashTest, ListItem, TotalOfDegem, Zihum } from '../CarDetails';
import _ from 'lodash';
import { getTooltipByMerkav, pad, shovyAgra, translateSugDegem } from '../helpers';
import Flag from '../CarDetails/Flag';
import axios from 'axios';

export default class CarType extends Component {
    constructor(props) {
        super(props);
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');
        // kinuy/:degem/:year
        const kinuy = props.match.params.kinuy;
        const degem = props.match.params.degem;
        const year = props.match.params.year;


        this.state = {
            debug,
            kinuy,
            degem,
            year,
            data: [],
            loadingData: true
        };
    }

    componentDidMount() {
        const { kinuy, degem, year } = this.state;
        this.fetchData(kinuy, degem, year);
    };

    // componentDidUpdate(prevProps) {
    //     if (this.props.kinuy !== prevProps.kinuy) {
    //         this.setState({ loadingCars: true, cars: [] });
    //         this.fetchData(this.state.kinuy);
    //     }
    // }

    fetchData = (kinuy, degem, year) => axios.get(`/degem/${degem}/${year}`)
        .then(({ data: {data} }) => this.setState({
            data,
            loadingData: false
        })).catch(() => {
            this.setState({ data: [] })
        }).finally(() => {
            this.setState({ loadingData: false })
        });

    render() {
        const { kinuy, data: carData, loadingData, debug } = this.state;

        // "kinuy_mishari": "IBIZA",
        // "tozeret_nm": "סיאט ספרד",
        // "degem_nm": "6L12G4",
        // "degem_cd": 870,
        // "shnat_yitzur": 2004

        const kod = `${pad(carData.tozeret_cd, 4)}-${pad(carData.degem_cd, 4)}`;
 

        return (<>
            <TypeSearchInput ref={this.input} kinuy={kinuy} />

            <div className="carDetails">
                <div>
                    {loadingData &&
                        <div>טוען נתונים לרכב...</div>}

                    {(carData && (_.isEmpty(carData)) && !loadingData &&
                        <h3>מצטערים, לא חזר מידע ממשרד התחבורה עבור {kinuy}.</h3>)}


                    {!_.isEmpty(carData) && !loadingData && (
                        <div style={{ display: 'flex', flexFlow: 'wrap' }}>

                            {/* {typeof navigator.share === 'function' && <ShareAltOutlined style={{ position: 'absolute', top: 10 }} className="share-btn" onClick={() => {
                                    navigator.share({
                                        title: `מידע על רכב מספר ${parseCarNumber(carNumber)}`,
                                        text: parseCarNumber(carNumber),
                                        url: window.location.href,
                                    })
                                }} />} */}

                            <div className='section' style={{ padding: '0 10px', width: '100%' }}>
                                <h2 style={{ marginTop: 0, paddingRight: 10 }}>נתוני דגם</h2>




                                <ul>
                                    <ListItem feat='סוג' value={translateSugDegem(carData.sug_degem)} />

                                    {carData.tozar && <ListItem feat={null} value={`תוצרת ${carData.tozar}`} extra={
                                        <>
                                            (<Flag tozeret_eretz_nm={carData.tozeret_eretz_nm} />
                                            {' '}
                                            {carData.tozeret_eretz_nm})
                                        </>
                                    } />}
                                    <ListItem copy={`${carData.kinuy_mishari} ${carData.degem_nm} `} feat='דגם' value={carData.kinuy_mishari} extra={<a href={`https://www.google.com/search?q=${carData.degem_nm}+${carData.kinuy_mishari}`} target="_blank" rel="noopener noreferrer">({carData.degem_nm})</a>} />
                                    <ListItem tooltip={getTooltipByMerkav(carData.merkav)} feat='מרכב' value={carData.merkav} />
                                    <ListItem copy feat='רמת גימור' value={carData.ramat_gimur} />
                                    <ListItem feat='שנת יצור' value={carData.shnat_yitzur} />
                                    <ListItem feat='קבוצת רישוי' value={carData.kvuzat_agra_cd} />
                                    <ListItem tooltip="עדכון: יולי 2022 ואינו כולל 9 שקלים עבור מדבקת הטסט" feat='אגרת רישוי' value={`₪${shovyAgra(carData.mishkal_kolel, carData.kvuzat_agra_cd, carData.shnat_yitzur)}`} />
                                    <ListItem copy feat='קוד משרד התחבורה' value={kod} />
                                </ul>









                                <TotalOfDegem degem={carData.degem_nm} />
                            </div>

                            <div className='section' style={{ padding: '0 10px' }}>
                                <h2>אביזרים ומערכות</h2>
                                <ul>
                                    <ListItem feat='טכנולוגיית הנעה' value={carData.technologiat_hanaa_nm} />
                                    {(Number(carData.automatic_ind) === 0 || Number(carData.automatic_ind) === 1) && <ListItem feat='תיבת הילוכים' value={parseInt(carData.automatic_ind) === 1 ? 'אוטומטית' : 'ידנית'} />}
                                    {['bakarat_yatzivut_ind', 'abs_ind', 'hege_koah_ind', 'halon_bagg_ind', 'mazgan_ind', 'argaz_ind', 'galgaley_sagsoget_kala_ind']
                                        .map(k =>
                                            <ListItem key={k} feat={k} value={carData[k]} translate checked checkedValue={parseInt(carData[k], 10)} />)}
                                </ul>
                            </div>

                            <div className='section' style={{ padding: '0 10px' }}>
                                <h2>מפרט טכני</h2>
                                <ul>
                                    <ListItem copy={carData.degem_manoa} value={null} feat='דגם מנוע' extra={<a href={`https://www.google.com/search?q=${carData.degem_manoa}+engine`} target="_blank" rel="noopener noreferrer">{carData.degem_manoa}</a>} />
                                    {['nefah_manoa', 'koah_sus', 'mishkal_kolel', 'gova', 'delek_nm', 'hanaa_nm', 'kosher_grira_im_blamim', 'kosher_grira_bli_blamim', 'mispar_moshavim', 'mispar_dlatot', 'mispar_halonot_hashmal', 'mispar_kariot_avir']
                                        .map(k =>
                                            <ListItem key={k} feat={k} value={carData[k]} translate />)}
                                    {carData.sug_mamir_cd !== 0 &&
                                        <ListItem feat='סוג ממיר' value={carData.sug_mamir_nm} />}
                                </ul>
                            </div>

                            <Betihut carData={carData} />

                            <Zihum carData={carData} />
                        </div>
                    )}

                    {/* <CarRecall carNumber={carNumber} /> */}

                    {carData.tozeret_cd && carData.degem_cd &&
                        <CarPrice tozeret={carData.tozeret_cd} degem={carData.degem_cd} shnat_yitzur={carData.shnat_yitzur} />}




                </div>
            </div>

            {carData.shnat_yitzur && carData.tozar && carData.kinuy_mishari &&
                <CrashTest shnat_yitzur={carData.shnat_yitzur}
                    tozar={carData.tozar}
                    degem={carData.kinuy_mishari}
                    merkav={carData.merkav} />}

            <footer>ט.ל.ח - המידע המוצג הינו מובא ישירות מאתר משרד התחבורה</footer>







            <div style={{ margin: '10px 0' }}>
                {debug === 'true' && <pre>data: {JSON.stringify(carData, null, 2)}</pre>}
            </div>
        </>
        );
    }
};

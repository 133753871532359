import React, { useState, useRef } from 'react';
import { Link, Redirect, useHistory } from "react-router-dom";
import Button from 'antd/lib/button';
import {
    HISTORY_KEY
} from '../common/consts';
import './CarSearchInput.scss';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const CarSearchInput = React.forwardRef((props, ref) => {
    const [toCar, setCar] = useState(null);
    const [carB, setCarB] = useState(null);
    // const [showCamera, openCamera] = useState(false);
    const inputEl = ref ? ref : useRef(null);

    // const cam = useRef();

    // const startDictation = () => {
    //   if (window.hasOwnProperty('webkitSpeechRecognition')) {
    //     const recognition = new webkitSpeechRecognition(); // eslint-disable-line

    //     recognition.continuous = false;
    //     recognition.interimResults = false;

    //     recognition.lang = "en-US";
    //     recognition.start();

    //     recognition.onresult = function(e) {
    //       console.log(e.results[0][0].transcript)
    //       setCar(e.results[0][0].transcript)
    //       // inputEl.current.value = e.results[0][0].transcript;
    //       recognition.stop();
    //       // document.getElementById('labnol').submit();
    //     };

    //     recognition.onerror = function(e) {
    //       recognition.stop();
    //     }
    //   }
    // }
    const navHistory = useHistory();


    const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');

    // const takePicture = () => {
    //   cam.current.capture()
    //   .then(blob => {
    //     // this.img.src = URL.createObjectURL(blob);
    //     // this.img.onload = () => { URL.revokeObjectURL(this.src); }
    //     console.log(URL.createObjectURL(blob));
    //     openCamera(false);
    //     URL.revokeObjectURL(URL.createObjectURL(blob));
    //   })
    // }

    return (<React.Fragment>
        {toCar && <Redirect to={`/car/${toCar}`} />}
        <h1>הכנס מספר רכב לפרטים</h1>
        <div className="searchInput">
            <input type="number"
                list="history-numbers"
                size="large"
                ref={inputEl}
                pattern="\d*"
                defaultValue={props.carNumber}
                onKeyUp={(e) => e.key === 'Enter' && setCar(inputEl.current.value)} />
            <Button size="large" type="primary" onClick={() => setCar(inputEl.current.value)}>
                אתר רכב
            </Button>
        </div>
        <datalist id="history-numbers">
            {history.map(i => <option key={i} value={i} />)}
        </datalist>

        {/* <div onClick={() => openCamera(true)}>Cam</div>
        {showCamera && <Camera
          style={style.preview}
          ref={cam}>
          <div style={style.captureContainer} onClick={takePicture}>
            <div style={style.captureButton} />
          </div>
        </Camera>} */}

        {props.showCompare && <Collapse>
            <Panel header="השוואת רכבים" key="a"><div style={{ position: 'relative', height: 60 }}>
                <div className="searchInput" style={{
                    position: 'absolute',
                    width: '330px',
                    top: '30px',
                    transform: 'translateY(-50%) translateX(-50%)',
                    left: '50%',
                }}>
                    <input type="number"
                        onChange={(e) => setCarB(e.target.value)}
                        onKeyUp={(e) => e.keyCode === 13 ? navHistory.push(`/compare/${toCar || props.carNumber}/${e.target.value}`) : null}
                        size="large"
                        pattern="\d*"
                    />
                    <Link to={`/compare/${toCar || props.carNumber}/${carB}`}>
                        <Button size="large" type="primary">
                            השווה
                        </Button>
                    </Link>
                </div></div>
            </Panel>
        </Collapse>}

    </React.Fragment>);
});
// const style = {
//   preview: {
//     position: 'relative',
//   },
//   captureContainer: {
//     display: 'flex',
//     position: 'absolute',
//     justifyContent: 'center',
//     zIndex: 1,
//     bottom: 0,
//     width: '100%'
//   },
//   captureButton: {
//     backgroundColor: '#fff',
//     borderRadius: '50%',
//     height: 56,
//     width: 56,
//     color: '#000',
//     margin: 20
//   },
//   captureImage: {
//     width: '100%',
//   }
// };
export default CarSearchInput;

export const convertCCToLiters = (cc) => (cc / 1000).toFixed(1);

export const convertHorsepowerToKilowatts = (horsepower) => Math.round(horsepower * 0.745699872);

export const convertToDate = (datee) => {
    let formated = `${datee}`;

    if (formated.indexOf('-') === -1) {
        const year = formated.substring(0, 4);
        const month = formated.substring(4, 6);
        const day = formated.substring(6, 8);

        formated = year + '-' + month + '-' + day;
    }

    return new Date(formated).toLocaleDateString('he-HE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
};

export const formatDate = (date, options) => {
    return new Date(date).toLocaleDateString("he-il", {
        year: "numeric", month: "long", day: "numeric", ...options
    });
}

export const getOnlyMonth = (date) => {
    return new Date(date).toLocaleDateString("he-il", { month: "long" });
}

export const parseCarNumber = (carNumber) => {
    let formated = `${carNumber}`;
    let f1,f2,f3;

    if (formated.indexOf('-') === -1) {
        if (formated.length === 8) {
            f1 = formated.substring(0, 3);
            f2 = formated.substring(3, 5);
            f3 = formated.substring(5, 8);
        }
        else {
            f1 = formated.substring(0, 2);
            f2 = formated.substring(2, 5);
            f3 = formated.substring(5, 7);
        }

        formated = f1 + '-' + f2 + '-' + f3;
    }

    return formated;
};

// export const pad = (number, size) => {
//     let s = number + '';
//     while (s.length < size) s = '0' + s;
//     return s;
// };

export const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }


export const fixRishum = (str = '') =>
    (str || '').toString().slice(0, 2) + "-" + (str || '').toString().slice(2 + Math.abs(0));

export const translateKeys = (key) => ({
    teura_automatit_benesiya_kadima_ind: 'תאורה אוטומטית בנסיעה קדימה',
    shlita_automatit_beorot_gvohim_ind: 'שליטה אוטומטית באורות גבוהים',
    zihuy_holchey_regel_ind: 'זיהוי הולכי רגל',
    zihuy_beshetah_nistar_ind: 'זיהוי בשטח נסתר',
    zihuy_tamrurey_tnua_ind: 'זיהוי תמרורי תנועה',
    zihuy_matzav_hitkarvut_mesukenet_ind: 'זיהוי מצב התקרבות מסוכנת',
    bakarat_stiya_menativ_ind: 'בקרת סטייה מנתיב',
    bakarat_shyut_adaptivit_ind: 'בקרת שיוט אדפטיבית',
    maarechet_ezer_labalam_ind: 'מערכת עזר לבלם',
    matzlemat_reverse_ind: 'מצלמת רוורס',
    hayshaney_lahatz_avir_batzmigim_ind: 'חישני לחץ אויר בצמיגים',
    hayshaney_hagorot_ind: 'חיישני חגורות',
    nitur_merhak_milfanim_ind: 'ניטור מרחק מלפנים',

    bakarat_stiya_activ_s: 'בקרת סטייה אקטיבית',
    blima_otomatit_nesia_leahor: 'בלימה אוטונומית בנסיעה לאחור',
    bakarat_mehirut_isa: 'בקרת מהירות נסיעה',
    zihuy_rechev_do_galgali: 'זיהוי רכב דו גלגלי',
    blimat_hirum_lifnei_holhei_regel_ofanaim: 'בלימת חרום הולכי רגל',
    hitnagshut_cad_shetah_met: 'התנגשות קדימה שטח מת',
    alco_lock: 'alco lock',


    bakarat_yatzivut_ind: 'בקרת יציבות',
    abs_ind: 'ABS',
    degem_manoa: 'דגם מנוע',
    hege_koah_ind: 'הגה כח',
    halon_bagg_ind: 'חלון שמש בגג',
    mazgan_ind: 'מזגן',
    argaz_ind: 'ארגז',
    galgaley_sagsoget_kala_ind: 'חישוקי מגנזיום',

    shinui_mivne_ind: 'שינוי מבנה',
    gapam_ind: 'שינוי גפ"מ',
    shnui_zeva_ind: 'שינוי צבע',
    shinui_zmig_ind: 'שינוי מידת צמיגים',


// kinuy_mishari_clean: 'דגם',
// shnat_yitzur: 'שנת יצור',
// ramat_gimur: 'רמת גימור',
// merkav: 'מרכב',
// kvuzat_agra_cd: 'קבוצת רישוי',
// mivchan_acharon_dt: 'תאריך טסט אחרון',
// tokef_dt: 'תוקף רישיון',
// mishkal_kolel: 'אגרת רישוי',
// baalut: 'בעלות',

// shovy_agra: 'שווי אגרה',

    nefah_manoa: 'נפח מנוע',
    koah_sus: 'הספק (כ"ס)',
    mishkal_kolel: 'משקל כולל',
    gova: 'גובה',
    delek_nm: 'סוג דלק',

    hanaa_nm: 'הנעה',
    kosher_grira_im_blamim: 'כושר גרירה עם בלמים',
    kosher_grira_bli_blamim: 'כושר גרירה ללא בלמים',
    mispar_moshavim: 'מקומות ישיבה',
    mispar_dlatot: 'מספר דלתות',
    mispar_halonot_hashmal: 'מספר חלונות חשמל',
    mispar_kariot_avir: 'מספר כריות אוויר',
// technologiat_hanaa_nm: 'טכנולוגיית הנעה',

// automatic_ind: 'תיבת הילוכים',
// sug_mamir_nm: 'סוג ממיר'
})[key];

export const translateSugDegem = (key) => ({
    M: 'מסחרי',
    P: 'פרטי'
})[key];

    
/**
 * תא כפול
 * תא בודד
 * שדה
 * קופה
 * קומבי
 * קבריולט
 * פנאי-שטח
 * סטיישן
 * סדאן
 * סגור/משלוח
 * משא אחוד
 * ואן/נוסעים
 * הצ'בק
 * MPV
 */

// ספורטבק – מונח של אאודי לרכבי הצ'בק 5 דלתות.
// גראן-קופה/ פאסטבק – רכב ספורט דומה בצורתו לקופה אך בעל 4 דלתות.
// MPV– מיניוואן, רכב המתאים להסעת 7-8 נוסעים, אך אינו מיועד לשימוש מסחרי.

export const getTooltipByMerkav = (merkav) => ({
    "תא כפול": "טנדר או משאית בעלת שתי קבינות, 4 דלתות ו-5 מקומות ישיבה",
    "תא בודד": "טנדר או משאית בעלת קבינה אחת, 2 דלתות ו-2 מקומות ישיבה",
    "שדה": "רכב שדה",
    "קופה": "רכב ספורט 2 דלתות שבו החלק האחורי יורד באלכסון",
    "קומבי": "רכב מסחרי שמשלב הסעת מטען ונוסעים",
    "קבריולט": "רכב ספורט 2 דלתות עם גג נפתח",
    "פנאי-שטח": "רכב גבוה, ג'יפ או ג'יפון, יכול להיות 4X4 אבל לא בהכרח",
    "סטיישן": "מרכב המבוסס על עיבוד של מרכבי הסאדן, כאשר תא הנוסעים ותא המטען מחוברים, עם חלונות צד וחלון אחורי באזור המטען, כשמעליהם גג מוארך. מרכב הסטיישן מגדיל את אפשרויות המטען לעומת מרכב הסדאן.",
    "סדאן": "מרכב בעל 4 דלתות במבנה קלאסי של תא מנוע, תא נוסעים ותא מטען. לאורך שנים זוהי צורת הרכב הנפוצה והפופולארית ביותר.",
    "סגור/משלוח": "רכב מסחרי המיועד להעברת מטען",
    "משא אחוד": "רכב מסחרי המיועד להעברת מטען",
    "ואן/נוסעים": "רכב מסחרי המיועד להסעת נוסעים",
    "הצ'בק": "מרכב בעל 3 או  5דלתות המכונה 'שתי קוספאות' – תא נוסעים הכולל למעשה גם את תא המטען ותא המנוע. מרכב האצ'בק מאפשר הגדלה משמעותית של הנפח למטען, והגדלה נוספת עם קיפול המושבים האחוריים.",
    "MPV": "רכב המתאים להסעת 7-8 נוסעים, אך אינו מיועד לשימוש מסחרי",
})[merkav];

export const checkSugTav = (sug) => ({
    '01': 'תג נכה רגיל',
    '02': 'תג נכה על כיסא גלגלים',
    '03': 'תג נכה רגיל מטעם משרד הביטחון',
    '04': 'תג נכה על כיסא גלגלים מטעם משרד הביטחון'
})[sug];

export const translateTozar = (tozar) => ({
    "אאודי": "AUDI",
    "אופל": "opel",
    "איווקו": "eveko",
    "איסוזו": "isuzu",
    "אל.טי.איי.": "lta",
    "אלפא רומיאו": "alfa-romeo",
    "אמ.סי.סי.": "mcc",
    "אסטון מרטין": "aston-martin",
    "ביואיק": "BUICK",
    "ב מ וו": "BMW",
    "בנטלי": "BENTLEY",
    "ג'י.אמ.סי": "GMC",
    "גיפ": "JEEP",
    "ג'יפ": "JEEP",
    "ג'.מ": "gm",
    "גרייט וול": "grate-wall",
    "דאציה": "dacia",
    "דה טומאסו": "da-tomaso",
    "דודג'": "DODGE",
    "דונגפנג": "dongfang",
    "די.אס": "ds",
    "די.אף.אס.קיי": "dfsk",
    "דייהו": "daewoo",
    "דייהטסו": "daihatsu",
    "דימלרקריזלר": "dimlkrizler",
    "האמר": "hummer",
    "הונדה": "HONDA",
    "ואז": "vas",
    "וולבו": "VOLVO",
    "טויוטה": "TOYOTA",
    "טלקו": "talko",
    "יגואר": "JAGUAR",
    "יונדאי": "HYUNDAI",
    "לינקולן": "LINCOLN",
    "לנציה": "lancia",
    "לנצ'יה": "lancia",
    "לקסוס": "LEXUS",
    "מזארטי": "mazarati",
    "מזדה": "MAZDA",
    "מיצובישי": "MITSUBISHI",
    "מקסוס": "mexus",
    "מרצדס בנץ": "MERCEDES-BENZ",
    "מרצדס-בנץ": "MERCEDES-BENZ",
    "ניסאן": "NISSAN",
    "סאאב": "saab",
    "סאנגיונג": "ssangyong",
    "סובארו": "SUBARU",
    "סוזוקי": "SUZUKI",
    "סוזוקי-מרוטי": "suzuki-maroti",
    "סיאט": "seat",
    "סיטרואן": "citroen",
    "סייק": "saic",
    "סמארט": "SMART",
    "סמארט ג'.מ.ב.ה": "SMART",
    "סקודה": "skoda",
    "פולקסווגן": "VOLKSWAGEN",
    "פונטיאק": "pontiac",
    "פורד": "FORD",
    "פורשה": "PORSCHE",
    "פיאג'ו": "piaggio",
    "פיאט": "FIAT",
    "פיאט/מדייר סוז": "FIAT",
    "פיאט קרייזלר": "CHRYSLER",
    "פיג'ו": "peugeot",
    "פרארי": "ferari",
    "קאדילאק": "CADILLAC",
    "קיה": "KIA",
    "קרייזלר": "CHRYSLER",
    "רובר": "rover",
    "רנו": "renoult",
    "רנו ": "renoult",
    "שברולט": "CHEVROLET"

    // "Make": "ACURA",
    // "Make": "FREIGHTLINER",
    // "Make": "INFINITI",
    // "Make": "LAND ROVER",
    // "Make": "MINI",
    // "Make": "RAM",
    // "MAEL": "ROLLS-ROYCE",
    // "Make": "SRT",
    // "Make": "TESLA",

})[tozar];

export const translateTozarBulbs = (tozar) => ({
    "אאודי": "AUDI",
    "אופל": "opel",
    "איווקו": "",
    "איסוזו": "isuzu",
    "אל.טי.איי.": "",
    "אלפא רומיאו": "alfa+romeo",
    "אמ.סי.סי.": "",
    "אסטון מרטין": "aston+martin",
    "ביואיק": "",
    "ב מ וו": "BMW",
    "בנטלי": "",
    "ג'י.אמ.סי": "",
    "גיפ": "JEEP",
    "ג'יפ": "JEEP",
    "ג'.מ": "",
    "גרייט וול": "",
    "דאציה": "dacia",
    "דה טומאסו": "",
    "דודג'": "DODGE",
    "דונגפנג": "",
    "די.אס": "ds",
    "די.אף.אס.קיי": "",
    "דייהו": "daewoo-chevrolet",
    "דייהטסו": "daihatsu",
    "דימלרקריזלר": "",
    "האמר": "",
    "הונדה": "HONDA",
    "ואז": "",
    "וולבו": "VOLVO",
    "טויוטה": "TOYOTA",
    "טלקו": "",
    "יגואר": "JAGUAR",
    "יונדאי": "HYUNDAI",
    "לינקולן": "",
    "לנציה": "lancia",
    "לנצ'יה": "lancia",
    "לקסוס": "LEXUS",
    "מזארטי": "mazarati",
    "מזדה": "MAZDA",
    "מיצובישי": "MITSUBISHI",
    "מקסוס": "",
    "מרצדס בנץ": "mercedes+benz",
    "מרצדס-בנץ": "mercedes+benz",
    "ניסאן": "NISSAN",
    "סאאב": "saab",
    "סאנגיונג": "ssangyong",
    "סובארו": "SUBARU",
    "סוזוקי": "SUZUKI",
    "סוזוקי-מרוטי": "SUZUKI",
    "סיאט": "seat",
    "סיטרואן": "citroen",
    "סייק": "",
    "סמארט": "SMART",
    "סמארט ג'.מ.ב.ה": "SMART",
    "סקודה": "skoda",
    "פולקסווגן": "VOLKSWAGEN+VW",
    "פונטיאק": "",
    "פורד": "FORD",
    "פורשה": "PORSCHE",
    "פיאג'ו": "",
    "פיאט": "FIAT",
    "פיאט/מדייר סוז": "FIAT",
    "פיאט קרייזלר": "CHRYSLER",
    "פיג'ו": "peugeot",
    "פרארי": "",
    "קאדילאק": "",
    "קיה": "KIA",
    "קרייזלר": "CHRYSLER",
    "רובר": "rover",
    "רנו": "renault",
    "רנו ": "renault",
    "שברולט": "daewoo-chevrolet",
    "לנדרובר": "land+rover",
    "לוטוס": "lotus"

    // "Make": "ACURA",
    // "Make": "FREIGHTLINER",
    // "Make": "INFINITI",
    // "Make": "MINI",
    // "Make": "RAM",
    // "MAEL": "ROLLS-ROYCE",
    // "Make": "SRT",
    // "Make": "TESLA",

})[tozar];


// updated to 1-Jul-2022
export const shovyAgra = (carWeight, group, year) => {
    const agra3500 = {
        1: {
            0: 1240,
            1: 1240,
            2: 1240,
            3: 1240,
            4: 1103,
            5: 1103,
            6: 1103,
            7: 985,
            8: 985,
            9: 985,
            10: 879 
        },
        2: {
            0: 1537,
            1: 1537,
            2: 1537,
            3: 1537,
            4: 1360,
            5: 1360,
            6: 1360,
            7: 1209,
            8: 1209,
            9: 1209,
            10: 1075
        },
        3: {
            0: 1825,
            1: 1825,
            2: 1825,
            3: 1825,
            4: 1614,
            5: 1614,
            6: 1614,
            7: 1432,
            8: 1432,
            9: 1432,
            10: 1268
        },
        4: {
            0: 2148,
            1: 2148,
            2: 2148,
            3: 2148,
            4: 1848,
            5: 1848,
            6: 1848,
            7: 1593,
            8: 1593,
            9: 1593,
            10: 1376
        },
        5: {
            0: 2438,
            1: 2438,
            2: 2438,
            3: 2438,
            4: 2035,
            5: 2035,
            6: 2035,
            7: 1703,
            8: 1703,
            9: 1703,
            10: 1434
        },
        6: {
            0: 3402,
            1: 3402,
            2: 3402,
            3: 3402,
            4: 2588,
            5: 2588,
            6: 2588,
            7: 1977,
            8: 1977,
            9: 1977,
            10: 1516
        },
        7: {
            0: 4787,
            1: 4787,
            2: 4787,
            3: 4787,
            4: 3393,
            5: 3393,
            6: 3393,
            7: 2418,
            8: 2418,
            9: 2418,
            10: 1737
        },
    };
    const numOfYears = Math.min((new Date().getFullYear() - year), 10);

    // https://www.gov.il/he/Departments/Guides/vehicles_licences?chapterIndex=12
    return parseInt(carWeight, 10) < 3500 ? agra3500[group][numOfYears] : 0;
};


export const translateEretz = (eretz) => ({
    'בריטניה': 'GB',
    'גרמניה': 'DE',
    'קוריאה הדרומית': 'KR',
    'בלגיה': 'BE',
    'ספרד': 'ES',
    'תורכיה': 'TR',
    'צרפת': 'FR',
    'פולין': 'PL',
    'יפן': 'JP',
    "צ'כיה": 'CZ',
    'איטליה': 'IT',
    'קנדה': 'CA',
    'תאילנד': 'TH',
    'ארהב': 'US',
    'מרוקו': 'MA',
    'סלובקיה': 'SK',
    'רומניה': 'RO',
    'הולנד': 'NL',
    'טורקיה': 'TR',
    'מקסיקו': 'MX',
    'אוסטריה': 'AT',
    'שבדיה': 'SE',
    'הונגריה': 'HU',
    'ישראל': 'IL',
    'פורטוגל': 'PT',
    'סין': 'CN',
    'הודו': 'IN',
    'מקסיקה': 'MX',
    'דרום אפריקה': 'ZA',
    'סרביה': 'RS',
    'סלובניה': 'SI',
    "ארה'ב": 'US',
    'רוסיה': 'RU',
    'ברזיל': 'BR',
    'פינלנד': 'FI',
    'ארגנטינה': 'AR',
  })[eretz];


  export const translateTozarOBD2 = (tozar) => ({
"אאודי": "Audi", 
"אופל": "Opel",  
"אורה": null,
"איווייס": null,
"איווקו": "Iveco",
"איויאיסי": null,
"איסוזו": "Isuzu",
"אל אי וי סי": null,
"אל.טי.איי.": null,
"אלפא רומיאו": "Alfa Romeo",
"אמ.סי.סי.": null,
"אסטון מרטין": "Aston Martin",
"אף אי דאבל יו": null,
"אקס אי וי": null,
"בורג": null,
"ביואיק": "Buick",
"בי ווי די": "BYD",
"ב מ וו": "BMW",
"בנטלי": "Bentley",
"גופל": null,
"גי.אי.סי": null,
"גילי": "Geely",
"ג'יפ": "Jeep",
"ג'.מ": "GMC",
"גרייט וול": "Great Wall",
"דאבל יו אם איי": null,
"דאציה": "Dacia",
"דה טומאסו": null,
"דודג'": "Dodge",
"דונגפנג": "Dongfeng",
"די אס": "DS",
"די.אס": "DS",
"די.אף.אס.קיי": null,
"דייהו": "Daewoo",
"דייהטסו": "Daihatsu",
"האמר": "Hummer",
"הונדה": "Honda",
"ואז": "Vaz",
"וואי": null,
"וויה": null,
"וולבו": "Volvo",
"ט אי סי": null,
"טויוטה": "Toyota",
"טלקו": null,
"טסלה": "Tesla",
"יגואר": "Jaguar",
"יונדאי": "Hyundai",
"לאמבורגיני": "Lamborghini",
"לינק אנד קו": null,
"לינקולן": "Lincoln",
"ליפמוטור": "",
  "לנדרובר": "Land Rover",
  "לנצ'יה": "Lancia",
  "לקסוס": "Lexus",
  "מאן": "MAN",
  "מ א ן": "MAN",
  "מ.ג": "MG",
  "מזארטי": "Maserati",
  "מזדה": "Mazda",
  "מיצובישי": "Mitsubishi",
  "מקסוס": null,
  "מרצדס": "Mercedes",
  "ניסאן": "Nissan",
  "סאאב": "Saab",
  "סאנגיונג": "Ssangyong",
  "סובארו": "Subaru",
  "סוזוקי": "Suzuki",
  "סיאט": "Seat",
  "סיטרואן": "Citroen",
  "סמארט": "Smart",
  "סנטרו": null,
  "סקודה": "Skoda",
  "סקיוול": null,
  "סרס": null,
  "פולסטאר": null,
  "פולקסווגן": "Volkswagen",
  "פונטיאק": "Pontiac",
  "פורד": "Ford",
  "פורשה": "Porsche",
  "פיאג'ו": "Piaggio",
  "פיאט": "Fiat",
  "פיג'ו": "Peugeot",
  "פרארי": "Ferrari",
  "צ'רי": "Chery",
  "קאדילאק": "Cadillac",
  "קארמה": null,
  "קיה": "Kia",
  "קרייזלר": "Chrysler",
  "רובר": "Rover",
  "רולס-רויס": "Rolls Royce",
  "רנו": "Renault",
  "שברולט": "Chevrolet",




// "Abarth",
// "Acura",
// "",
// "Alpine",
 
// "BAW",
// "Brilliance",

// "CFMOTO",
// "Caterham",
// "Changan",
// "Changhe",
// "",

// "DAF",
// "DR",
// "Datsun",
// "Ducati",

// "Foton",

// "GAZ",
// "",

// "Holden",
// "Infiniti",
// "Iran Khodro",
// "JAC",
// "JMC",
// "King Long",
// "Lada",
// "",
// "",
// "",
// "Lifan",
// "MVM",
// "Mahindra",
// "Mercury",
// "Mini",
// "Perodua",
// "Proton",
// "Samsung",
// "Saturn",
// "Scania",
// "Scion",
// "Secma",
// "Tagaz",
// "Tata",
// "Triumph",
// "Troller",
// "UAZ",
// "Vauxhall",
// "Voge",
// "Yamaha",
// "Zaz",
// "Zotye"
})[tozar];
import React from 'react';

// import Table from 'antd/lib/table';
// import Card from 'antd/lib/card';
// import Input from 'antd/lib/input';
// import Select from 'antd/lib/select';
// import Typography from 'antd/lib/typography';
// import Collapse from 'antd/lib/collapse';

import Space from 'antd/lib/space';
import Track from './TrackWrapper';
import Pezael from './Pezael';
import Arad from './Arad';
import MotorCity from './MotorCity';

// import './style.scss';

const Tracks = () => (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <h1>מידע שימושי: מסלולי מרוצים</h1>

        <Track title={<>פצאל - <a href="https://www.pezael-circuit.com/" target="_blank" rel="noopener noreferrer">אתר רשמי</a></>}
            map="pezael"
            lat={35.455340}
            lon={32.052516}>
            <Pezael/>
        </Track>

        <Track title={<>ערד - <a href="https://www.facebook.com/AradRacingTrack/" target="_blank" rel="noopener noreferrer">פייסבוק רשמי</a></>}
            map="arad"
            lat={35.166822}
            lon={31.255708}>
            <Arad/>
        </Track>

        <Track title={<>מוטורסיטי - <a href="https://www.motorcity.co.il/" target="_blank" rel="noopener noreferrer">אתר רשמי</a></>}
            map="motorCity"
            lat={34.729196}
            lon={31.265851}>
            <MotorCity/>
        </Track>

    </Space>
);

export default Tracks;
import React from 'react';
import { parseCarNumber } from '../helpers';
import {
    HISTORY_KEY
} from '../common/consts';
import './History.scss';

function History(props) {

    const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
    const historyPic = JSON.parse(localStorage.getItem('historyPic') || '{}');

    return (
        <div className="historySection">
            {history.map((cn, idx) => <div key={idx} onClick={() => props.onClick(cn)} className="historyItem" style={{ backgroundImage: `url('${historyPic[cn]}')` }}>
                {(historyPic[cn] || '').includes('http') ? '' : historyPic[cn]}<br /><br />
                {parseCarNumber(cn)}
            </div>)}

            {history.length > 0 && <div className="lastItemTitle">חיפושים אחרונים</div>}
        </div>
    );
};

export default History;
import React, { Component } from 'react';
import Button from 'antd/lib/button';
import { ListItem } from './';
import axios from 'axios';

// import './style.scss';

export default class CarRecall extends Component {
    constructor() {
        super();
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');

        this.state = {
            debug,
            yevuan: null,
            loadingYevuan: false
        };
    }

    getYevuan = () => {
        const { tozeret, degem, shnat_yitzur } = this.props;
        this.setState({
            loadingYevuan: true
        }, () => axios.get(`/yevuan/${parseInt(tozeret, 10)}/${parseInt(degem, 10)}/${shnat_yitzur}`)
            .then(({ data: { yevuan } }) => this.setState({
                loadingYevuan: false,
                yevuan
            }))
            .catch(err => console.log(err))
        )
    };

    render() {
        const { yevuan, loadingYevuan, debug } = this.state;

        return (
            <div className="section price" style={{ padding: '0 10px' }}>
                <h2>פרטי יבואן</h2>

                {yevuan && yevuan.length>0 && (<ul>
                        <ListItem feat="יבואן" value={yevuan[0].shem_yevuan} title={yevuan[0].semel_yevuan} />
                        <ListItem feat="מחיר מחירון יבואן" value={(yevuan[0].mehir).toLocaleString('he-IL', {
                            style: 'currency',
                            currency: 'ILS',
                        })} />
                    </ul>)}

                    {!yevuan && (<Button type="primary" loading={loadingYevuan} onClick={this.getYevuan}>
                        הצג פרטים
                    </Button>)}

                {debug === 'true' && <pre>recalls: {JSON.stringify(yevuan, null, 2)}</pre>}
            </div>
        );
    }
};

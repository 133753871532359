const weatherTranslate = {
200: ['סופת רעמים', 'סופת רעמים עם גשם קל'],
201: ['סופת רעמים', 'סופת רעמים עם גשם'],
202: ['סופת רעמים', 'סופת רעמים עם גשם כבד'],
210: ['סופת רעמים', 'סופת רעמים קלה'],
211: ['סופת רעמים', 'סופת רעמים'],
212: ['סופת רעמים', 'סופת רעמים כבדה'],
221: ['סופת רעמים', 'סופת רעמים סמויה'],
230: ['סופת רעמים', 'סופת רעמים עם טפטוף קל'],
231: ['סופת רעמים', 'סופת רעמים עם טפטוף'],
232: ['סופת רעמים', 'סופת רעמים עם טפטוף כבד'],

300: ['טפטוף', 'טפטוף בעוצמת אור'],
301: ['טפטוף', 'טפטוף'],
302: ['טפטוף', 'טפטוף בעצימות כבדה'],
310: ['טפטוף', 'גשם טפטוף בעוצמה קלה'],
311: ['טפטוף', 'טפטוף גשם'],
312: ['טפטוף', 'גשם טפטוף בעצימות כבדה'],
313: ['טפטוף', 'גשם ומטפטף'],
314: ['טפטוף', 'גשם ומטפטף כבד'],
321: ['טפטוף', 'טפטוף מקלחת'],

500: ['גשם', 'גשם קל'],
501: ['גשם', 'גשם מתון'],
502: ['גשם', 'גשם בעצימות כבדה'],
503: ['גשם', 'גשם כבד מאוד'],
504: ['גשם', 'גשם קיצוני'],
511: ['גשם', 'גשם קפוא'],
520: ['גשם', 'גשם מקלחת בעוצמה קלה'],
521: ['גשם', 'גשם מקלחת'],
522: ['גשם', 'גשם מקלחת בעוצמה כבדה'],
531: ['גשם', 'גשם מקלחת מרופט'],

600: ['שלג', 'שלג קל'],
601: ['שלג', 'שלג'],
602: ['שלג', 'שלג כבד'],
611: ['שלג', 'גשם'],
612: ['שלג', 'מקלחת קלה'],
613: ['שלג', 'גשם מקלחת'],
615: ['שלג', 'גשם קל ושלג'],
616: ['שלג', 'גשם ושלג'],
620: ['שלג', 'שלג מקלחת קל'],
621: ['שלג', 'שלג מקלחת'],
622: ['שלג', 'שלג מקלחת כבד'],

701: ['ערפל', 'ערפל'],
711: ['עשן', 'עשן'],
721: ['אובך', 'אובך'],
731: ['אבק', 'מערבולות חול / אבק'],
741: ['ערפל', 'ערפל'],
751: ['חול', 'חול'],
761: ['אבק', 'אבק'],
762: ['אפר', 'אפר וולקני'],
771: ['חוליות', 'חוליות'],
781: ['טורנדו', 'טורנדו'],

800: ['צלול', 'שמיים בהירים'],

801: ['עננים', 'מעט עננים: 11-25%'],
802: ['עננים', 'עננים מפוזרים: 25-50%'],
803: ['עננים', 'עננים שבורים: 51-84%'],
804: ['עננים', 'עננים: 85%-100%']
};

export {weatherTranslate};
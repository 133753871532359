import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Overlay from "ol/Overlay.js";
import * as proj from 'ol/proj';
import GPX from 'ol/format/GPX';

import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as LayerTile, Vector as VectorLayer } from 'ol/layer';
import { OSM as SourceOSM, Vector as VectorSource } from 'ol/source';
// import { Circle as CircleStyle, Fill, Text } from 'ol/style';
import { Stroke, Style, Icon } from 'ol/style';
import { Chart } from 'react-google-charts';

import {
  GlobalOutlined,
  ShareAltOutlined,
  CarFilled,
  WarningTwoTone,
  AreaChartOutlined,
  SaveOutlined,
  InfoCircleFilled,
  VideoCameraOutlined
} from '@ant-design/icons';

import Spin from 'antd/lib/spin';
import Button from 'antd/lib/button';
import Popover from 'antd/lib/popover';
import Tooltip from 'antd/lib/tooltip';
import Card from 'antd/lib/card';
import Switch from 'antd/lib/switch';

import RACE_FLAG from './assets/flag.svg';
import { STATIONS } from '../common/consts';
import { pad } from '../helpers';
import { ROADS, Weather } from './';

import './Roads.scss'

// export const pad = (n, width, z) => {
//   z = z || '0';
//   n = n + '';
//   return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
// }
class Roads extends Component {
  constructor(props) {
    super(props);

    // create feature layer and vector source
    const featuresLayer = new VectorLayer({
      source: new VectorSource()
    });

    const roadName = props.match.params.road;
    const roadData = _.filter(ROADS, { key: roadName })[0];

    this.state = {
      loading: true,
      loadWarnings: true,
      warnings: [],
      center: proj.fromLonLat([34.6962941, 32.4269213], 'EPSG:3857'),
      zoom: 17,
      featuresLayer,
      gpxData: { name: 'טוען נתוני מסלול...', desc: '', track: [] },
      roadName,
      roadData,
      showGasStations: props.showGasStations,
      loadingGas: false,
      openHours: '247',
      stations: {
          paz: [], sonol: [], doralon: [], delek: [], tapuz: []
      },
    };

    // this.event = new Event('bla');

    this.map = new Map({
      target: null,
      layers: [new LayerTile({
        source: new SourceOSM()
      })],
      view: new View({
        center: this.state.center,
        zoom: this.state.zoom
      })
    });
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        fetch(`/api/gpx?road=${this.state.roadName}&lat=${position.coords.latitude}&lon=${position.coords.longitude}`)
          .then(res => res.json())
          .then(gpxData => this.setState({ gpxData }, this.buildMap));
      });
    }

    fetch('/api/iroads')
      .then(res => res.json())
      .then(({ roads }) => {
        const warnings = [...this.state.roadData.info];

        roads.map((road) => this.state.roadData.roads.includes(parseInt(road.number)) && warnings.push(road.description));

        this.setState({
          loadWarnings: false,
          warnings
        })
      }
      )
      .catch(() => this.setState({ loadWarnings: false }));
  }

  componentWillUnmount() {
    this.map.setTarget(null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showGasStations !== this.state.showGasStations) {
        //Perform some operation here
        this.setState({ showGasStations: this.state.showGasStations});

        if (this.state.showGasStations) {
            this.setState({ loadingGas: true });
            this.getAllFuelStations();
        } else {
            this.buildMap();
        }
    }
}

getAllFuelStations = () => {
  const { openHours } = this.state;
  Promise.all([
      fetch(`/api/delek/paz?openhours=${openHours}`),
      fetch(`/api/delek/doralon?openhours=${openHours}`),
      fetch(`/api/delek/delek?openhours=${openHours}`),
      fetch(`/api/delek/sonol?openhours=${openHours}`),
      fetch(`/api/delek/tapuz?openhours=${openHours}`)
  ])
      .then(async ([
          pazStations,
          doralonStations,
          delekStations,
          sonolStations,
          tapuzStations
      ]) => {
          const paz = await pazStations.json();
          const doralon = await doralonStations.json();
          const delek = await delekStations.json();
          const sonol = await sonolStations.json();
          const tapuz = await tapuzStations.json();
          return [
              paz,
              doralon,
              delek,
              sonol,
              tapuz
          ]
      })
      .then((allStationsResult) => {
          this.setState({
              loadingGas: false,
              stations: {
                  paz: allStationsResult[0].stations,
                  doralon: allStationsResult[1].stations,
                  delek: allStationsResult[2].stations,
                  sonol: allStationsResult[3].stations,
                  tapuz: allStationsResult[4].stations
              }
          }, this.buildMap);
      })
      .catch((err) => console.log(err));
}

  buildMap = () => {
    const {
      showGasStations,
      stations: {
          doralon,
          delek,
          sonol,
          paz,
          tapuz
      }
  } = this.state;

    this.map.setTarget("map");

    window.document.title = `כביש נהיגה: ${this.state.gpxData.name}`;
    // document.querySelector('meta[name="description"]')
    //   .setAttribute('content', this.state.gpxData.desc);

    let features = [];
    features = [
      ...doralon,
      ...delek,
      ...sonol,
      ...paz,
      ...tapuz
    ].map(station => {
        const feature = new Feature({
            geometry: new Point(proj.fromLonLat([station.location.longitude, station.location.latitude])),
            sug: 'gas',
            ...station
        });

        var style = new Style({
            image: new Icon({
                src: STATIONS[station.type].img
            })
        });

        feature.setStyle(style);
        return feature;
    });

    // create feature layer and vector source
    const gasStationsLayer = new VectorLayer({
        name: 'gas',
        source: new VectorSource({ features })
    });

    if (!showGasStations) {
      this.map.getLayers().forEach((layer) => layer && layer.get('name') === 'gas' && this.map.removeLayer(layer))
    }
    else {
        this.map.addLayer(gasStationsLayer);
    }

    const features2 = [{ location: this.state.gpxData.start }].map(item => {
      const feature = new Feature({
        geometry: new Point(proj.fromLonLat([item.location.lon, item.location.lat])),
        ...item
      });

      var style = new Style({
        image: new Icon({
          src: RACE_FLAG,
          size: [478, 478],
          scale: 0.05
        }),

        // image: new CircleStyle({
        //   radius: 12,
        //   stroke: new Stroke({ color: '#fff' }),
        //   fill: new Fill({ color: '#ff00000' })
        // }),
        // text: new Text({
        //   text: '^',
        //   fill: new Fill({
        //     color: '#fff'
        //   })
        // })
      });

      feature.setStyle(style);

      return feature;
    });
    const featuresLayer = new VectorLayer({
      source: new VectorSource({ features: features2 })
    });
    featuresLayer.setZIndex(11);
    this.map.addLayer(featuresLayer);

    const source = new VectorSource({
      url: this.state.roadData.gpx,
      format: new GPX({ extractWaypoints: true })
    });
    const vectorLayer = new VectorLayer({
      source,
      style: new Style({
        stroke: new Stroke({
          color: '#1b8cf6',
          width: 4
        })
      })
    });
    this.map.addLayer(vectorLayer);

    // fit when the source loads
    vectorLayer.getSource().on('addfeature', () => {
      this.map.getView().fit(vectorLayer.getSource().getExtent());
    });

    // bind click on vector
    this.map.on('click', (evt) => {
      const feature = this.map.getFeaturesAtPixel(evt.pixel);
      if (feature && feature[0].values_.location) {
        const { lat, lon } = feature[0].values_.location;
        this.openNavigation(lat, lon);
      }
    });

    navigator.geolocation.getCurrentPosition((position) => {
      const marker = new Overlay({
        position: proj.fromLonLat([position.coords.longitude, position.coords.latitude], 'EPSG:3857'),
        element: document.getElementById("location"),
        positioning: 'center-center',
        stopEvent: false
      });

      this.map.addOverlay(marker);
    });


    this.highlight = new Overlay({
      position: proj.fromLonLat([this.state.gpxData.start.lon, this.state.gpxData.start.lon]),
      positioning: 'center-center',
      element: document.getElementById('highlight'),
      stopEvent: false
    });

    this.map.addOverlay(this.highlight);
  }

  openNavigation = (lat, lon) => {
    if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") !== -1) ||
      (navigator.platform.indexOf("iPad") !== -1) ||
      (navigator.platform.indexOf("iPod") !== -1))
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);

    else /* else use Google */
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
  }

  setWeatherIcon = (icon, track) => {
    const weatherImg = document.getElementById("weatherImg");
    if (weatherImg) { weatherImg.src = `https://openweathermap.org/img/wn/${icon || '01d'}.png`; }

    const marker = new Overlay({
      position: proj.fromLonLat([track.lon, track.lat], 'EPSG:3857'),
      element: document.getElementById("weather"),
      positioning: 'center-center',
      stopEvent: false
    });

    this.map.addOverlay(marker);
  }

  calculateMinMax(data) {
    let min = data[1][1],
      max = 0,
      gain = 0,
      loss = 0;

    for (var i = 1; i < data.length; i++) {
      if (data[i][1] < min) {
        min = data[i][1];
      }
      if (data[i][1] > max) {
        max = data[i][1];
      }
      if (data[i][1] > data[i - 1][1]) {
        gain = gain + (data[i][1] - data[i - 1][1]);
      }
      if (data[i - 1][1] > data[i][1]) {
        loss = loss + (data[i - 1][1] - data[i][1]);
      }
    }
    return { max, min, gain: Math.round(gain), loss: Math.round(loss) };
  }

  render() {
    const {
      gpxData: { name, track, routeLength, distance, totalDistance, trafficTime, baseTime },
      roadData: { gpx, roads, round, cams },
      loadWarnings, warnings, showGasStations
    } = this.state;

    const data = [['id', 'גובה']];

    track.map((i) => data.push([`${i.lat},${i.lon}`, i.elevation]));

    let elev = {}, content;
    if (data.length > 1) {
      elev = this.calculateMinMax(data);
      content = (
        <div style={{ textAlign: 'right' }}>
          <p>גובה מצטבר: {elev.gain} מ'</p>
          <p>אובדן גובה: {elev.loss}- מ'</p>
          <p>נקודת מקסימום: {Math.round(elev.max)} מ'</p>
          <p>נקודת מינימום: {Math.round(elev.min)} מ'</p>
        </div>
      );
    }

    const baseHours = baseTime / 60;
    const baseTimeStr = baseHours >= 1 ? `${Math.floor(baseHours)}:${pad(Math.round(baseTime % 60), 2)}` : `${Math.round(baseTime)} דקות`;

    let trafficTimeStr;

    if (trafficTime !== baseTime) {
      const travelHours = trafficTime / 60;

      trafficTimeStr = (travelHours) >= 1 ? `${Math.floor(travelHours)}:${pad(Math.round(trafficTime % 60), 2)}` : `${Math.round(trafficTime)} דקות`;
    }

    return (<div>
      <h1 title={name}>{name}</h1>

      {typeof navigator.share === 'function' && <ShareAltOutlined className="share-btn" onClick={() => {
        navigator.share({
          title: `מספרכב - מסלול טיול: ${name}`,
          text: name,
          url: window.location.href,
        })
      }} />}

      {track[0] && <CarFilled className="car-btn" onClick={() => {
        window.open(`https://www.waze.com/ul?ll=${track[0].lat}%2C${track[0].lon}&navigate=yes&zoom=17`);
      }} />}

      <div>
        {!!roads.length && <Button.Group size={'small'}>
          מספרי כבישים: {roads.map(n => <Button key={n} size='small' href={`https://he.wikipedia.org/wiki/%D7%9B%D7%91%D7%99%D7%A9_${n}`} target="_blank" rel="noopener noreferrer">{n}</Button>)}
        </Button.Group>}
        <Button style={{ marginRight: '10px' }} href={gpx} type="primary" size="small"><SaveOutlined />&nbsp;&nbsp;הורד GPX</Button>

        &nbsp; <Switch checked={showGasStations} onChange={() => this.setState({ showGasStations: !showGasStations })} />
        &nbsp;דלק


        {track[0] && <Weather lat={track[0].lat} lon={track[0].lon} icnCallback={this.setWeatherIcon} />}
      </div>

      <div className="section-header">
        <h2 className="road-header-title">
          {loadWarnings && <Spin />}
          {!_.isEmpty(warnings) && <Tooltip placement="topRight" title={<ul>
            {warnings.map((warning, idx) => <li key={idx}>{warning}</li>)}
          </ul>}><WarningTwoTone /></Tooltip>}
          {' '}
          <GlobalOutlined /> מפת המסלול {round && <small>(מעגלי)</small>}
        </h2>
        <span>
          <Popover placement="leftBottom"
            content={<div style={{ textAlign: 'right' }}>
              {routeLength > 0 && <p style={{ whiteSpace: 'nowrap' }}>אורך: {+(Math.round(routeLength + "e+2") + "e-2")} ק"מ</p>}
              {distance && <p>{+(Math.round(distance + "e+2") + "e-2")} ק"מ מהמיקום שלי</p>}
              {distance && <p>{Math.round(totalDistance)} ק"מ כולל הלוך וחזור</p>}
              {baseTime && <p>זמן הגעה: {baseTimeStr}</p>}
              {trafficTimeStr && <p>זמן הגעה כולל תנועה נוכחית: {trafficTimeStr}</p>}
            </div>}>
            <InfoCircleFilled style={{ fontSize: 21 }} />
          </Popover>
        </span>
      </div>
      <div id="map" style={{ width: "100%", height: "300px" }} />
      <div id="location" className="marker"><div className="pulse" /></div>
      <div id="weather"><img id="weatherImg" alt="מזג אוויר" /></div>
      <div id="highlight" className="highlight"></div>
      {data.length > 1 && <React.Fragment>
        <div className="section-header">
          <h2 style={{ display: 'inline-block', paddingLeft: 10 }}>
            <AreaChartOutlined /> גרף גובה
          </h2>
          <Popover placement="leftBottom" content={content}>
            <InfoCircleFilled style={{ fontSize: 21 }} />
          </Popover>
        </div>
        <Chart
          width={'100%'}
          height={'300px'}
          className="elev-chart"
          chartType="AreaChart"
          loader={<div>טוען נתוני גובה</div>}
          data={data}
          options={{
            title: null,
            legend: { position: 'none' },
            hAxis: { textPosition: 'none' },
            vAxis: { minValue: 0 },
            explorer: {},
            chartArea: { width: '85%', height: '100%', right: '0' },
            // lineWidth: 25
          }}
          chartEvents={[{
            eventName: "ready",
            callback: ({ chartWrapper, google }) => {
              this.chart = chartWrapper.getChart();
              google.visualization.events.addListener(
                this.chart,
                "onmouseover",
                (e) => {
                  const { row } = e;

                  const lat = data[row][0].split(',')[0];
                  const lon = data[row][0].split(',')[1];

                  this.highlight.setPosition(proj.fromLonLat([parseFloat(lon), parseFloat(lat)]));
                }
              );
            }
          },
          {
            eventName: "select",
            callback({ chartWrapper }) {
              console.log("Selected ", chartWrapper.getChart().getSelection());
            }
          }]} />
      </React.Fragment>}

      {!_.isEmpty(cams) && <div>
        <div className="section-header">
          <h2 className="road-header-title"><VideoCameraOutlined /> מצלמות</h2>
        </div>
        <div style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>

          {cams.map((cam, idx) => (<Card title={cam.title} key={idx}>
            <ReactPlayer url={cam.url} playing width="100%" height="auto"/>
          </Card>))}
        </div>
      </div>}
    </div>);
  }
};

export default Roads;

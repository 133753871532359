import React from 'react';
import _ from 'lodash';

const Stars = ({ count, size: width = 12, color = '#e6cf30' }) => (
    <span>(
        {_.range(count).map(i =>
            <div key={i} style={{ display: 'inline-block', width, color }}>
                <svg viewBox="0 0 473.486 473.486">
                    <polygon fill="currentColor" points="473.486,182.079 310.615,157.952 235.904,11.23 162.628,158.675 0,184.389 117.584,299.641 91.786,462.257 237.732,386.042 384.416,460.829 357.032,298.473 " />
                </svg>
            </div>
        )}
        )</span>);

export default Stars;
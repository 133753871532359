import React, { Component } from 'react';
import _ from 'lodash';
import { translateTozar } from '../helpers';
import Stars from './Stars';
import './CrashTest.scss';
import ListItem from './ListItem';
import axios from 'axios';

export default class CrashTest extends Component {
    constructor() {
        super();
        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');

        this.state = {
            debug,
            ncap: {},
            ncapLoading: true
        };
    }

    // resolver keys must be in lower case!!
    nameResolver = (name = '') => {
        const MODEL = name.toUpperCase();
        const { tozar: t, merkav } = this.props;
        const tozar = translateTozar(t);

        switch (tozar) {
            case 'MERCEDES-BENZ':
                let MERKAV = '';
                if (merkav === 'קבריולט') { MERKAV = 'CABRIOLET'; }
                if (merkav === 'קופה') { MERKAV = 'COUPE'; }

                // GT CLASS
                const firstLetters = MODEL.replace(/[0-9 ].*/, '');
                const exeptions = ['AMGGLA454MATI', 'S560E'];
                const exeption = MODEL.replace(/[ ]/g, '');
                const translator = {
                    'AMGGLA454MATI': 'AMG GLA45',
                    'S560E': 'S560E',
                    'GLC350E': 'GLC350E',
                    'GLC350E4MATIC': 'GLC350E',
                    'GLC350E4COUPE': 'GLC350E',
                };
                if (exeptions.includes(exeption)) return translator[exeption];

                return `${firstLetters}-CLASS ${MERKAV}`.trim();
            case 'LEXUS':
                return MODEL.replace('LEXUS', '').trim();
            default:
                return ({
                    'NEW BEETLE': 'beetle',
                    'M135I': '135I'
                })[MODEL] || MODEL;
        }
    }

    componentDidMount() {
        const { shnat_yitzur, tozar, degem } = this.props;
        const sy = shnat_yitzur;
        const t = translateTozar(tozar);
        const d = this.nameResolver(degem);

        axios.get(`/SafetyRatings/${sy}/${t}/${d}`)
            .then(({data}) => this.setState({ ncap: data.ncap, ncapLoading: false }));
    };

    render() {
        const { ncap, ncapLoading, debug } = this.state;
        const ncapOverall = {};
        const ncapFront = {};
        const ncapSide = {};
        const ncapSidePole = {};
        const ncapRollover = {};
        const overall = [
            'OverallRating',
            'VehiclePicture',
        ];
        const front = [
            'FrontCrashPicture',
            'FrontCrashDriversideRating',
            'FrontCrashPassengersideRating',
            'OverallFrontCrashRating',
            'FrontCrashVideo'
        ];
        const side = [
            'SideCrashPicture',
            'SideCrashDriversideRating',
            'SideCrashPassengersideRating',
            'OverallSideCrashRating',
            'SideCrashVideo'
        ];
        const sidePole = [
            'SidePolePicture',
            'SidePoleCrashRating',
            'SidePoleVideo'
        ];
        const rollover = [
            'RolloverPicture',
            'RolloverRating',
            'RolloverPossibility',
            'RolloverVideo'
        ];

 
        //  "combinedSideBarrierAndPoleRating-Front": "5",
        // "combinedSideBarrierAndPoleRating-Rear": "5",
        // "sideBarrierRating-Overall": "5",


        // "dynamicTipResult": "No Tip",
        // "NHTSAElectronicStabilityControl": "Standard",
        // "NHTSAForwardCollisionWarning": "Standard",
        // "NHTSALaneDepartureWarning": "Standard",
        // "ComplaintsCount": 170,
        // "RecallsCount": 1,
        // "InvestigationCount": 0,
 



        overall.map((i, key) => ncapOverall[i] = this.state.ncap[i]);
        front.map((i, key) => ncapFront[i] = this.state.ncap[i]);
        side.map((i, key) => ncapSide[i] = this.state.ncap[i]);
        sidePole.map((i, key) => ncapSidePole[i] = this.state.ncap[i]);
        rollover.map((i, key) => ncapRollover[i] = this.state.ncap[i]);

        const hasSomeOverallValue = !!_.map(ncapOverall, (i) => (i && i !== 'Not Rated')).filter(Boolean).length;
        const hasSomeFrontValue = !!_.map(ncapFront, (i) => (i && i !== 'Not Rated')).filter(Boolean).length;
        const hasSomeSideValue = !!_.map(ncapSide, (i) => (i && i !== 'Not Rated')).filter(Boolean).length;
        const hasSomeSidePoleValue = !!_.map(ncapSidePole, (i) => (i && i !== 'Not Rated')).filter(Boolean).length;
        const hasSomeRolloverValue = !!_.map(ncapRollover, (i) => (i && i !== 'Not Rated')).filter(Boolean).length;

        return (
            <div className="section ncap">
                {ncapLoading && <div>טוען מבחני ריסוק</div>}

                {!_.isEmpty(ncap) && (
                    <div style={{ marginTop: '40px' }}>
                        <ListItem value={this.state.ncap.RecallsCount} feat='כמות קריאות שרות' />
                        <ListItem value={this.state.ncap.ComplaintsCount} feat='כמות תלונות' />
                        <ListItem value={this.state.ncap.InvestigationCount} feat='כמות חקירות' />
                        {(
                            hasSomeOverallValue
                            || hasSomeFrontValue
                            || hasSomeSideValue
                            || hasSomeSidePoleValue
                            || hasSomeRolloverValue) && <h2>מבחני ריסוק</h2>}
                        {ncapOverall.OverallRating !== 'Not Rated' && <div>ציון כללי: {ncapOverall.OverallRating} <Stars count={ncapOverall.OverallRating} /></div>}
                        {ncapOverall.VehiclePicture && <div style={{ textAlign: 'center' }}>
                            <img style={{ maxWidth: '100%' }} src={ncapOverall.VehiclePicture.replace('http:', '')} alt={ncapOverall.VehicleDescription} />
                        </div>}

                        <div className="row">
                            {hasSomeFrontValue && <div className="half">
                                <h4>התנגשות קדמית</h4>
                                {ncap.FrontCrashPicture && <div><img src={ncap.FrontCrashPicture.replace('http:', '')} alt="תמונת התנגשות קדמית" className="ncapImage" /></div>}
                                {ncap.FrontCrashDriversideRating !== 'Not Rated' && <div><strong>הגנה על הנהג:</strong> {ncap.FrontCrashDriversideRating} <Stars count={ncap.FrontCrashDriversideRating} /></div>}
                                {ncap.FrontCrashPassengersideRating !== 'Not Rated' && <div><strong>הגנה על הנוסע:</strong> {ncap.FrontCrashPassengersideRating} <Stars count={ncap.FrontCrashPassengersideRating} /></div>}
                                {ncap.OverallFrontCrashRating !== 'Not Rated' && <div><strong>ניקוד כללי:</strong> {ncap.OverallFrontCrashRating} <Stars count={ncap.OverallFrontCrashRating} /></div>}
                                <div><a href={ncap.FrontCrashVideo}>סרטון</a></div>
                            </div>}

                            {hasSomeSideValue && <div className="half">
                                <h4>התנגשות מהצד</h4>
                                {ncapSide.SideCrashPicture && <div><img src={ncapSide.SideCrashPicture.replace('http:', '')} alt="תמונת התנגשות מהצד" className="ncapImage" /></div>}
                                {ncapSide.SideCrashDriversideRating !== 'Not Rated' && <div><strong>הגנה על צד הנהג:</strong> {ncapSide.SideCrashDriversideRating} <Stars count={ncapSide.SideCrashDriversideRating} /></div>}
                                {ncapSide.SideCrashPassengersideRating !== 'Not Rated' && <div><strong>הגנה על צד הנוסע:</strong> {ncapSide.SideCrashPassengersideRating} <Stars count={ncapSide.SideCrashPassengersideRating} /></div>}
                                {ncapSide.OverallSideCrashRating !== 'Not Rated' && <div><strong>ניקוד כללי:</strong> {ncapSide.OverallSideCrashRating} <Stars count={ncapSide.OverallSideCrashRating} /></div>}
                                <div><a href={ncapSide.SideCrashVideo}>סרטון</a></div>
                            </div>}
                        </div>
                        <hr />
                        <div className="row">
                            {hasSomeSidePoleValue && <div className="half">
                                <h4>התנגשות בעמוד</h4>
                                {ncapSidePole.SidePolePicture && <div><img src={ncapSidePole.SidePolePicture.replace('http:', '')} alt="תמונת התנגשות מהצד" className="ncapImage" /></div>}
                                {ncapSidePole.SidePoleCrashRating !== 'Not Rated' && <div><strong>ניקוד כללי:</strong> {ncapSidePole.SidePoleCrashRating} <Stars count={ncapSidePole.SidePoleCrashRating} /></div>}
                                <div><a href={ncapSidePole.SidePoleVideo}>סרטון</a></div>
                            </div>}

                            {hasSomeRolloverValue && <div className="half">
                                <h4>התהפכות</h4>
                                {ncapRollover.RolloverPicture && <div><img src={ncapRollover.RolloverPicture.replace('http:', '')} alt="תמונת התהפכות" className="ncapImage" /></div>}
                                {ncapRollover.RolloverRating !== 'Not Rated' && <div><strong>ניקוד כללי:</strong> {ncapRollover.RolloverRating} <Stars count={ncapRollover.RolloverRating} /></div>}
                                {ncapRollover.RolloverPossibility !== 'Not Rated' && <div><strong>הסתברות:</strong> {ncapRollover.RolloverPossibility * 100}%</div>}
                                {ncapRollover.RolloverVideo && <div><a href={ncapRollover.RolloverVideo}>סרטון</a></div>}
                            </div>}

                        </div>
                    </div>
                )
                }
                {debug === 'true' && <pre>nhtsa: {JSON.stringify(ncap, null, 2)}</pre>}
            </div >
        );
    }
};

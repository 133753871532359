import React, { useState, useRef } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import Button from 'antd/lib/button';
import {
    HISTORY_KEY
} from '../common/consts';
import './CarCompareSearchInput.scss';

const CarCompareSearchInput = React.forwardRef((props, ref) => {
    const [toCarA, setCarA] = useState(null);
    const [toCarB, setCarB] = useState(null);
    // const [showCamera, openCamera] = useState(false);
    const inputEl = ref ? ref : useRef(null);
    const inputEl2 = ref ? ref : useRef(null);

    const history2 = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');

 

    return (<React.Fragment>
        {(toCarA && toCarB) && <Redirect to={`/compare/${toCarA}/${toCarB}`} />}
        <h1>הכנס מספרי רכב להשוואה</h1>
        <div className="compareSearchInput">
            <input type="number"
                // list="history-numbers"
                size="large"
                ref={inputEl}
                pattern="\d*"
                defaultValue={props.carNumberA}
                onKeyUp={(e) => e.key === 'Enter' && setCarA(inputEl.current.value)} />

            <input type="number"
                // list="history-numbers"
                size="large"
                ref={inputEl2}
                pattern="\d*"
                defaultValue={props.carNumberB}
                onKeyUp={(e) => e.key === 'Enter' && setCarB(inputEl2.current.value)} />
            <Button size="large" type="primary" onClick={() => {
                setCarA(inputEl.current.value);
                setCarB(inputEl2.current.value);
             }}>
                השווה
            </Button>
        </div>
        <datalist id="history-numbers">
            {history2.map(i => <option key={i} value={i} />)}
        </datalist>



    </React.Fragment>);
});

export default CarCompareSearchInput;

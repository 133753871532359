import doralonImg from '../Delek/assets/doralon.png';
import sonolImg from '../Delek/assets/sonol.png';
import pazImg from '../Delek/assets/paz.png';
import delekImg from '../Delek/assets/delek.png';
import tapuzImg from '../Delek/assets/tapuz.png';

const STATIONS = {
    doralon: { title: 'דור אלון', color: '#007836', img: doralonImg },
    sonol: { title: 'סונול', color: '#c71217', img: sonolImg },
    paz: { title: 'פז', color: '#ffc82e', img: pazImg },
    delek: { title: 'דלק', color: '#fe000b', img: delekImg },
    tapuz: { title: 'תפוז', color: '#ed6a3b', img: tapuzImg }
};

const HISTORY_SIZE = 6;
const HISTORY_KEY = 'history-1';
const HISTORY_OFFSET_KEY = 'theory-offset-v2';

const CAMERAS_KEY = 'Aug_19';

const directionTranslation = {
    'מדרום': 'N',
    'ממערב': 'E',
    'מצפון': 'S',
    'ממזרח': 'W'
};
const typeTranslation = {
    'נתצ': 'orange',
    'רמזור': 'red',
    'מהירות': 'blue',
    'רכבת': '#334455'
};

export {
    STATIONS,
    HISTORY_SIZE,
    HISTORY_KEY,
    HISTORY_OFFSET_KEY,
    CAMERAS_KEY,
    directionTranslation,
    typeTranslation
};
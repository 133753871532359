import React from 'react';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Tooltip from 'antd/lib/tooltip';
import {
    translateKeys
} from '../helpers';
import './ListCompareItem.scss';

const ListCompareItem = ({
    func, winner, feat, translate, ltr, check,
    alertA, alertB,
    extraA, extraB,
    valueA, valueB,
    tooltipA, tooltipB,
    winnerA, winnerB,
}) => {
    if (!valueA && !valueB) return null;

    const key = translate ? translateKeys(feat) : feat;

    let wA = winnerA, wB = winnerB;

    if (winner && winner === "low") {
        wA = parseInt(valueA, 10) < parseInt(valueB, 10);
        wB = parseInt(valueA, 10) > parseInt(valueB, 10);
    }
    else if (winner && winner === "high") {
        wA = parseInt(valueA, 10) > parseInt(valueB, 10);
        wB = parseInt(valueA, 10) < parseInt(valueB, 10);
    }

    return (
        <tr className="compare-row">
            <td className="fds">{key && <strong>{key}:</strong>}</td>
            <td className="sdf" style={{ verticalAlign: 'top', color: alertA ? 'red' : '', ...(wA && !wB) ? { color: 'darkgreen', fontWeight: 'bold' } : {} }}>
                <span style={{ direction: ltr ? 'ltr' : 'rtl', alignItems: 'center' }}>
                    {
                        check
                            ? valueA === 1
                                ? <CheckOutlined />
                                : <CloseOutlined />
                            : func
                                ? func(valueA)
                                : valueA
                    }
                </span>
                {extraA && <span style={{ marginRight: 10 }}>
                    {extraA}
                </span>}
                {tooltipA && <span style={{ marginRight: 10 }}>
                    <Tooltip placement="topRight" title={tooltipA} style={{ marginRight: 10 }}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </span>}
            </td>
            <td className="sdf" style={{ verticalAlign: 'top', color: alertB ? 'red' : '', ...(!wA && wB) ? { color: 'darkgreen', fontWeight: 'bold' } : {} }}>
                <span style={{ direction: ltr ? 'ltr' : 'rtl', alignItems: 'center' }}>
                    {
                        check
                            ? valueB === 1
                                ? <CheckOutlined />
                                : <CloseOutlined />
                            : func
                                ? func(valueB)
                                : valueB
                    }
                </span>
                {extraB && <span style={{ marginRight: 10 }}>
                    {extraB}
                </span>}
                {tooltipB && <span style={{ marginRight: 10 }}>
                    <Tooltip placement="topRight" title={tooltipB}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </span>}
            </td>
        </tr >

    );
};

export default ListCompareItem;
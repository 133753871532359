import React, { Component } from 'react';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Map from "ol/Map.js";
import View from "ol/View.js";
import Overlay from "ol/Overlay.js";
import LayerTile from "ol/layer/Tile.js";
import SourceOSM from "ol/source/OSM.js";
import { Stroke, Style, Circle, Fill, Icon, Text } from 'ol/style';
import * as proj from 'ol/proj';

import Switch from 'antd/lib/switch';

import cameras from '../cameras-a3.json';
import { STATIONS, CAMERAS_KEY, directionTranslation, typeTranslation } from '../common/consts';

import './Delek.scss';

class Delek extends Component {
  constructor(props) {
    super(props);

    // create feature layer and vector source
    const featuresLayer = new VectorLayer({
      source: new VectorSource()
    });

    this.state = {
      loading: true,
      center: proj.fromLonLat([34.7744083, 32.0304332], 'EPSG:3857'),
      zoom: 14,
      featuresLayer,
      openHours: '247',
      stations: {
        paz: [], sonol: [], doralon: [], delek: [], tapuz: []
      },
      showCameras: false
    };


    this.map = new Map({
      target: null,
      layers: [new LayerTile({
        source: new SourceOSM()
      })],
      view: new View({
        center: this.state.center,
        zoom: this.state.zoom
      })
    });
  }

  componentDidMount() {
    this.getAllFuelStations();
  }


  getAllFuelStations = () => {
    const { openHours } = this.state;
    Promise.all([
      fetch(`/api/delek/paz?openhours=${openHours}`),
      fetch(`/api/delek/doralon?openhours=${openHours}`),
      fetch(`/api/delek/delek?openhours=${openHours}`),
      fetch(`/api/delek/sonol?openhours=${openHours}`),
      fetch(`/api/delek/tapuz?openhours=${openHours}`)
    ])
      .then(async ([
        pazStations,
        doralonStations,
        delekStations,
        sonolStations,
        tapuzStations
      ]) => {
        const paz = await pazStations.json();
        const doralon = await doralonStations.json();
        const delek = await delekStations.json();
        const sonol = await sonolStations.json();
        const tapuz = await tapuzStations.json();
        return [
          paz,
          doralon,
          delek,
          sonol,
          tapuz
        ]
      })
      .then((allStationsResult) => {
        this.setState({
          loading: false,
          stations: {
            paz: allStationsResult[0].stations,
            doralon: allStationsResult[1].stations,
            delek: allStationsResult[2].stations,
            sonol: allStationsResult[3].stations,
            tapuz: allStationsResult[4].stations
          }
        }, this.buildMap);
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    this.map.setTarget(null);
  }

  buildMap = () => {
    const {
      showCameras,
      stations: {
        doralon,
        delek,
        sonol,
        paz,
        tapuz
      }
    } = this.state;

    this.map.setTarget("map");

    const features = [
      ...doralon,
      ...delek,
      ...sonol,
      ...paz,
      ...tapuz
    ].map(station => {
      const feature = new Feature({
        sug: 'gas',
        geometry: new Point(proj.fromLonLat([station.location.longitude, station.location.latitude])),
        ...station
      });



      var style = new Style({
        image: new Icon({
          src: STATIONS[station.type].img
        })
      });

      feature.setStyle(style);

      return feature;
    });

    // create feature layer and vector source
    const featuresLayer = new VectorLayer({
      source: new VectorSource({ features })
    });
    this.map.addLayer(featuresLayer);

    const overlay = new Overlay({
      element: document.getElementById('popup'),
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    this.map.addOverlay(overlay);

    const camFeatures = cameras[CAMERAS_KEY].map(A3 => {
        const feature = new Feature({
            sug: 'camera',
            geometry: new Point(proj.fromLonLat([Number(A3.long), Number(A3.lat)])),
            ...A3
        });

        var style = new Style({
            image: new Circle({
                radius: 7,
                stroke: new Stroke({
                    color: '#fff'
                }),
                fill: new Fill({
                    color: (typeTranslation[A3.type] || '#000')
                })
            }),
            text: new Text({
                text: directionTranslation[A3.diraction],
                fill: new Fill({
                    color: '#fff'
                })
            })
        });
        feature.setStyle(style);
        return feature;
    });

    // create feature layer and vector source
    const camFeaturesLayer = new VectorLayer({
        name: 'cameras',
        source: new VectorSource({ features: camFeatures })
    });

    if (!showCameras) {
        this.map.getLayers().forEach((layer) => layer && layer.get('name') === 'cameras' && this.map.removeLayer(layer) )
    }
    else {
        this.map.addLayer(camFeaturesLayer);
    }





    // bind click on vector
    this.map.on('click', async (evt) => {
      const feature = this.map.getFeaturesAtPixel(evt.pixel);

      if (feature && feature[0].values_.sug === 'camera') {
        const cameraData = feature[0].values_;
        document.getElementById('popup-content').innerHTML = `
            <b>מצלמת ${cameraData.type}</b>
            <div>תחום: ${cameraData.area}</div>
            <div>כיוון: ${cameraData.diraction}</div>
        `;
        overlay.setPosition(evt.coordinate);
      }
      else if (feature) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            const { location: { latitude, longitude }, street, shortName, type, isFuelingStation24_7Open } = feature[0].values_;
            fetch(`/api/distance/${latitude}/${longitude}/${position.coords.latitude}/${position.coords.longitude}`)
              .then(r => r.json())
              .then(re => {
                document.getElementById('popup-content').innerHTML = `
                  <div><b style='color: ${STATIONS[type].color};'>${STATIONS[type].title} - ${shortName}</b></div>
                  <p>${street}</p>
                  <p>מרחק: ${re.distance} ק"מ</p>
                  <p>${isFuelingStation24_7Open ? 'תחנה פתוחה 24/7' : ''}</p>
                  <a href='https://maps.google.com/maps?daddr=${latitude},${longitude}&amp;ll=' class='ant-btn ant-btn-primary ant-btn-sm btn'>נווט לתחנה</a>
                `;
                overlay.setPosition(evt.coordinate);
              });
          });
        }

        // this.openNavigation(latitude, longitude);
      }
    });

    document.getElementById('popup-closer').onclick = function () {
      overlay.setPosition(undefined);
      document.getElementById('popup-closer').blur();
      return false;
    };

    this.setCurrentPosition();
  }

  setCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const center = proj.fromLonLat([position.coords.longitude, position.coords.latitude], 'EPSG:3857');
        this.map.getView().setCenter(center);

        const marker = new Overlay({
          position: center,
          element: document.getElementById('location'),
          positioning: 'center-center',
          stopEvent: false
        });

        this.map.addOverlay(marker);
      });
    }
  }

  handleChange = (checked) => {
    this.setState({
      openHours: (checked ? '247' : ''),
      loading: true
    }, this.getAllFuelStations);
  }

  // openNavigation = (lat, long) => {
  //   if /* if we're on iOS, open in Apple Maps */
  //     ((navigator.platform.indexOf("iPhone") !== -1) ||
  //     (navigator.platform.indexOf("iPad") !== -1) ||
  //     (navigator.platform.indexOf("iPod") !== -1))
  //     window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);

  //   else /* else use Google */
  //     window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
  // }

  render() {
    const {
      stations,
      loading,
      openHours,
      showCameras
    } = this.state;

    return (<div>
      <h1>תחנות דלק אוקטן 98</h1>

      {loading && (
        <div>טוען תחנות דלק, מכיוון שהפעולה דוגמת ברגעים אלו את אתרי התחנות הפעולה צפויה להיות איטית במעט...</div>)}

      {!loading && (<React.Fragment>
        <Switch checked={openHours === '247'} onChange={this.handleChange} /> הצג תחנות אשר פועלות 24/7 בלבד
        &nbsp; <Switch checked={showCameras} onChange={() => this.setState({showCameras: !showCameras}, () => this.buildMap())}/> {loading ? 'הסתר': 'הצג'} מצלמות
      </React.Fragment>)}

      <div id="map" style={{ width: "100%", height: "500px" }} />
      <div id="location" className="marker"><div className="pulse" /></div>
      <div id="popup" className="ol-popup">
        <span id="popup-closer" className="ol-popup-closer"></span>
        <div id="popup-content"></div>
      </div>

      {!loading && <footer>
        מוצגות התחנות הבאות:
        {[
          'paz',
          'delek',
          'sonol',
          'doralon',
          'tapuz'
        ].map(stationKey =>
          <b key={stationKey} style={{
            textDecoration: (stations[stationKey].length === 0) ? 'underline' : '',
            color: STATIONS[stationKey].color
          }}>
            {STATIONS[stationKey].title} ({stations[stationKey].length}),
            {' '}
          </b>
        )}
        {openHours === '247' && <div>התחנות המוצגות מספקות דלק 98 אוקטן ופעילות 24 שעות ביממה, 7 ימים בשבוע</div>}
      </footer>}
    </div>);
  }
};

export default Delek;

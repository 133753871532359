import React, { Component } from 'react';
import Button from 'antd/lib/button';
import {
    convertToDate
} from '../helpers';
import axios from 'axios';

export default class MobileyeCheck extends Component {
    static getDerivedStateFromProps(props, state) {
        if (props.carNumber !== state.carNumber) {
            return {
                carNumber: props.carNumber,
                loading: false,
                disc_date: 'prestine'
            };
        }

        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            disc_date: 'prestine'
        };
    }

    getData = () => {
        const { carNumber } = this.state;

        this.setState({ loading: true }, () => {
            axios.get(`/hanacha/${carNumber}`)
                .then(({ data: hanacha }) => this.setState({
                    loading: false,
                    disc_date: hanacha.disc_date && convertToDate(hanacha.disc_date)
                }))
                .catch((e) => this.setState({ loading: false, disc_date: null }));
        });
    };

    render() {
        const { disc_date, loading } = this.state;

        return (
            <div className="section mobileye" style={{ padding: '0px 10px' }}>
                <h2>זכאות להנחה לאחר התקנת מערכות בטיחות</h2>

                {disc_date === 'prestine'
                    ? (<Button type="primary" loading={loading} onClick={this.getData}>
                        בדוק זכאות
                    </Button>)
                    : (
                        loading
                            ? <div>בודק זכאות להנחה לאחר התקנת מערכת בטיחות....</div>
                            : (disc_date
                                ? (<div>
                                    על פי הנתונים הרכב ככל הנראה זכאי לקבלת ההטבה<br />
                                    <small>
                                        * בדיקת הזכאות מבוססת על נתוני משרד התחבורה והם באחריותו בלבד. התוצאות נכונות ליום {disc_date}<br />
                                        עפ"י נוהל 151 של משרד התחבורה:
                                        <i>"אגרת הרישוי השנתית לאחר מתן ההטבה לא תפחת מסך של 10₪ מדי שנה, כך שלגבי רכבים שאגרת הרישוי השנתית בגינם נמוכה מ-710₪, גובה ההטבה האפקטיבית יהיה נמוך מ-1500₪ במצטבר. לא תועבר יתרת הטבה משנה לשנה בכל מקרה"</i>.
                                    </small>
                                </div>)
                                : <div>רכב זה אינו זכאי להטבת משרד התחבורה.</div>)
                    )}
            </div>
        );
    }
};

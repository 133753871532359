import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
	parseCarNumber,
	translateSugDegem,
	getTooltipByMerkav,
	formatDate,
	shovyAgra
} from '../helpers';
// import {
// 	HISTORY_SIZE,
// 	HISTORY_KEY
// } from '../common/consts';
import { CarHands } from '../CarDetails';
import {
	CarCompareSearchInput,
	ListCompareItem,
	CrashTest,
} from '.';
import './CarCompareDetails.scss';
import Flag from '../CarDetails/Flag';

const apiCall = (endpoint) => axios.get(endpoint)
const num = (number, options = {}) => new Intl.NumberFormat('en-EN', options).format(number);

export default class CarCompareDetails extends Component {
	constructor(props) {
		super(props);

		const carNumberA = props.match.params.cara;
		const carNumberB = props.match.params.carb;

		this.initState = {
			carNumberA,
			carNumberB,
			carDataA: {},
			carDataB: {},
			pa: {},
			pb: {},
			ttla: null,
			ttlb: null,
			disc_date: null,
			loadingCarData: false,
			loadingCarPrice: false
		};

		this.state = {
			...this.initState
		};

		// this.input1 = React.createRef();
		// this.input2 = React.createRef();
	}

	componentDidMount() {
		this.state.carNumberA && this.state.carNumberB && this.handleChange(this.state.carNumberA, this.state.carNumberB);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.cara !== this.state.carNumberA
			|| props.match.params.carb !== this.state.carNumberB) {
			this.handleChange(props.match.params.cara, props.match.params.carb);
		}
	}

	getData = async (carNumberA, carNumberB) => {

		const p = { data: { data: [] } };
		const c = { data: { carData: {} } };
		let a = c, b = c, pa = p, pb = p, ttla = c, ttlb = c;

		[a, b] = await axios.all([
			apiCall(`/cardata/${carNumberA}`),
			apiCall(`/cardata/${carNumberB}`)
		]);

		[pa, pb] = await axios.all([
			apiCall(`/yevuan/${a.data.carData.tozeret_cd}/${a.data.carData.degem_cd}/${a.data.carData.shnat_yitzur}`),
			apiCall(`/yevuan/${b.data.carData.tozeret_cd}/${b.data.carData.degem_cd}/${b.data.carData.shnat_yitzur}`)
		]);

		[ttla, ttlb] = await axios.all([
			apiCall(`/carcount/${a.data.carData.degem_nm}`),
			apiCall(`/carcount/${b.data.carData.degem_nm}`)
		]);

		this.setState({
			carDataA: a.data.carData,
			carDataB: b.data.carData,
			pa: pa.data.yevuan || {},
			pb: pb.data.yevuan || {},
			ttla: ttla.data.total,
			ttlb: ttlb.data.total,
			loadingCarData: false,
		});

	}

	handleChange = (carNumberA, carNumberB) => {
		if (carNumberA && (carNumberA.length < 7 || carNumberA.length > 8)) return null;
		if (carNumberB && (carNumberB.length < 7 || carNumberB.length > 8)) return null;




		// window.document.title = `מספרכב - איתור מידע לרכב מספר ${CN}`;
		// window.dataLayer.push({ 'event': 'search', 'carNumber': CN });


		// const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
		// const historySet = new Set(history);

		// if (historySet.has(CN)) {
		//     historySet.delete(CN);
		// }

		// if (historySet.size === HISTORY_SIZE) {
		//     const first = [...historySet][0];
		//     const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
		//     delete history[first];
		//     localStorage.setItem(HISTORY_KEY, JSON.stringify(history));
		//     historySet.delete(first);
		// }

		// historySet.add(CN);
		// localStorage.setItem(HISTORY_KEY, JSON.stringify([...historySet]));

		this.setState({
			...this.initState,
			loadingCarData: true,
			loadingRecall: true,
			carNumberA,
			carNumberB,
		}, () => {
			this.getData(carNumberA, carNumberB)
		});
	}


	render() {
		const {
			loadingCarData,
			carNumberA,
			carNumberB,
			carDataA,
			carDataB,
			pa,
			pb,
			ttla,
			ttlb,
		} = this.state;

		const yA = carDataA.extra ? (new Date().getTime() - new Date(carDataA.extra.rishum_rishon_dt).getTime()) / (1000 * 3600 * 24 * 365) : 0;
		const xA = Math.round(yA * 10) / 10;

		const yB = carDataB.extra ? (new Date().getTime() - new Date(carDataB.extra.rishum_rishon_dt).getTime()) / (1000 * 3600 * 24 * 365) : 0;
		const xB = Math.round(yB * 10) / 10;

		const agraA = shovyAgra(carDataA.mishkal_kolel, carDataA.kvuzat_agra_cd, carDataA.shnat_yitzur);
		const agraB = shovyAgra(carDataB.mishkal_kolel, carDataB.kvuzat_agra_cd, carDataB.shnat_yitzur);

		const kmAvgA = carDataA.extra ? Math.round(carDataA.extra.kilometer_test_aharon / (new Date(carDataA.mivchan_acharon_dt).getFullYear() - carDataA.shnat_yitzur)) : null;
		const kmAvgB = carDataB.extra ? Math.round(carDataB.extra.kilometer_test_aharon / (new Date(carDataB.mivchan_acharon_dt).getFullYear() - carDataB.shnat_yitzur)) : null;

		const sameTokefAsLastTestA = new Date(carDataA.mivchan_acharon_dt).getTime() === new Date(carDataA.tokef_dt).getTime();
		const lateOnTestA = !sameTokefAsLastTestA && new Date().getTime() > new Date(carDataA.tokef_dt).getTime();
		const sameTokefAsLastTestB = new Date(carDataB.mivchan_acharon_dt).getTime() === new Date(carDataB.tokef_dt).getTime();
		const lateOnTestB = !sameTokefAsLastTestB && new Date().getTime() > new Date(carDataB.tokef_dt).getTime();


		return (
			<React.Fragment>
				<CarCompareSearchInput carNumberA={carNumberA} carNumberB={carNumberB} />
				{loadingCarData ? <div style={{ margin: '20px auto', padding: '0 10px', width: '90%' }}><h2>טוען רכבים להשוואה...</h2></div>
					: <div className="carDetails" style={{ padding: '0 10px', width: '100%' }}>
						<table border>
							{(_.isEmpty(carDataA) || _.isEmpty(carDataB)) && <ListCompareItem
								alertA={_.isEmpty(carDataA)}
								alertB={_.isEmpty(carDataB)}
								valueA={_.isEmpty(carDataA) ? `לא נמצא מידע על רכב שמספרו ${carNumberA}` : null}
								valueB={_.isEmpty(carDataB) ? `לא נמצא מידע על רכב שמספרו ${carNumberB}` : null}
							/>}
							<ListCompareItem
								valueA={<><Link to={`/car/${carNumberA}`}>{parseCarNumber(carNumberA)}</Link><br /><small>{carDataA.tozar} {carDataA.kinuy_mishari}</small></>}
								valueB={<><Link to={`/car/${carNumberB}`}>{parseCarNumber(carNumberB)}</Link><br /><small>{carDataB.tozar} {carDataB.kinuy_mishari}</small></>} />

							<tr className='compare-title'><td colSpan={3} style={{ paddingRight: 10 }}><h2>נתוני דגם</h2></td></tr>
							<ListCompareItem feat='סוג'
								valueA={translateSugDegem(carDataA.sug_degem)}
								valueB={translateSugDegem(carDataB.sug_degem)} />
							{(carDataA.tozar || carDataB.tozar) && <ListCompareItem feat='תוצרת'
								valueA={<>{carDataA.tozar}<div>
									(<Flag tozeret_eretz_nm={carDataA.tozeret_eretz_nm} />
									{' '}
									{carDataA.tozeret_eretz_nm})
								</div></>}
								valueB={<>{carDataB.tozar}<div>
									(<Flag tozeret_eretz_nm={carDataB.tozeret_eretz_nm} />
									{' '}
									{carDataB.tozeret_eretz_nm})
								</div></>} />}

							<ListCompareItem feat='דגם'
								valueA={carDataA.kinuy_mishari}
								valueB={carDataB.kinuy_mishari} />

							<ListCompareItem feat='מרכב'
								valueA={carDataA.merkav}
								tooltipA={getTooltipByMerkav(carDataA.merkav)}
								valueB={carDataB.merkav}
								tooltipB={getTooltipByMerkav(carDataB.merkav)} />

							<ListCompareItem feat='רמת גימור'
								valueA={carDataA.ramat_gimur}
								valueB={carDataB.ramat_gimur} />

							<ListCompareItem feat='שנת יצור'
								winner="high"
								valueA={carDataA.shnat_yitzur}
								valueB={carDataB.shnat_yitzur} />

							{carDataA.extra && carDataB.extra && <ListCompareItem feat='מועד עליה לכביש'
								valueA={`${formatDate(carDataA.extra.rishum_rishon_dt)} (${xA})`}
								valueB={`${formatDate(carDataB.extra.rishum_rishon_dt)} (${xB})`} />}

							<ListCompareItem feat='תאריך טסט אחרון'
								winner="high"
								func={(data) => formatDate(data)}
								valueA={carDataA.mivchan_acharon_dt}
								valueB={carDataB.mivchan_acharon_dt} />

							{carDataA.extra && carDataB.extra && <ListCompareItem feat="קילומטרז' בטסט"
								winnerA={kmAvgA && kmAvgB && kmAvgA < kmAvgB}
								winnerB={kmAvgA && kmAvgB && kmAvgA > kmAvgB}
								alertA={kmAvgA > 20000}
								alertB={kmAvgB > 20000}
								valueA={carDataA.extra.kilometer_test_aharon ? num(carDataA.extra.kilometer_test_aharon) : ' -- '}
								tooltipA={carDataA.extra.kilometer_test_aharon ? <>{`ממוצע שנתי: ${num(kmAvgA)} ק"מ, `}<br />
									{`ממוצע ארצי: 20,000 ק"מ`}</> : null}
								valueB={carDataB.extra.kilometer_test_aharon ? num(carDataB.extra.kilometer_test_aharon) : ' -- '}
								tooltipB={carDataB.extra.kilometer_test_aharon ? <>{`ממוצע שנתי: ${num(kmAvgB)} ק"מ, `}<br />
									{`ממוצע ארצי: 20,000 ק"מ`}</> : null}
							/>}

							<ListCompareItem feat='תוקף רשיון'
								alertA={lateOnTestA}
								alertB={lateOnTestB}
								winnerA={carDataA.tokef_dt && carDataB.tokef_dt && new Date(carDataA.tokef_dt).getTime() > new Date(carDataB.tokef_dt).getTime()}
								winnerB={carDataA.tokef_dt && carDataB.tokef_dt && new Date(carDataA.tokef_dt).getTime() < new Date(carDataB.tokef_dt).getTime()}
								valueA={formatDate(carDataA.tokef_dt)}
								valueB={formatDate(carDataB.tokef_dt)} />

							<ListCompareItem feat='קבוצת רישוי'
								valueA={carDataA.kvuzat_agra_cd}
								valueB={carDataB.kvuzat_agra_cd} />

							<ListCompareItem feat='אגרת רישוי'
								winner="low"
								func={agra => `₪${agra}`}
								valueA={agraA}
								valueB={agraB} />

							<ListCompareItem feat='בעלות'
								winnerA={carDataA.baalut === 'פרטי' && carDataB.baalut !== 'פרטי'}
								winnerB={carDataB.baalut === 'פרטי' && carDataA.baalut !== 'פרטי'}
								valueA={carDataA.baalut}
								valueB={carDataB.baalut} />

							<ListCompareItem feat='צבע הרכב'
								valueA={carDataA.tzeva_rechev !== 'לא ידוע' ? carDataA.tzeva_rechev : ' -- '}
								valueB={carDataB.tzeva_rechev !== 'לא ידוע' ? carDataB.tzeva_rechev : ' -- '} />

							<ListCompareItem feat='מידת צמיגים'
								valueA={carDataA.zmig_kidmi === carDataA.zmig_ahori ?
									carDataA.zmig_kidmi :
									<>
										<div>ק'{carDataA.zmig_kidmi}</div>
										<div>א'{carDataA.zmig_ahori}</div>
									</>
								}
								tooltipA={carDataA.extra ? carDataA.extra.shinui_zmig_ind === 1 && "צמיג שונה מהמקור" : null}
								valueB={
									carDataB.zmig_kidmi === carDataB.zmig_ahori ?
										carDataB.zmig_kidmi :
										<>
											<div>ק'{carDataB.zmig_kidmi}</div>
											<div>א'{carDataB.zmig_ahori}</div>
										</>
								}
								tooltipB={carDataB.extra ? carDataB.extra.shinui_zmig_ind === 1 && "צמיג שונה מהמקור" : null} />

							<ListCompareItem feat='כמות רכבים על הכביש'
								func={(data) => (data || 0).toLocaleString()}
								winner="low"
								valueA={ttla}
								tooltipA={`מציג מידע על רכבים שעלו לכביש משנת 2018 ועד החודש הקלנדרי הקודם לדגם ${carDataA.degem_nm}`}
								valueB={ttlb}
								tooltipB={`מציג מידע על רכבים שעלו לכביש משנת 2018 ועד החודש הקלנדרי הקודם לדגם ${carDataB.degem_nm}`}
							/>

							{((carDataA.tozeret_cd && carDataA.degem_cd) ||
								(carDataB.tozeret_cd && carDataB.degem_cd)) &&
								<ListCompareItem feat='מחיר מחירון'
									winner="low"
									func={(data) => (data || 0).toLocaleString('he-IL', {
										style: 'currency',
										currency: 'ILS',
									})
									}
									valueA={pa.mehir}
									valueB={pb.mehir}
								/>
							}

							<tr className='compare-title'><td colSpan={3} style={{ paddingRight: 10 }}><h2>אביזרים ומערכות</h2></td></tr>

							<ListCompareItem feat='טכנולוגיית הנעה'
								valueA={carDataA.technologiat_hanaa_nm}
								valueB={carDataB.technologiat_hanaa_nm} />

							<ListCompareItem feat='תיבת הילוכים'
								valueA={
									(carDataA.automatic_ind === 0 || carDataA.automatic_ind === 1)
										? parseInt(carDataA.automatic_ind) === 1 ? 'אוטומטית' : 'ידנית'
										: ' -- '
								}
								valueB={(carDataB.automatic_ind === 0 || carDataB.automatic_ind === 1)
									? parseInt(carDataB.automatic_ind) === 1 ? 'אוטומטית' : 'ידנית'
									: ' -- '} />

							{['bakarat_yatzivut_ind', 'abs_ind', 'hege_koah_ind', 'halon_bagg_ind', 'mazgan_ind', 'argaz_ind', 'galgaley_sagsoget_kala_ind']
								.map(k =>
									<ListCompareItem key={k} feat={k} translate check
										winner="high"
										valueA={parseInt(carDataA[k], 10)}
										valueB={parseInt(carDataB[k], 10)}
									/>)}

							<tr className='compare-title'><td colSpan={3} style={{ paddingRight: 10 }}><h2>מפרט טכני</h2></td></tr>

							<ListCompareItem feat='דגם מנוע'
								valueA={`${carDataA.degem_manoa} (${carDataA.extra ? carDataA.extra.mispar_manoa || '' : ''})`}
								valueB={`${carDataB.degem_manoa} (${carDataB.extra ? carDataB.extra.mispar_manoa || '' : ''})`} />


							{['nefah_manoa', 'koah_sus', 'mishkal_kolel', 'gova', 'delek_nm', 'hanaa_nm', 'kosher_grira_im_blamim', 'kosher_grira_bli_blamim', 'mispar_moshavim', 'mispar_dlatot', 'mispar_halonot_hashmal', 'mispar_kariot_avir']
								.map(k =>
									<ListCompareItem key={k} feat={k} translate
										winner={k === 'mishkal_kolel' ? "low" : "high"}
										valueA={carDataA[k]}
										valueB={carDataB[k]} />)}

							<ListCompareItem feat='סוג ממיר'
								valueA={carDataA.sug_mamir_cd !== 0 ? carDataA.sug_mamir_nm : ' -- '}
								valueB={carDataB.sug_mamir_cd !== 0 ? carDataB.sug_mamir_nm : ' -- '}
							/>

							<tr className='compare-title'><td colSpan={3} style={{ paddingRight: 10 }}><h2>מערכות בטיחות</h2></td></tr>

							<ListCompareItem feat='רמת אבזור בטיחותי'
								winnerA={carDataA.ramat_eivzur_betihuty > carDataB.ramat_eivzur_betihuty}
								winnerB={carDataB.ramat_eivzur_betihuty > carDataA.ramat_eivzur_betihuty}
								valueA={<span className={`sfBox sfType${carDataA.ramat_eivzur_betihuty}`}>{carDataA.ramat_eivzur_betihuty}</span>}
								valueB={<span className={`sfBox sfType${carDataB.ramat_eivzur_betihuty}`}>{carDataB.ramat_eivzur_betihuty}</span>}
							/>

							<ListCompareItem feat='ניקוד בטיחות'
								winner="high"
								valueA={carDataA.nikud_betihut}
								valueB={carDataB.nikud_betihut}
							/>

							{[
								'teura_automatit_benesiya_kadima_ind',
								'shlita_automatit_beorot_gvohim_ind',
								'zihuy_holchey_regel_ind',
								'zihuy_beshetah_nistar_ind',
								'zihuy_tamrurey_tnua_ind',
								'zihuy_matzav_hitkarvut_mesukenet_ind',
								'bakarat_stiya_menativ_ind',
								'bakarat_shyut_adaptivit_ind',
								'maarechet_ezer_labalam_ind',
								'matzlemat_reverse_ind',
								'hayshaney_lahatz_avir_batzmigim_ind',
								'hayshaney_hagorot_ind',
								'nitur_merhak_milfanim_ind',
							].map(item => <ListCompareItem key={item} feat={item} translate check
								winner="high"

								valueA={parseInt(carDataA[item], 10)}
								extraA={carDataA[item.replace('_ind', '_makor_hatkana')] ? `מקור: ${carDataA[item.replace('_ind', '_makor_hatkana')]}` : null}

								valueB={parseInt(carDataB[item], 10)}
								extraB={carDataB[item.replace('_ind', '_makor_hatkana')] ? `מקור: ${carDataB[item.replace('_ind', '_makor_hatkana')]}` : null}

							/>)}

							{['bakarat_stiya_activ_s',
								'blima_otomatit_nesia_leahor',
								'bakarat_mehirut_isa',
								'zihuy_rechev_do_galgali',
							].map(item => <ListCompareItem key={item} feat={item} translate check
								// winnerA={parseInt(carDataA[item], 10) > parseInt(carDataB[item], 10)}
								// winnerB={parseInt(carDataB[item], 10) > parseInt(carDataA[item], 10)}
								winner="high"
								valueA={parseInt(carDataA[item], 10)}
								valueB={parseInt(carDataB[item], 10)}
							/>)}

							<tr className='compare-title'><td colSpan={3} style={{ paddingRight: 10 }}><h2>נתוני פליטות מזהמים</h2></td></tr>

							{/* {kvutzat_zihum && <li><strong>קבוצת זיהום:</strong>  <span className={`apBox apTypes${kvutzat_zihum}`}>{kvutzat_zihum}</span></li>} */}
							<ListCompareItem feat='קבוצת זיהום'
								valueA={<span className={`apBox apTypes${carDataA.kvutzat_zihum}`}>{carDataA.kvutzat_zihum}</span>}
								valueB={<span className={`apBox apTypes${carDataB.kvutzat_zihum}`}>{carDataB.kvutzat_zihum}</span>} />
							<ListCompareItem feat='ציון ירוק'
								winner="low"
								valueA={carDataA.madad_yarok}
								valueB={carDataB.madad_yarok} />
							<ListCompareItem feat='כמות CO'
								winner="low"
								valueA={Number.parseFloat(carDataA.kamut_CO)}
								valueB={Number.parseFloat(carDataB.kamut_CO)} />
							<ListCompareItem feat='כמות CO2'
								winner="low"
								valueA={Number.parseFloat(carDataA.kamut_CO2)}
								valueB={Number.parseFloat(carDataB.kamut_CO2)} />
							<ListCompareItem feat='כמות NOX'
								winner="low"
								valueA={Number.parseFloat(carDataA.kamut_NOX)}
								valueB={Number.parseFloat(carDataB.kamut_NOX)} />
							<ListCompareItem feat='כמות HC+NOX'
								winner="low"
								valueA={carDataA.kamut_HC_NOX}
								valueB={carDataB.kamut_HC_NOX} />
							<ListCompareItem feat='כמות HC'
								winner="low"
								valueA={Number.parseFloat(carDataA.kamut_HC)}
								valueB={Number.parseFloat(carDataB.kamut_HC)} />
							<ListCompareItem feat='כמות PM10'
								winner="low"
								valueA={carDataA.kamut_PM10}
								valueB={carDataB.kamut_PM10} />

							<tr className='compare-title'><td colSpan={3} style={{ paddingRight: 10 }}><h2>היסטוריית בעלויות</h2></td></tr>
							<ListCompareItem
								valueA={<CarHands title={undefined} carNumber={carNumberA} />}
								valueB={<CarHands title={undefined} carNumber={carNumberB} />} />


							<CrashTest
								shnat_yitzurA={carDataA.shnat_yitzur}
								tozarA={carDataA.tozar}
								degemA={carDataA.kinuy_mishari}
								merkavA={carDataA.merkav}
								shnat_yitzurB={carDataB.shnat_yitzur}
								tozarB={carDataB.tozar}
								degemB={carDataB.kinuy_mishari}
								merkavB={carDataB.merkav} />
						</table>
					</div>}


				<footer>ט.ל.ח - המידע המוצג הינו מובא ישירות מאתר משרד התחבורה</footer>
			</React.Fragment>
		);
	}
};

import React from 'react';
import {
    formatDate,
    parseCarNumber,
} from '../helpers';
import Flag from './Flag';
import { ListItem } from './';

// import img from './assets/vin-decoder-full-1.jpg';
// import './SelfImport.scss';

function SelfImport(props) {
    const {
        mispar_rechev,
        shilda,
        tozeret_nm,
        sug_rechev_nm,
        degem_nm,
        mishkal_kolel,
        shnat_yitzur,
        nefach_manoa,
        tozeret_eretz_nm,
        degem_manoa,
        mivchan_acharon_dt,
        tokef_dt,
        sug_yevu,
        moed_aliya_lakvish,
        sug_delek_nm
    } = props.carData;

    const yearsOnRoad = new Date().getFullYear() - new Date(`${moed_aliya_lakvish}-1`).getFullYear();
    const yearsOnRoadRounded = Math.round(yearsOnRoad * 10) / 10;

    return (<React.Fragment>
        <div style={{ padding: '0 10px' }}>
            <h2>רכב ביבוא אישי</h2>
            <ul>
                <ListItem feat='סוג יבוא' value={sug_yevu} />
                <ListItem feat='מספר רכב' value={parseCarNumber(mispar_rechev)} />
                {tozeret_nm && <ListItem feat={null} value={`תוצרת ${tozeret_nm}`} extra={
                    <>
                        (<Flag tozeret_eretz_nm={tozeret_eretz_nm} />
                        {' '}
                        {tozeret_eretz_nm})
                    </>
                } />}
                <ListItem copy={`${degem_nm} `} feat='דגם' value={degem_nm} extra={<a href={`https://www.google.com/search?q=${degem_nm}`} target="_blank" rel="noopener noreferrer">({degem_nm})</a>} />

                <ListItem copy feat='שלדה'
                    value={shilda}
                    extra={<>
                        {/* <a href={`https://7zap.com${vinUrl}`} target="_blank" rel="noopener noreferrer">
                            {vinUrl !== '/'
                                ? <><img src="https://7zap.com/favicon-7Zap.ico" alt="7zap" width="16" height="16" /> - קטלוג חלפים</>
                                : <><img src="https://7zap.com/favicon-7Zap.ico" alt="7zap" width="16" height="16" /> - חיפוש קטלוג חלפים</>}
                        </a> */}
                        {shilda !== '' && <>
                            <a href={`https://7zap.com/en/search/${shilda}/`} target="_blank" rel="noopener noreferrer"><img src="https://7zap.com/favicon-7Zap.ico" alt="7zap" width="16" height="16" /></a>
                            {' | '}
                            <a href={`https://partsouq.com/en/search/all?q=${shilda}`} target="_blank" rel="noopener noreferrer"><img src="https://partsouq.com/favicon.ico" alt="partsouq" width="16" height="16" /></a>
                        </>}
                    </>} />
                <ListItem feat='רכב' value={sug_rechev_nm} />
                <ListItem feat='משקל כולל' value={mishkal_kolel} />
                <ListItem feat='שנת יצור' value={shnat_yitzur} />
                <ListItem feat='נפח מנוע' value={nefach_manoa} />
                <ListItem copy={degem_manoa} value={null} feat='דגם מנוע' extra={<a href={`https://www.google.com/search?q=${degem_manoa}+engine`} target="_blank" rel="noopener noreferrer">{degem_manoa}</a>} />
                <ListItem feat='סוג דלק' value={sug_delek_nm} />
                <ListItem feat='מועד עליה לכביש' value={`${formatDate(moed_aliya_lakvish)} (${yearsOnRoadRounded})`} />
                <ListItem feat='תאריך טסט אחרון' value={formatDate(mivchan_acharon_dt)} />
                <ListItem feat='תוקף רשיון' value={formatDate(tokef_dt)} />
            </ul>

            {/* <a href="https://driving-tests.org/vin-decoder/"><img src={img} style={{width: '100%'}}/></a> */}
        </div>
    </React.Fragment>);
};

export default SelfImport;

// https://en.wikibooks.org/wiki/Vehicle_Identification_Numbers_(VIN_codes)/World_Manufacturer_Identifier_(WMI)
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { ListItem } from './';
import './Betihut.scss';

function Betihut(props) {
    const { ramat_eivzur_betihuty, nikud_betihut } = props.carData;
    const y = {};
    const z = [
        'teura_automatit_benesiya_kadima_ind',
        'shlita_automatit_beorot_gvohim_ind',
        'zihuy_holchey_regel_ind',
        'zihuy_beshetah_nistar_ind',
        'zihuy_tamrurey_tnua_ind',
        'zihuy_matzav_hitkarvut_mesukenet_ind',
        'bakarat_stiya_menativ_ind',
        'bakarat_shyut_adaptivit_ind',
        'maarechet_ezer_labalam_ind',
        'matzlemat_reverse_ind',
        'hayshaney_lahatz_avir_batzmigim_ind',
        'hayshaney_hagorot_ind',
        'nitur_merhak_milfanim_ind',
    ];
    const additionalZ = [
        'bakarat_stiya_activ_s',
        'blima_otomatit_nesia_leahor',
        'bakarat_mehirut_isa',
        'zihuy_rechev_do_galgali',
        'blimat_hirum_lifnei_holhei_regel_ofanaim',
        'hitnagshut_cad_shetah_met',
        'alco_lock'
    ];

    [...z, ...additionalZ].map((i, key) => y[i] = props.carData[i]);

    const hasSomeValue = !!_.map(y, (i) => parseInt(i) === 1).filter(Boolean).length || ramat_eivzur_betihuty || nikud_betihut;
    const classs = (number) => classnames(`sfBox sfType${number}`, { sfSelected: ramat_eivzur_betihuty == number });

    return (
        <div className="main section Betihut" style={{ padding: '0 10px' }}>
            {hasSomeValue &&
                <div>
                    <h2>מערכות בטיחות</h2>
                    <ul>
                        {ramat_eivzur_betihuty && <li>רמת אבזור בטיחותי:
                            <span className={`sfBox sfType${ramat_eivzur_betihuty}`}>{ramat_eivzur_betihuty}</span></li>}
                        {nikud_betihut && <ListItem feat='ניקוד בטיחות' value={nikud_betihut} />}

                        {z.map(item => <ListItem key={item} checked checkedValue={parseInt(props.carData[item])} translate feat={item} value={item} data-install={props.carData[item.replace('_ind', '_makor_hatkana')]} />)}
                        
                        {additionalZ.map(item => <ListItem key={item} checked checkedValue={parseInt(props.carData[item])} translate feat={item} value={item}  />)}
                    </ul>

                    {ramat_eivzur_betihuty && <div className="sfScale">
                        <div className="sfHigh">בטיחות גבוהה</div>
                        {_.reverse(_.range(9)).map(number =>
                            <span key={number} className={classs(number)}>{number}</span>)}
                        <div className="sfLow">בטיחות נמוכה</div>
                    </div>}
                </div>}
        </div>
    );
};

export default Betihut;
import React, { useEffect, useState, useCallback, createRef } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Space from 'antd/lib/space';
import { HISTORY_OFFSET_KEY } from '../common/consts';
import { Timer } from '.';

import './style.scss';

const numOfQuestions = 30;

const TheoryQuestions = (props) => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [asnwers, setAnswers] = useState({});

    const timer = createRef();

    const offset = props.match.params.start || localStorage.getItem(HISTORY_OFFSET_KEY) || 0;

    const getQuestions = useCallback(() => {
        setLoading(true);
        fetch(`/api/theory?all=true&numOfQ=${numOfQuestions}&offset=${offset}`)
            .then(res => res.json())
            .then(data => {
                setLoading(false);
                data.theoryQuestions && setQuestions(data.theoryQuestions);
                data.total && setTotal(data.total);
            })
            .catch(() => setLoading(false));
    },
        [offset]);

    useEffect(() => {
        getQuestions();
    }, []);

    const clickHandler = (e) => {
        timer.current.start();

        if (e.target && e.target.nodeName !== 'SPAN' && e.target.nodeName !== 'LI') { return; }

        const parent = e.target.closest('li').parentElement;

        parent.querySelectorAll('li').forEach((node) => {
            node.style.listStyle = 'circle';
            node.style.fontWeight = 'normal';
        });

        // e.target.closest('li').style.color = 'red';
        e.target.closest('li').style.listStyle = 'disc';
        e.target.closest('li').style.fontWeight = 'bold';

        // parent.parentElement.querySelector('button').style.display = 'inline-block';

        const q = e.target.closest('div').parentElement.parentElement.querySelector('strong').innerText.substring(0, 4);

        let cAns = '';
        if (e.target.nodeName === 'SPAN') {
            cAns = e.target.closest('span').getAttribute('id');
        }
        else {
            cAns = e.target.querySelector('span').getAttribute('id');
        }

        const correctAnswer = (cAns || '').startsWith('correctAnswer');
        setAnswers({ ...asnwers, [`answer_${q}`]: correctAnswer });
    }

    const createMarkup = (__html) => ({ onClick: clickHandler, className: 'desc', dangerouslySetInnerHTML: { __html } });
    const nextOffset = parseInt(offset, 10) + parseInt(numOfQuestions, 10);

    const answerCountObj = _.countBy(asnwers);
    const allAnswerd = (answerCountObj.true + answerCountObj.false) === numOfQuestions;
    const successCount = answerCountObj.true || 0;

    return (<>
        <div style={{ position: 'sticky', top: 0, backgroundColor: 'ghostwhite', padding: '10px 30px', zIndex: 1 }}>
            <div>זמן שנותר: <Timer minutes={40} ref={timer} /></div>
            {allAnswerd && <>
                <div>תשובות נכונות: {successCount}</div>
                {successCount >= (numOfQuestions - 4) && <div>הצלחת לענות על {successCount} שאלות, עברת בהצלחה את הבחינה.</div>}
            </>}
        </div>

        <Space className={classnames('theory-questions', { 'all-answerd': allAnswerd })} direction="vertical" size="large">
            <h1>שאלות תאוריה<small>מאגר של {total} שאלות</small></h1>
            {loading
                ? <>טוען שאלות תאוריה</>
                : <>
                    <div>
                        במבחן התאוריה תתבקשו לענות על 30 שאלות.<br />
                        השאלות הן שאלות "אמריקאיות", כלומר שאלות רב-ברירתיות, כאשר עליכם לבחור תשובה אחת נכונה מתוך ארבע תשובות אפשריות.<br />
                        משך המבחן הוא 40 דקות. עליכם לענות נכונה על לפחות 26 שאלות על מנת לעבור את הבחינה.<br />
                        כלומר, ניתן לשגות בארבע שאלות לכל היותר.<br />
                        בסיום המענה על השאלות תוכלו לבדוק את התשובות הנכונות עבור כל שאלה.
                    </div>

                    {questions.map((question) => <div key={question._id}>
                        {question.category && <h2 className="title">שאלה בנושא {question.category}</h2>}

                        <strong>{question.title2}</strong>
                        <div {...createMarkup(question.description4)} />
                    </div>)}

                    <hr />

                    {nextOffset <= total
                        ? <button className="secondary" onClick={() => {
                            window.location.href = `/theory/${nextOffset}`;
                            localStorage.setItem(HISTORY_OFFSET_KEY, nextOffset);
                        }}>הצג {numOfQuestions} שאלות נוספות</button>
                        : <div>כל הכבוד, לא נותרו שאלות יותר</div>}
                </>}
        </Space>
    </>);
}

export default TheoryQuestions;

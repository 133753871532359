import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { ListItem } from './';

import './Zihum.scss';

function Zihum(props) {
    //   "kamut_CO2": "112",
    //   "kamut_NOX": "3.0999999493360519E-2",
    //   "kamut_PM10": "",
    //   "kamut_HC": "5.0999999046325684E-2",
    //   "kamut_HC_NOX": "",
    //   "kamut_CO": "0.18199999630451202",
    //   "kamut_CO2_city": "",
    //   "kamut_NOX_city": "",
    //   "kamut_PM10_city": "",
    //   "kamut_HC_city": "",
    //   "kamut_CO_city": "",
    //   "kamut_CO2_hway": "",
    //   "kamut_NOX_hway": "",
    //   "kamut_PM10_hway": "",
    //   "kamut_HC_hway": "",
    //   "kamut_CO_hway": "",

    const {
        madad_yarok,
        kvutzat_zihum,
        kamut_CO,
        kamut_CO2,
        kamut_NOX,
        kamut_HC_NOX,
        kamut_HC,
        kamut_PM10
    } = props.carData;

    return (<div className='section'>
        {_.some([madad_yarok, kvutzat_zihum, kamut_CO2, kamut_CO]) && <div style={{ padding: '0 10px' }}>
            <h2>נתוני פליטות מזהמים</h2>
            <ul>
                {kvutzat_zihum && <li><strong>קבוצת זיהום:</strong>  <span className={`apBox apTypes${kvutzat_zihum}`}>{kvutzat_zihum}</span></li>}
                {madad_yarok && <ListItem feat='ציון ירוק' value={madad_yarok} />}
                {kamut_CO && <ListItem feat='כמות CO' value={Number.parseFloat(kamut_CO)} />}
                {kamut_CO2 && <ListItem feat='כמות CO2' value={Number.parseFloat(kamut_CO2)} />}
                {kamut_NOX && <ListItem feat='כמות NOX' value={Number.parseFloat(kamut_NOX)} />}
                {kamut_HC_NOX && <ListItem feat='כמות HC+NOX' value={kamut_HC_NOX} />}
                {kamut_HC && <ListItem feat='כמות HC' value={Number.parseFloat(kamut_HC)} />}
                {kamut_PM10 && <ListItem feat='כמות PM10' value={kamut_PM10} />}
            </ul>

            {kvutzat_zihum && <div className="apScale">
                <span className="apMax">זיהום מירבי</span>
                {_.reverse(_.range(1, 16)).map(number => <span key={number} className={classnames(`apBox apTypes${number}`, { apSelected: kvutzat_zihum == number })}>{number}</span>)}
                <span className="apMin">זיהום מזערי</span>
            </div>}
        </div>}
    </div>);
};

export default Zihum;
import React from 'react';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import Table from 'antd/lib/table';

import './style.scss';

const { Title } = Typography;

const radio = {
    columns: [
        {
            title: 'ערוץ',
            dataIndex: 'channel',
            key: 'channel',
            align: 'right'
        }, {
            title: 'תדר FRS אמריקאי',
            dataIndex: 'hrzus',
            key: 'hrzus',
            align: 'right'
        },
        {
            title: 'תדר FRS אירופאי',
            dataIndex: 'hrzeu',
            key: 'hrzeu',
            align: 'right'
        }
    ],
    data: [
        { channel: '1', hrzus: '462.5625', hrzeu: '446.00625', key: '1', },
        { channel: '2', hrzus: '462.5875', hrzeu: '446.01875', key: '2', },
        { channel: '3', hrzus: '462.6125', hrzeu: '446.03125', key: '3', },
        { channel: '4', hrzus: '462.6375', hrzeu: '446.04375', key: '4', },
        { channel: '5', hrzus: '462.6625', hrzeu: '446.05625', key: '5', },
        { channel: '6', hrzus: '462.6875', hrzeu: '446.06875', key: '6', },
        { channel: '7', hrzus: '462.7125', hrzeu: '446.08125', key: '7', },
        { channel: '8', hrzus: '467.5625', hrzeu: '446.09375', key: '8', },
        { channel: '9', hrzus: '467.5875', key: '9', },
        { channel: '10', hrzus: '467.6125', key: '10', },
        { channel: '11', hrzus: '467.6375', key: '11', },
        { channel: '12', hrzus: '467.6625', key: '12', },
        { channel: '13', hrzus: '467.6875', key: '13', },
        { channel: '14', hrzus: '467.7125', key: '14', }
    ]
};


const manual = {
    columns: [
        {
            title: 'פונקציה',
            dataIndex: 'function',
            key: 'function',
            align: 'right'
        }, {
            title: 'שם',
            dataIndex: 'funcname',
            key: 'funcname',
            align: 'right'
        },
        {
            title: 'אפשרויות בחירה',
            dataIndex: 'data',
            key: 'data',
            align: 'right'
        },
        {
            title: 'הסבר',
            dataIndex: 'explanation',
            key: 'explanation',
            align: 'right'
        }
    ],
    data: [
        { function: '0', funcname: 'SQL', data: '0-9', explanation: 'כמות הרחש שהמקלט קולט ומשמיע כמסר נכנס. אם יש פרצי שידור של רחש לא מוסבר צריך לרדת במספרים', key: '0', },
        { function: '1', funcname: 'STEP', data: '2.5 / 5 / 6.25 / 10 / 12.5 / 25 Khz', explanation: 'בחצים שימוש בעת התדרים קפיצות', key: '1', },
        { function: '2', funcname: 'TXP', data: 'HIGH / MID / LOW', explanation: 'עוצמת שידור.\n LOW-נמוך \n HIGH-גבוה ', key: '2', },
        { function: '3', funcname: 'SAVE', data: 'OFF / 1-4', explanation: 'הפעלה קולית.\n הפעלת שידור ע"י דיבור ללא לחיצה על כפתור ה PTT \n 1-מאוד רגיש \n 4 -הכי פחות רגיש', key: '3', },
        { function: '4', funcname: 'VOX', data: 'OFF / 1-10', explanation: 'כמות הרחש שהמקלט קולט ומשמיע כמסר נכנס.\n אם יש פרצי שידור של רחש לא מוסבר צריך לרדת במספרים', key: '4', },
        { function: '5', funcname: 'WN', data: 'WIDE / NARR', explanation: 'רוחב פס של הערוץ.\n כמה מדויק ניתן לכייל את התדר. מומלץ להשאיר ברחב (WIDE)', key: '5', },
        { function: '6', funcname: 'ABR', data: 'OFF / 1-10', explanation: 'משך הזמן (שניות) שהתצוגה דלוקה לאחר שימוש \n 1 מספיק על מנת לחסוך בסוללה', key: '6', },
        { function: '7', funcname: 'TDR', data: 'ON / OFF', explanation: 'האזנה כפולה.\n במידה ואין צורך בהאזנה לכמה קבוצות במקביל מומלץ לכבות על מנת לחסוך בסוללה ', key: '7', },
        { function: '8', funcname: 'BEEP', data: 'ON / OFF', explanation: 'השמעת צליל בעת לחיצה על כפתורים', key: '8', },
        { function: '9', funcname: 'TOT', data: 'OFF / 15 / 30 / 45 / 60 / 75....600', explanation: 'מגביל משך של שידור בודד רציף (שניות).\nחותך את השידור כשהוא ארוך מדי על מנת לחסוך בסוללה ולשחרר את הקו לתשדורות אחרות', key: '9', },
        { function: '10', funcname: 'R-DCS', data: 'OFF / D023N-D754N / D023I-D754I', explanation: 'קידוד קליטה דיגיטלי', key: '10', },
        { function: '11', funcname: 'R-CTCS', data: '67Hz...254.1Hz', explanation: 'קידוד קליטה רציף', key: '11', },
        { function: '12', funcname: 'T-DCS', data: 'OFF / D023N-D754N / D023I-D754I', explanation: 'קידוד שידור דיגיטלי', key: '12', },
        { function: '13', funcname: 'T-CTCS', data: '67Hz...254.1Hz', explanation: 'קידוד שידור רציף', key: '13', },
        { function: '14', funcname: 'VOICE', data: 'ON / OFF', explanation: 'פידבק קולי על כל לחיצה', key: '14', },
        { function: '15', funcname: 'ANI-ID', data: '', explanation: 'כיוון אוטומטי של מספר מזהה לקשר.', key: '15', },
        { function: '16', funcname: 'DTMFST', data: 'OFF / DT-ST / ANI-ST / DT+ANI', explanation: 'הטון של קידוד השידור הדיגיטלי.', key: '16', },
        { function: '17', funcname: 'S-CODE', data: '1-15', explanation: 'קידוד הסיגנל עבור מספר קבוצות הנמצאות באותו שטח ורוצים להימנע מחפיפה.', key: '17', },
        { function: '18', funcname: 'SC-REV', data: 'TO / CO / SE', explanation: 'שיטת הסריקה', key: '18', },
        { function: '19', funcname: 'PTT-ID', data: 'OFF / BOT / EOT / BOTH', explanation: 'צליל של קידוד הסיגנל.', key: '19', },
        { function: '20', funcname: 'PTT-LT', data: '0-50ms', explanation: 'דיליי בשידור קוד הסיגנל.\n אם פונצקיה 19 כבויה לא רלוונטי', key: '20', },
        { function: '21', funcname: 'MDF-A', data: 'FREQ / CH / NAME', explanation: 'במצב "ערוץ" מה יופיע בשורה העליונה.\n FREQ – התדר ששמור בערוץ הנבחר\n CH – מספר הערוץ הנבחר\n NAME – שם שנקבע מראש לכל ערוץ', key: '21', },
        { function: '22', funcname: 'MDF-B', data: 'FREQ / CH / NAME', explanation: 'במצב "ערוץ" מה יופיע בשורה העליונה.\n FREQ – התדר ששמור בערוץ הנבחר\n CH – מספר הערוץ הנבחר\n NAME – שם שנקבע מראש לכל ערוץ', key: '22', },
        { function: '23', funcname: 'BCL', data: 'ON / OFF', explanation: 'אם הערוץ תפוס השידור לא יעבוד.\n יכול לחסוך בסוללה ולמנוע מצבים של שידור בזמן שמישהו אחר כבר משדר.', key: '23', },
        { function: '24', funcname: 'AUTOLOCK', data: 'ON / OFF', explanation: 'אוטומטית מקשים נעילת', key: '24', },
        { function: '25', funcname: 'SFT-D', data: 'OFF / + / -', explanation: 'הפעלת מצב ריפיטר וכיוון הזזת התדר החוזר.', key: '25', },
        { function: '26', funcname: 'OFFSET', data: '<___.___>hz', explanation: 'בכמה הרץ מזיזים את התדר החוזר במצב ריפיטר', key: '26', },
        { function: '27', funcname: 'MEMCH', data: '0-127', explanation: 'שמירת תדר לערוץ.\n יש 128 מקומות בזיכרון.', key: '27', },
        { function: '28', funcname: 'DELCH', data: '0-127', explanation: 'מחיקת התדר השמור בערוץ', key: '28', },
        { function: '29', funcname: 'WT-LED', data: 'OFF / PURPLE / ORANGE / BLUE', explanation: 'צבע המסך במצב המתנה', key: '29', },
        { function: '30', funcname: 'RX-LED', data: 'OFF / PURPLE / ORANGE / BLUE', explanation: 'צבע המסך במצב קליטה', key: '30', },
        { function: '31', funcname: 'TX-LED', data: 'OFF / PURPLE / ORANGE / BLUE', explanation: 'צבע המסך במצב שידור', key: '31', },
        { function: '32', funcname: 'AL-MOD', data: 'SITE / TONE / CODE', explanation: 'הגדרות מצב אזעקה.', key: '32', },
        { function: '33', funcname: 'BAND', data: 'UHF / VHF', explanation: 'קבוצת תדרים.', key: '33', },
        { function: '34', funcname: 'TX-AB', data: 'OFF / A / B', explanation: 'במצב האזנה כפולה\n (A) השידור יעשה תמיד לשורה עליונה \n (B) לשורה התחתונה \n (OFF) בחירה חופשית ע"י כפתור A/B', key: '34', },
        { function: '35', funcname: 'STE', data: 'ON / OFF', explanation: 'חיסול צליל סיומת.', key: '35', },
        { function: '36', funcname: 'RP-STE', data: 'OFF / 1-10', explanation: 'חיסול צליל סיומת במצב ריפיטר.', key: '36', },
        { function: '37', funcname: 'RPT-RL', data: 'OFF / 1-10', explanation: 'דיליי של צליל סיומת במצב ריפיטר.', key: '37', },
        { function: '38', funcname: 'PONMSG', data: 'FULL / MGS', explanation: 'הודעת פתיחה', key: '38', },
        { function: '39', funcname: 'ROGER', data: 'ON / OFF', explanation: 'צליל סיום שידור.', key: '39', },
        { function: '40', funcname: 'RESET', data: 'ALL', explanation: 'איפוס המכשיר', key: '40', },
    ]
};

const Radio = () => (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <h1>מידע שימושי: רדיו</h1>

        <Title level={4}>טבלת תדרי FRS</Title>
        <Table bordered
            size="small"
            pagination={false}
            columns={radio.columns}
            dataSource={radio.data} />

        <Title level={4}>הסבר התפריט במכשירים של Baofeng</Title>
        <Table bordered
            size="small"
            pagination={false}
            columns={manual.columns}
            dataSource={manual.data} />



    </Space>
);

export default Radio;
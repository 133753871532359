import React from 'react';
import {
    shovyAgra,
    parseCarNumber,
    formatDate,
    fixRishum,
    translateSugDegem,
    pad,
    getTooltipByMerkav
} from '../helpers';
import {
    ListItem,
    Tires
} from '.';
import Flag from './Flag';

const num = (number, options = {}) => new Intl.NumberFormat('en-EN', options).format(number);
const NetunimLeDegem = ({ carData }) => {
    if (!carData) return null;

    const {
        sug_degem,
        tozeret_eretz_nm,
        tozar,
        degem_nm,
        kinuy_mishari,
        shnat_yitzur,
        kvuzat_agra_cd,
        mishkal_kolel,
        merkav,
        ramat_gimur,
        mivchan_acharon_dt,
        mispar_rechev,
        tokef_dt,
        baalut,
        misgeret,
        tzeva_rechev,
        zmig_kidmi,
        zmig_ahori,
        horaat_rishum,
        tozeret_cd,
        degem_cd,
        moed_aliya_lakvish,
        extra
    } = carData;

    const kod = `${pad(tozeret_cd, 4)}-${pad(degem_cd, 4)}`;
    const sameTokefAsLastTest = new Date(mivchan_acharon_dt).getTime() === new Date(tokef_dt).getTime();
    const lateOnTest = !sameTokefAsLastTest && new Date().getTime() > new Date(tokef_dt).getTime();

    const yearsOnRoad = extra.rishum_rishon_dt ? (new Date().getTime() - new Date(extra.rishum_rishon_dt).getTime()) / (1000 * 3600 * 24 * 365) : new Date().getFullYear() - new Date(`${moed_aliya_lakvish}-1`).getFullYear();
    const yearsOnRoadRounded = Math.round(yearsOnRoad * 10) / 10;
    
    const kmAvg = yearsOnRoadRounded === 0 ? extra.kilometer_test_aharon : Math.round(extra.kilometer_test_aharon / yearsOnRoadRounded)
    
    return (<ul>
        <ListItem feat='סוג' value={translateSugDegem(sug_degem)} />

        {tozar && <ListItem feat={null} value={`תוצרת ${tozar}`} extra={
            <>
                (<Flag tozeret_eretz_nm={tozeret_eretz_nm} />
                {' '}
                {tozeret_eretz_nm})
            </>
        } />}
        <ListItem copy={`${kinuy_mishari} ${degem_nm} `} feat='דגם' value={kinuy_mishari} extra={<a href={`https://www.google.com/search?q=${degem_nm}+${kinuy_mishari}`} target="_blank" rel="noopener noreferrer">({degem_nm})</a>} />
        <ListItem tooltip={getTooltipByMerkav(merkav)} feat='מרכב' value={merkav} />

        <ListItem copy feat='רמת גימור' value={ramat_gimur} />

        <ListItem feat='שנת יצור' value={shnat_yitzur} />
        <ListItem feat='מועד עליה לכביש' value={`${formatDate(moed_aliya_lakvish)} (${yearsOnRoadRounded})`} />
        {extra.rishum_rishon_dt !== mivchan_acharon_dt && <ListItem feat='תאריך טסט אחרון' value={formatDate(mivchan_acharon_dt)} />}
        {extra.kilometer_test_aharon > 0 && <ListItem feat="קילומטרז' בטסט"
            style={{ color: kmAvg > 14900 ? 'red' : '' }}
            value={num(extra.kilometer_test_aharon)}
            tooltip={yearsOnRoadRounded === 0 ? null : <>{`ממוצע שנתי: ${num(kmAvg)} ק"מ, `}<br />
                {`ממוצע ארצי: 14,900 ק"מ`}</>}
        />}
        <ListItem style={{ color: lateOnTest ? 'red' : null }} feat='תוקף רשיון' value={formatDate(tokef_dt)} extra={lateOnTest ? " | לא בוצע טסט" : null} />

        <ListItem feat='קבוצת רישוי' value={kvuzat_agra_cd} />
        <ListItem tooltip="עדכון: יולי 2022 ואינו כולל 9 שקלים עבור מדבקת הטסט" feat='אגרת רישוי' value={`₪${shovyAgra(mishkal_kolel, kvuzat_agra_cd, shnat_yitzur)}`} />

        <ListItem feat='בעלות' value={baalut} />
        <ListItem feat='רישום מקורי' value={extra.mkoriut_nm} />
        
        {tzeva_rechev !== 'לא ידוע' && <ListItem feat='צבע הרכב' value={tzeva_rechev} />}
        {Number(extra.shnui_zeva_ind) === 1 && <ListItem title="" value="* צבע שונה מהמקור" />}

        <Tires zmig_kidmi={zmig_kidmi} zmig_ahori={zmig_ahori} extra={extra} />

        <ListItem feat='וו גרירה' value={extra.grira_nm} />

        <ListItem copy feat='שלדה'
            value={misgeret}
            extra={<>
                {/* <a href={`https://7zap.com${vinUrl}`} target="_blank" rel="noopener noreferrer">
                    {vinUrl !== '/'
                        ? <><img src="https://7zap.com/favicon-7Zap.ico" alt="7zap" width="16" height="16" /> - קטלוג חלפים</>
                        : <><img src="https://7zap.com/favicon-7Zap.ico" alt="7zap" width="16" height="16" /> - חיפוש קטלוג חלפים</>}
                </a> */}
                {misgeret !== '' && <>
                    <a href={`https://7zap.com/en/search/${misgeret}/`} target="_blank" rel="noopener noreferrer"><img src="https://7zap.com/favicon-7Zap.ico" alt="7zap" width="16" height="16" /></a>
                    {' | '}
                    <a href={`https://partsouq.com/en/search/all?q=${misgeret}`} target="_blank" rel="noopener noreferrer"><img src="https://partsouq.com/favicon.ico" alt="partsouq" width="16" height="16" /></a>
                </>}
            </>} />

        <ListItem feat='מספר רכב' value={parseCarNumber(mispar_rechev)} />
        <ListItem copy feat='קוד משרד התחבורה' value={kod} />

        <ListItem copy={fixRishum(horaat_rishum)} feat="הוראת רישום" value={null}
            extra={<a href={`https://www.google.com/search?q=הוראת רישום ${fixRishum(horaat_rishum)}`} target="_blank" rel="noopener noreferrer">{fixRishum(horaat_rishum)}</a>}
        />
    </ul>);
}

export default NetunimLeDegem;


import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Link } from "react-router-dom";
import classnames from 'classnames';
import Divider from 'antd/lib/divider';
import Button from 'antd/lib/button';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import { WarningTwoTone, RetweetOutlined, VideoCameraTwoTone } from '@ant-design/icons';
import { pad } from '../helpers';

import './Roads.scss';

const RoadItem = function ({ road, idx, warnings }) {
    const [distance, setDistance] = useState(0);
    const [routeLength, setLength] = useState(0);
    const [timeDiff, setTimeDiff] = useState(null);
    const [timeString, setTimeString] = useState('');
    const [routeName, setName] = useState('טוען שם...');

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                fetch(`/api/gpx?road=${road.key}&lat=${position.coords.latitude}&lon=${position.coords.longitude}`)
                    .then(res => res.json())
                    .then(({ routeLength, name, distance, baseTime, trafficTime }) => {
                        setLength(routeLength);
                        setName(name);
                        setDistance(distance);

                        const prcnt = ((trafficTime - baseTime) * 100) / baseTime;
                        setTimeDiff(prcnt);

                        let timeStr;
                        const baseHours = baseTime / 60;
                        const baseStr = baseHours >= 1 ? `${Math.floor(baseHours)}:${pad(Math.round(baseTime % 60), 2)} ש'` : `${Math.round(baseTime)} דקות`;
                        timeStr = baseStr;

                        if (trafficTime !== baseTime) {
                            const travelHours = trafficTime / 60;
                            const trafficTimeStr = (travelHours) >= 1 ? `${Math.floor(travelHours)}:${pad(Math.round(trafficTime % 60), 2)} ש'` : `${Math.round(trafficTime)} דקות`;
                            timeStr = `${trafficTimeStr} במקום ${baseStr}`;
                        }
                        setTimeString(timeStr);
                    });
            });
        }
    }, [road.key]);

    const tag = _.cond([
        [(t) => t > 0 && t <= 33, () => ({ color: 'green', title: 'מעט תנועה' })],
        [(t) => t > 33 && t <= 66, () => ({ color: 'yellow', title: 'הרבה תנועה' })],
        [(t) => t > 66 && t <= 100, () => ({ color: 'red', title: 'תנועה כבדה מאד' })],
        [_, () => ({ color: 'blue', title: 'זמן הגעה משוער' })]
    ])(timeDiff);

    return (<React.Fragment>
        <div key={road.key}
            className={classnames(['road-item', { 'is-closed': road.closed }])}
            style={{ position: 'relative', paddingLeft: 90 }}>

            <div style={{ position: 'absolute', left: 0, bottom: 0 }}>
                {(!!warnings.length || !!road.info) && <Tooltip placement="topRight" title={<ul>
                    {(road.info || []).map((w, idx) => <li key={`w_${idx}`}>{w}</li>)}
                    {(warnings || []).map((w, idx) => <li key={idx}>{w.description}</li>)}
                </ul>}><WarningTwoTone /></Tooltip>}
                {' '}
                {road.round && (<Tooltip placement="topRight" title="מסלול מעגלי">
                    <RetweetOutlined style={{ color: 'blue' }} />
                </Tooltip>)}
                {' '}
                {!_.isEmpty(road.cams) && (<Tooltip placement="topRight" title="קיימות מצלמות בשידור חי מצומת בקרבת הכביש">
                    <VideoCameraTwoTone />
                </Tooltip>)}
            </div>
            <Link to={`/roads/${road.key}`}>
                {idx + 1}. {road.name || routeName}
                {!!road.roads.length && <small style={{ whiteSpace: 'nowrap' }}>&nbsp;({road.roads.join(', ')})</small>}

                <div>
                    מרחק: {distance} ק"מ |
                    <span style={{ whiteSpace: 'nowrap' }}> אורך מסלול: {+(Math.round(routeLength + "e+2") + "e-2")} ק"מ</span>
                </div>
                {timeDiff !== null && <Tag color={tag.color}>{tag.title} - {timeString}</Tag>}
                <Button style={{ position: 'absolute', left: 0, top: 0 }}>הצג פירוט</Button>
            </Link>
        </div>
        <Divider />
    </React.Fragment>);
};

export default RoadItem;

import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { ShareAltOutlined, BulbOutlined, GoogleOutlined, CarOutlined, FacebookOutlined } from '@ant-design/icons';
import {
    convertHorsepowerToKilowatts,
    parseCarNumber,
    translateKeys,
    translateTozar,
    translateTozarBulbs,
    translateTozarOBD2
} from '../helpers';
import {
    HISTORY_SIZE,
    HISTORY_KEY
} from '../common/consts';
import {
    CrashTest,
    CarRecall,
    CarSearchInput,
    Betihut,
    CarPrice,
    CarStolen,
    TotalOfDegem,
    MobileyeCheck,
    TavNeche,
    Zihum,
    ListItem,
    SelfImport,
    NetunimLeDegem,
    CarHands,
    CarBattery
} from '.';
import './CarDetails.scss';

export default class CarDetails extends Component {
    constructor(props) {
        super(props);

        const carNumber = props.match.params.id;

        this.initState = {
            carNumber,
            carData: {},
            disc_date: null,
            loadingCarData: false,
            compareNumber: null
        };

        this.state = {
            ...this.initState
        };

        this.input = React.createRef();
    }

    componentDidMount() {
        this.state.carNumber && this.handleChange(this.state.carNumber);
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.match.params.id !== this.state.carNumber) {
            this.handleChange(props.match.params.id);
        }
    }

    getData = (carNumber) => axios.get(`/cardata/${carNumber}`)
        .then(({ data:results }) => this.setState({
            loadingCarData: false,
            carData: results.carData || {},
            selfImport: results.selfImport,
            carNumber
        }))
        .catch(er => this.setState({ loadingCarData: false }));

    handleChange = (carNumber) => {
        if (carNumber && (carNumber.length < 7 || carNumber.length > 8)) return null;
        if (carNumber) { this.input.current.value = carNumber; }

        const CN = carNumber || this.input.current.value;
        // const urlParams = new URLSearchParams(window.location.search);

        window.document.title = `מספרכב - איתור מידע לרכב מספר ${CN}`;
        window.dataLayer.push({ 'event': 'search', 'carNumber': CN });
        // window.history.pushState(`page${CN}`, `איתור מידע על רכב מספר ${CN}`, `?car=${CN}`);

        const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
        const historySet = new Set(history);

        if (historySet.has(CN)) {
            historySet.delete(CN);
        }

        if (historySet.size === HISTORY_SIZE) {
            const first = [...historySet][0];
            const history = JSON.parse(localStorage.getItem(HISTORY_KEY) || '[]');
            delete history[first];
            localStorage.setItem(HISTORY_KEY, JSON.stringify(history));
            historySet.delete(first);
        }

        historySet.add(CN);
        localStorage.setItem(HISTORY_KEY, JSON.stringify([...historySet]));

        this.setState({
            ...this.initState,
            loadingCarData: true,
            loadingRecall: true,
            carNumber: CN
        }, () => {
            this.getData(CN)
        });
    }

    render() {
        const {
            loadingCarData,
            carNumber,
            carData,
            selfImport
        } = this.state;

        const {
            tozeret_cd,
            degem_cd,
            tozar,
            degem_nm,
            kinuy_mishari,
            shnat_yitzur,
            sug_mamir_cd,
            sug_mamir_nm,
            automatic_ind,
            merkav,
            technologiat_hanaa_nm,
            degem_manoa,
            extra
        } = carData;

        return (
            <React.Fragment>
                <CarSearchInput ref={this.input} carNumber={carNumber} showCompare />

                <div className="carDetails">
                    <div>
                        {loadingCarData &&
                            <div>טוען נתונים לרכב מספר {parseCarNumber(carNumber)}...</div>}

                        {(carNumber && (_.isEmpty(carData)) && !loadingCarData &&
                            <h3>מצטערים, לא חזר מידע ממשרד התחבורה עבור {parseCarNumber(carNumber)}.</h3>)}

                        {selfImport && (<div style={{ padding: '0 10px', width: '100%' }}>
                            <SelfImport carData={carData} />
                        </div>)}

                        {!_.isEmpty(carData) && !loadingCarData && !selfImport && (
                            <div style={{ display: 'flex', flexFlow: 'wrap' }}>

                                {typeof navigator.share === 'function' && <ShareAltOutlined style={{ position: 'absolute', top: 10 }} className="share-btn" onClick={() => {
                                    navigator.share({
                                        title: `מידע על רכב מספר ${parseCarNumber(carNumber)}`,
                                        text: parseCarNumber(carNumber),
                                        url: window.location.href,
                                    })
                                }} />}

                                <div className='section' style={{ padding: '0 10px', width: '100%' }}>
                                    <h2 style={{ marginTop: 0, paddingRight: 10 }}>נתוני דגם</h2>
                                    <NetunimLeDegem carData={carData} />

                                    <TotalOfDegem degem={degem_nm} />
                                </div>

                                <div className='section' style={{ padding: '0 10px' }}>
                                    <h2>אביזרים ומערכות</h2>
                                    <ul>
                                        <ListItem feat='טכנולוגיית הנעה' value={technologiat_hanaa_nm} />
                                        {(Number(automatic_ind) === 0 || Number(automatic_ind) === 1) && <ListItem feat='תיבת הילוכים' value={Number(automatic_ind) === 1 ? 'אוטומטית' : 'ידנית'} />}
                                        {['bakarat_yatzivut_ind', 'abs_ind', 'hege_koah_ind', 'halon_bagg_ind', 'mazgan_ind', 'argaz_ind', 'galgaley_sagsoget_kala_ind']
                                            .map(k =>
                                                <ListItem key={k} feat={k} value={carData[k]} translate checked checkedValue={parseInt(carData[k], 10)} />)}
                                    </ul>
                                </div>

                                <div className='section' style={{ padding: '0 10px' }}>
                                    <h2>מפרט טכני</h2>
                                    <ul>
                                        <ListItem copy={degem_manoa} value={null} feat='דגם מנוע' extra={<a href={`https://www.google.com/search?q=${degem_manoa}+engine`} target="_blank" rel="noopener noreferrer">{degem_manoa}</a>} />
                                        <ListItem copy value={extra.mispar_manoa} feat='מספר מנוע' />
                                        <ListItem value={carData.delek_nm} feat={translateKeys('delek_nm')} />
                                        {Number(extra.gapam_ind) === 1 && <ListItem feat='גפ"מ' checked checkedValue={Number(extra.gapam_ind)} />}
                                        {['nefah_manoa', 'koah_sus', 'mishkal_kolel', 'gova', 'hanaa_nm', 'kosher_grira_im_blamim', 'kosher_grira_bli_blamim', 'mispar_moshavim', 'mispar_dlatot', 'mispar_halonot_hashmal', 'mispar_kariot_avir']
                                            .map(k =>
                                                <ListItem key={k} feat={k} value={carData[k]} translate extra={k==='koah_sus'? <small>({convertHorsepowerToKilowatts(carData[k])}kW)</small> : null} />)}
                                        {sug_mamir_cd !== 0 &&
                                            <ListItem feat='סוג ממיר' value={sug_mamir_nm} />}
                                    </ul>
                                </div>

                                <Betihut carData={carData} />

                                <Zihum carData={carData} />

                                <CarHands carNumber={carNumber} title="היסטוריית בעלויות" />

                            </div>
                        )}

                        <CarRecall carNumber={carNumber} />
                        
                        {shnat_yitzur && <CarBattery carData={carData}  shnat_yitzur={shnat_yitzur} />}

                        {tozeret_cd && degem_cd && !selfImport &&
                            <CarPrice tozeret={tozeret_cd} degem={degem_cd} shnat_yitzur={shnat_yitzur} />}

                        {carNumber && (<div>
                            <TavNeche carNumber={carNumber} />

                            <CarStolen carNumber={carNumber} />

                            <MobileyeCheck carNumber={carNumber} />

                            <div className="section more" style={{padding: '0 10px'}}>
                                <h2>בדיקה באתרים נוספים</h2>
                                <div><a href={`https://meshumeshet.com/c/${carNumber}`} target="_blank" rel="noopener noreferrer"><CarOutlined /> - משומשת</a></div>
                                <div><a href={`https://www.facebook.com/search/top/?q=${carNumber}`} target="_blank" rel="noopener noreferrer"><FacebookOutlined /> - פייסבוק</a></div>
                                <div><a href={`https://www.google.com/search?q=${carNumber}`} target="_blank" rel="noopener noreferrer"><GoogleOutlined /> - גוגל</a></div>
                                <div><a href={`https://www.powerbulbs.com/vehicles/cars/rest-of-world/${translateTozarBulbs(tozar)}`} target="_blank" rel="noopener noreferrer"><BulbOutlined /> - חיפוש נורות</a></div>
                                <div><a href={`https://fuse-box.info/?amp=1&s=${kinuy_mishari}+${shnat_yitzur}`} target="_blank" rel="noopener noreferrer"><img src="https://fuse-box.info/wp-content/uploads/2018/02/favicon1-80x80.png" alt="fuse-box" width="16" height="16" /> - חיפוש פיוזים</a></div>
                                <div><a href={`https://www.varta-automotive.com/en-be/battery-finder`} target="_blank" rel="noopener noreferrer"><img src="https://www.varta-automotive.com/packages/website_varta/themes/varta/favicon.ico" alt="varta" width="16" height="16" /> - חיפוש מצברים</a></div>
                                <div><a href={`https://www.outilsobdfacile.com/location-plug-connector-obd/${translateTozarOBD2(tozar)}`} target="_blank" rel="noopener noreferrer"><img src="https://www.boutiqueobdfacile.com/userfiles/image/2/favicon.ico" alt="OBD" width="16" height="16" /> - מיקום שקע OBD ברכה</a></div>

                                {/* <div><a href={`https://fuse-box.info/?amp=1&s=${kinuy_mishari}+${shnat_yitzur}`} target="_blank" rel="noopener noreferrer"><img src="https://fuse-box.info/wp-content/uploads/2018/02/favicon1-80x80.png" alt="fuse-box" width="16" height="16" /> - חיפוש פיוזים</a></div> */}
                                <div><a href={`https://www.mywipersize.com/make/${(translateTozar(tozar) || '').toLowerCase()}/${(kinuy_mishari || '').toLowerCase()}.php`} target="_blank" rel="noopener noreferrer"><img src="https://www.mywipersize.com/favicon.ico" alt="wipers" width="16" height="16" /> - חיפוש מגבים</a></div>
                                <div><a href={`https://cardiagn.com/${(translateTozar(tozar) || '').toLowerCase()}/${(kinuy_mishari || '').toLowerCase().replace(' ', '-')}`} target="_blank" rel="noopener noreferrer"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACHFBMVEVHcEy/v7/AwMC5ubmZmZmNjY3BwcGIiIiNjY3MzMyFhYXCwsLOzs7BIiazJCe6uLippqacnJyioaGlHh6goKCenZ3Nzc2in5+7u7u+vr6sq6ugnZ3Ozs6fnp7FxcW1tbWtra3Hx8exsbHKysq/KjC3t7fHx8fCLC6WlpbBIybWMzfExMSenZ2bm5ufnp+9vb2cmZm9eHy/ISS6ICWzs7OnpaWmpaW4uLjEw8O7urrNzc2lJSXDIia1srLJIyfJNjqnpqbEw8OjoqKYmJirTk+pYWPLy8usamzJycnHx8fT09O/4OfQISXKycmsqqqwr6+pp6f39/esq6vx8u6/vb3v7+/S0tK/4Obc29u/vr68u7u6uLjA3eDj4uKzsbHR0NCqqans7uvNy8vV1NTo6OjOLDDS0dHX19fIISXg3cPm5ubOzs7r5s/54Ka+4OfTKi22tLTd3d3CwcHHxsbb2trq7Oe83OLs4cDA29nLIibYk5rg5+GmpaXk6ePv7+zC3d3T4t309PPy8vG83eTUd33q6Nrg39/13qjUZ23u7uXKKi7s69/WVlvTOz+2zdPr6urp5tXs48e9REfJOz/h6OLMnp+2trbYvbrM39vRqKm+1ty8ytCurKzOJSjRREnZgIbCWFvZ5OC8z9Xy3ane5+Hcg4rWjJLC2NLBwMDRUFTD2tbPMjbU1NTBzsrFLzPENDjXSE2ogoPm3+D09PSnlWq8AAAASnRSTlMAjIVlFxCTAwv1B5r6mz3gLB+9CLKU8SWgdkMz9YnDijvNS+ZgXNd5Ioz+rvhmoIBfo7OIl4KN8+rk6SLnU/v+86rKSJTn7f7i1o7bV/8AAAIKSURBVDjLrdPXV9pQHMBxFCizOKpYa7V2772n3eNHEtKEAElkVqAFKiibKu5Vt3bvPen+B5vkBW549ft27/nk3HWiUKxQ6lrZhHaDHhnX6Qx15ePajUYV+oEOzFXSlEavVauUZvA0IUCzuViEU4YLbTeuX9XpjABFaEfBNpC3Cd1UG0DQE8HF/BFPEBKwFQW77bhzPDzPDP+7KXULlCgwQIhJhhh6eiy6uJjJREPQjIJqsPu93mL0rtDExPtMCPajoBk8QYKITEUXFu4IjSWhGgVKiIQIChdWp6enCoWhJFSh4Cz4nV4qMZTq//B2cPBJymlfhYJ1gI97qV5fp8NhsVgc/T1y0BLEXSTBFgQg9qACNHnwMElwdGe3BO457bI9qIwi4CVgkYDsFOr1eBdJPaKlJdLPHz99eNC0Bnlvswji9IDF8exNRyAQi8V2bSkH+hMicA8POLrf3cew1y8xDNuOvPfJ3i6ScNPZkZE5AXwMCGAnsokaTjim1ZdNp+d+YtjkpABWo8Dm8grgc/bbj18YNvopP/qqAnDxBJPyFf4u577nZ2dnAiWw9sjRY8dZF8Mwvvlwj3/pa375ttDhEnC7rfwXjrWxNs7G8ku/Z3K5c2cOmUovRVJ9cZ6T4nn3n2tXLptOl1+TpnFPH2WVekGcv9h6SVvx6+3Y29hQX9+wr/VAi1qxwv0HNU6rtorKWzgAAAAASUVORK5CYII=" alt="cardiagn" width="16" height="16" /> - ספרי רכב</a></div>
                                <div><a href={`https://catalog.mann-filter.com/EU/eng/vehicle/MANN-FILTER%20Katalog%20Europa/Vehicles/CARS%20+%20TRANSPORTERS/${(translateTozar(tozar) || '').toUpperCase()}`} target="_blank" rel="noopener noreferrer"><img src="https://catalog.mann-filter.com/files/mann-filter/images/favicon.ico" alt="MANN" width="16" height="16" /> - MANN filters</a></div>

                                <a style={{marginLeft: 10}} href="https://www.rainx.com/blade-size-finder/" target="_blank" rel="noopener noreferrer"><img width="16" height="16" src="http://rainx.com/favicon.ico" alt="" /></a>
                                <a style={{marginLeft: 10}} href="https://ngk.mypartfinder.com/" target="_blank" rel="noopener noreferrer"><img width="16" height="16" src="https://ngk.mypartfinder.com/favicon.ico" alt="" /></a>
                                <hr />
                                <div><a href={`https://www.google.com/search?q=${tozar}+${kinuy_mishari}`} target="_blank" rel="noopener noreferrer">חיפוש הדגם בגוגל</a></div>
                            </div>
                        </div>)}
                    </div>
                </div>

                {shnat_yitzur && tozar && kinuy_mishari &&
                    <CrashTest shnat_yitzur={shnat_yitzur}
                        tozar={tozar}
                        degem={kinuy_mishari}
                        merkav={merkav} />}

                <footer>ט.ל.ח - המידע המוצג הינו מובא ישירות מאתר משרד התחבורה</footer>
            </React.Fragment>
        );
    }
};
